<template>
  <div>
    <div class="user-item my-1" :id="id" @click="optPopOpen">

      <div class="user-avatar">
        <b-avatar size="sm" variant="info" :src="avatar"/>
      </div>

      <div class="user-wrapper-name-role">
        <div class="user-name-wrapper">
          <div class="user-name">
            {{ full_name }}
          </div>
        </div>

        <div class="user-role">{{ isModerator ? 'модератор' : 'участник' }}</div>
      </div>

      <b-popover id="options-popover" v-if="this.isModerator" :target="id" :show.sync="isShowOpt" placement="bottomleft">
        <div class="popover-container">

          <div class="option-item" @click="micSwitch">
            <b-icon icon="mic" class="popover-option-icon"/>
            <div>
              {{ this.isMicAllow ? 'Выключить звук' : 'Включить звук' }}
            </div>
          </div>

          <div class="option-item" @click="cameraSwitch">
            <b-icon icon="camera-video" class="popover-option-icon"/>
            <div>
              {{ this.isCameraAllow ? 'Выключить видео' : 'Включить видео' }}
            </div>
          </div>

          <div class="option-item" @click="chatSwitch">
            <b-icon icon="chat-text" class="popover-option-icon"/>
            <div>
              {{ this.isChatAllow ? 'Выключить чат' : 'Включить чат' }}
            </div>
          </div>

          <div class="option-item" @click="screenShareSwitch">
            <b-icon icon="arrow-up-right-square" class="popover-option-icon"/>
            <div>
              {{ this.isScreenShareAllow ? 'Выключить показ экрана' : 'Включить показ экрана' }}
            </div>
          </div>

        </div>
      </b-popover>

    </div>

  </div>

</template>

<script>
const MIC_TOOL = 'mic';
const CAMERA_TOOL = 'camera';
const CHAT_TOOL = 'chat';
const SCREEN_SHARE_TOOL = 'screen_share';

export default {
  name: 'ConfMenuUserItem',
  props: {
    id: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    full_name: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    room_name: {
      type: String,
      default: ''
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
    isMicAllowed: {
      type: Boolean,
      default: true
    },
    isCameraAllowed: {
      type: Boolean,
      default: true
    },
    isChatAllowed: {
      type: Boolean,
      default: true
    },
    isScreenShareAllowed: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isShowOpt: false,
      isMicAllow: this.isMicAllowed,
      isCameraAllow: this.isCameraAllowed,
      isChatAllow: this.isChatAllowed,
      isScreenShareAllow: this.isScreenShareAllowed
    }
  },
  methods: {
    optPopOpen() {
      this.isShowOpt = !this.isShowOpt;
    },
    sendCommand(toolName, toolFlag) {
      const command = {
        isCommand: true,
        isAllow: toolFlag,
        tool: toolName,
        id: this.id
      };
      const commandJson = JSON.stringify(command);

      this.$root.global_object.API.executeCommand('sendEndpointTextMessage', this.id, commandJson);
    },
    micSwitch() {
      this.isMicAllow = !this.isMicAllow;

      this.sendCommand(MIC_TOOL, this.isMicAllow);
      this.api_set_user_tool_rights(MIC_TOOL, this.isMicAllow)

      this.optPopOpen();
    },
    cameraSwitch() {
      this.isCameraAllow = !this.isCameraAllow;

      this.sendCommand(CAMERA_TOOL, this.isCameraAllow);
      this.api_set_user_tool_rights(CAMERA_TOOL, this.isCameraAllow)

      this.optPopOpen();
    },
    chatSwitch() {
      this.isChatAllow = !this.isChatAllow;

      this.sendCommand(CHAT_TOOL, this.isChatAllow);
      this.api_set_user_tool_rights(CHAT_TOOL, this.isChatAllow);

      this.optPopOpen();
    },
    screenShareSwitch() {
      this.isScreenShareAllow = !this.isScreenShareAllow;

      this.sendCommand(SCREEN_SHARE_TOOL, this.isScreenShareAllow);
      this.api_set_user_tool_rights(SCREEN_SHARE_TOOL, this.isScreenShareAllow);

      this.optPopOpen();
    },

    api_set_user_tool_rights(toolName, isAllow) {
      this.axios
          .post('/api/v2/moderating/edit_permissions', {
            occupant_id: this.id,
            room_name: this.room_name,
            tool: toolName,
            allow: isAllow
          }, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .catch(error => {
            console.log(error)
          })
    }
  }
}
</script>

<style>
.user-item {
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    overflow: hidden;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    box-shadow: rgb(255 255 255 / 15%) 0px -1px 0px inset;
    padding: 4px;
}

.user-item:hover {
    cursor: pointer;
    background-color: #484f54;
}

.user-avatar {
    margin-right: 10px;
}

.user-wrapper-name-role {
    display: flex;
    flex: 1 1 0%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2px;
}

.user-name-wrapper {
    display: flex;
    flex: 1 1 0%;
    overflow: hidden;
}

.user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.user-role {
    font-size: 13px;
    line-height: 17px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: rgb(133, 133, 133);
}

.audio-video-info svg {
    margin: 0 5px;
}

#options-popover .popover-body {
    padding: 0;
    background-color: #151515;
    z-index: 3;
}

#options-popover .arrow:after {
    border-bottom-color: #151515;
}

.popover-container {
    display: flex;
    flex-direction: column;
}

.option-item {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: 40px;
    padding: 10px 16px;
    box-sizing: border-box;
}

.option-item:hover {
    cursor: pointer;
    background-color: #5a6268;
}

.option-item div {
    color: white;
}

.popover-option-icon {
    color: white;
    padding-right: 24px;
}
</style>