import Vue from 'vue'
import App from './App.vue'
// import VueSocketIO from 'vue-socket.io';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import './../app.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as Sentry from "@sentry/vue";
import {Integrations} from "@sentry/tracing";
import md5 from 'md5';
import ToggleButton from 'vue-js-toggle-button'
import VueChatScroll from 'vue-chat-scroll'

Vue.use(VueChatScroll)
Vue.use(ToggleButton)


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/main_style.css'

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)
Vue.use(VueRouter)
// Vue.use(new VueSocketIO({
//     connection: 'http://localhost:3000'
//   }));
Vue.use(md5)


import moment from 'moment';
import VueMoment from 'vue-moment';

require('moment/locale/ru')
Vue.use(VueMoment, {moment});

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import {LayoutPlugin} from 'bootstrap-vue'

Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import {ModalPlugin} from 'bootstrap-vue'

Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import {CardPlugin} from 'bootstrap-vue'

Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import {VBScrollspyPlugin} from 'bootstrap-vue'

Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import {DropdownPlugin, TablePlugin} from 'bootstrap-vue'

Vue.use(DropdownPlugin)
Vue.use(TablePlugin)

import {BVToastPlugin} from 'bootstrap-vue'

Vue.use(BVToastPlugin)
Vue.use(md5)

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faHand} from "@fortawesome/free-regular-svg-icons"
import {library} from "@fortawesome/fontawesome-svg-core"

library.add(faHand)
Vue.component('font-awesome-icon', FontAwesomeIcon);

import index from "./router"

// import global_object from "./router"

function loadError(oError) {
    throw new URIError("The script " + oError.target.src + " didn't load correctly.");
}

function onloadFunction() {
    window.dataLayer = window.dataLayer || [];
    const gtag = function () {
        window.dataLayer.push(arguments);
    };
    gtag('js', new Date());
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_GTAG);
}

if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
    let googleAnalitics = document.createElement("script");
    googleAnalitics.onerror = loadError;
    if (onloadFunction) {
        googleAnalitics.onload = onloadFunction;
    }
    document.head.appendChild(googleAnalitics);
    googleAnalitics.src = process.env.VUE_APP_GOOGLE_ANALYTICS_SRC;
}


if (process.env.VUE_APP_SENTRY) {
    Sentry.init({
        Vue,
        denyUrls: [
            /127\.0\.0\.1:8080/i,
            "localhost:8080"
        ],
        dsn: process.env.VUE_APP_SENTRY_DSN,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: "meet-miem@" + process.env.VUE_APP_VERSION,
        integrations: [new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(index.router),
                tracingOrigins: [
                    //"localhost",
                    process.env.VUE_APP_VERSION_TRACING_ORIGINS,
                    ///^\//
                ]
            }
        )],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //         Sentry.showReportDialog({eventId: event.event_id});
        //     }
        //     hint;
        //     return event;
        // },
    });
}

DatePicker.install(Vue);
DatePicker.locale('ru');

new Vue({
    render: h => h(App),
    router: index.router,
    data: {
        global_object: index.global_object
    },

}).$mount('#app')


