<template>
  <b-container>
  <b-row v-if="!is_moderator" align-v="center" class="status-string justify-content-center ">
    <b-col class="col-md-auto col-12 text-center" id="status-text">
      <div>Дождитесь, когда организатор разрешит вам войти</div>
    </b-col>
    <b-col class="col-md-auto col-12 text-center" id="col-icon-upload" v-if="true">
      <div><b-spinner variant="secondary" label="Spinning"></b-spinner>
      </div>
    </b-col>
    <b-col class="col-md-auto col-12 text-center" id="col-button-exit">
      <b-button id="button-exit" @click="disconnectUser()">
        Выйти
      </b-button>
    </b-col>
  </b-row>
    <b-row>
      <b-col v-if="is_moderator" class="col-lg-6 col-12 pb-2">
    <b-card header="Список ожидающих"  header-class="text-center" header-bg-variant="secondary" class="card-size" >
      <b-row align-h="between" class="approve-all pt-0 pb-0">
            <b-col class="col-xl-6 col-lg-5 col-md-4 my-auto pl-0 pr-0 "><p>На одобрении ({{this.waitingList.length}})</p></b-col>
<!--        TODO: В блоке есть ошибка. Нужно тестировать-->
<!--            <b-col class="col-xl-6 col-lg-7 col-md-8 my-auto" v-if="this.waitingList.length>0"> -->
<!--               <b-row align-h="end">-->
<!--                <b-col class=" col-auto my-auto pl-0 pr-0"><p>Добавить всех</p></b-col>-->
<!--                <b-col class="col-auto my-auto"><b-icon icon="check2-all"-->
<!--                 @click="letIntoTheConfAll" font-scale="1.3" class="active-icon"></b-icon>-->
<!--                </b-col> -->
<!--                </b-row>-->
<!--            </b-col>-->
      </b-row>
      <b-card-body class="waiting-list">
          <b-list-group flush v-for="(waiting,index) in this.waitingList" :key="index">
            <b-list-group-item href="#" >
              <b-row align-h="between" >
                <b-col class="waiting-person" >
                  <b-row align-h="start" class="pl-0">
                    <b-col class="col-auto my-auto pl-0 pr-0"><b-avatar :src="waiting.profile_pic"></b-avatar></b-col>
                    <b-col class="col-auto my-auto pr-0"><div>{{waiting.user_name}}</div></b-col>
                  </b-row>
                </b-col>
                <b-col class="col-auto my-auto"> <b-icon icon="check2" @click="letIntoTheConf(waiting.email)" font-scale="1.3" class="active-icon"></b-icon> </b-col>
                <b-col class="col-auto my-auto"> <b-icon icon="x" @click="deleteFromWaitingRoom(waiting.email)" font-scale="1.3" class="active-icon"></b-icon> </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
      </b-card-body>
    </b-card>
      </b-col>
      <b-col :class="[!is_moderator ? 'col-lg-12' : 'col-lg-6']">
        <b-card header="Чат зала ожидания" header-class="text-center" class="card-size"  >
          <b-card-body class="chat-card" v-chat-scroll >
          <b-list-group v-for="(msg,index) in this.chat" :key="index" >
            <b-list-group-item class="message-item" v-if="current_user_message(msg.user_email)" >
              <b-row align-v="center" align-h="end">
                  <b-col class="col-auto my-auto pl-2 pr-4">
                    <b-row align-h="end">
                     <b-col class="col-auto my-auto pl-0 pr-0" style="text-align: right;"><strong style="text-align: right;color: #004085"> Вы </strong></b-col>
                    </b-row>
                    <b-row ><small>{{msg_time(msg.create_ts)}}</small> </b-row>
                  </b-col>
              </b-row>
              <b-row align-v="center" align-h="end">
                <b-col class="col-auto my-auto pt-2 pl-0 pr-2">{{msg.body}} </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item v-else class="message-item" >
              <b-row align-v="center" align-h="start">
                <b-col class="col-auto my-auto pl-0"><b-avatar :src="msg.user_profile_pic"></b-avatar></b-col>
                <b-col class="col-auto my-auto pl-2 pr-4">
                  <b-row align-h="start">
                    <b-col class="col-auto my-auto pl-0 pr-0" style="text-align: right;"><strong style="text-align: right;color: #004085">{{msg.user_name}}  </strong></b-col>
                    <b-col v-if="msg.is_moderator" class="col-auto my-auto pb-1 pl-2">
                      <b-button disabled variant="outline-dark" style="font-size: x-small; padding-left: 2px; padding-right: 2px;padding-bottom: 0px; padding-top: 0px;"> <strong>M</strong> </b-button>
                    </b-col>
                  </b-row>
                  <b-row ><small>{{msg_time(msg.create_ts)}}</small> </b-row>
                </b-col>
              </b-row>
              <b-row align-v="center" align-h="start">
                <b-col class="col-auto my-auto pt-2 pl-0 pr-2">{{msg.body}} </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
          </b-card-body>
          <b-input-group class="input-message pl-0 pr-0">
                <b-form-input
                v-model="new_message"
                placeholder="Введите сообщение"
                type="text"
                debounce="500"
                list="namesOrg"
                @keyup.enter="addNewMessage()"
                >
                </b-form-input>
                <b-input-group-append>
                  <b-button @click="addNewMessage()" class="button-send-message"><b-icon icon="cursor"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="delete-alert" @ok="this.hideModelWaitingRoom" ok-only hide-header-close no-close-on-esc title="Уведомление" size="sm">
      <p>Организатор исключил вас из комнаты ожидания</p>
    </b-modal>
  </b-container>
</template>

<script>
import SocketioService from '../services/socketio.service.js';

export default {
  name: "WaitingRoom",
  props:{
    is_moderator: {
      type: Boolean,
      default: false
    },
    email:{
      type: String,
      default: ''
    },
    room_name:{
      type: String,
      default: ''
    },
    join_room:{
      type: Function,
      default: () => 1,
    },
    delete_user:{
      type: Function,
      default: () => 1,
    }
  },
  data(){
    return{
      new_message:'',
      chat:[],
      messages:0,
      waitingList:[],
      months:[
        'янв.',
        'февр.',
        'марта',
        'апр.',
        'мая',
        'июня',
        'июля',
        'авг.',
        'сент.',
        'окт.',
        'нояб.',
        'дек.',
      ],
    }
  },
   mounted(){
     SocketioService.socket.on('get_all_messages_response', (data)=>{
       console.log(data.messages);
       this.chat = data.messages
     });
     SocketioService.socket.on('get_waiting_users_response', (response)=>
     {
       console.log(response)
       if(response.success){
         this.waitingList = response.waiting_users;
         console.log('СПИСОК', response.waiting_users)
       }
     });
     SocketioService.socket.on(`chat_${this.room_name}`, (response) => {
           //alert(response.message);
           if(response.success) {
             this.chat.push({
               user_name: response.from_user,
               create_ts: response.sending_time,
               body: response.message,
               is_moderator:response.is_moderator,
               user_profile_pic: response.profile_pic,
               user_email:response.email
             })
           }
         }
     );
     SocketioService.socket.on(`join_room_${this.room_name}`,(response)=>{
       if(response){
         if(response.can_join && !this.is_moderator) {
           this.hideModelWaitingRoom()
           this.join_room()
           console.log('ВЫ ПЕРЕХОДИТЕ В ОСНОВНУЮ КОМНАТУ')
           SocketioService.socket.emit('waiting_room_status', {'room_name':this.room_name});
         }
         else
           this.$bvModal.show('delete-alert')
         SocketioService.socket.emit('waiting_room_status', {'room_name':this.room_name});
       }
     });
     SocketioService.socket.emit('get_all_messages', {'room_name':this.room_name})
     SocketioService.socket.emit('get_waiting_users',{'room_name':this.room_name})

   },
  destroyed() {
    SocketioService.socket.off(`chat_${this.room_name}`);
    SocketioService.socket.off('get_waiting_users_response');
    SocketioService.socket.off('get_all_messages_response');
  },
  methods: {
     msg_time(ts){
      console.log(ts)
       let date = new Date(ts*1000)
       let month_now=this.months[date.getMonth()]
       let day_now= date.getDate()
       if(day_now<10){
         day_now='0'+day_now.toString()
       }
       else day_now=day_now.toString()
       const time_now= date.toLocaleTimeString().slice(0,-3)
       return  time_now + '   '+ day_now +' '+ month_now
     },
    letIntoTheConf(user_email){
      SocketioService.socket.emit('accept_participant',{'room_name':this.room_name,'accept_everyone':false,'email':user_email, 'accept':true})
      let index = this.waitingList.findIndex(item => item['email'] === user_email);
      this.waitingList.splice(index, 1);
      this.delete_user()
    },
    letIntoTheConfAll(){
      SocketioService.socket.emit('accept_participant',{'room_name':this.room_name,'accept_everyone':true, 'accept':true})
      this.waitingList=[]
      this.delete_user('true')
    },
    deleteFromWaitingRoom(user_email){
      SocketioService.socket.emit('accept_participant',{'room_name':this.room_name,'accept_everyone':false,'email':user_email, 'accept':false})
      let index = this.waitingList.findIndex(item => item['email'] === user_email);
      this.waitingList.splice(index, 1);
      this.delete_user()
    },
    hideModelWaitingRoom(){
      this.$bvModal.hide('modal-waiting-room')
    },
    disconnectUser(){
       SocketioService.socket.emit('delete_user_from_waiting_room',{'room_name':this.room_name});
       this.hideModelWaitingRoom()
     },
    addNewMessage(){
      if(this.new_message){
        SocketioService.socket.emit('chat', {'room_name':this.room_name, 'message':this.new_message});
      }
      this.new_message=''
    },
    current_user_message(email){
       console.log('СООБЩЕНИЕ', email, this.email)
       if(email===this.email) return true
       else return false
    }
 },
}
</script>

<style scoped>
 #button-exit{
   background-color: #0050cf;
 }
 .button-send-message{
  background-color: #0050cf;
 }

 .status-string{
   margin-bottom: 10px;
 }
 .waiting-person{
   display: inline;
 }
 .approve-all{
   padding-left: 15px;
   padding-right: 15px ;
 }
 .waiting-list{
   overflow-x: hidden;
   padding: 0px;
   margin: 0px;
   height: 400px;
 }
 .chat-card{
   overflow-y: scroll;
   overflow-x: hidden;
   padding: 0px;
   margin: 0px;
   height: 400px;
 }
 .message-item{
   border: 0;
 }
 .input-message{
   padding: 5px 20px;
 }
 .waiting-room-cards{
  margin-bottom: 20px;
 }
 
</style>