<template>
  <div class="user-item" :id="id">
    <div class="user-avatar">
      <b-avatar size="sm" variant="info" :src="avatar"/>
    </div>

    <div class="user-name">
      <span>{{ full_name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreakoutRoomUserItem',
  props: {
    id: {
      type: String,
      default: ''
    },
    avatar: {
      type: String,
      default: ''
    },
    full_name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.user-item {
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0px;
    box-shadow: rgb(255 255 255 / 15%) 0px -1px 0px inset;
    padding: 4px;
}

.user-avatar {
    margin-right: 10px;
}

.user-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>