<template>
  <b-button v-b-toggle.sidebar-menu variant="primary" size="sm">
    <b-icon icon="list" aria-hidden="true" v-if="!is_auth_flag"></b-icon>
    <b-avatar class="" size="sm" v-bind:text="user_shortname"
              v-if="is_auth_flag && !user_has_picture "></b-avatar>
    <b-avatar class="" size="sm" variant="info" v-bind:src="user_picture"
              v-if="is_auth_flag && user_has_picture"></b-avatar>
    <span class="pl-1" v-if="!is_conf_now">Профиль</span>
  </b-button>
</template>

<script>
  export default {
    name: 'ProfileButton',
    props: {
      is_auth_flag: {
        type: Boolean,
        default: false
      },
      user_shortname: {
        type: String,
        default: ""
      },
      user_has_picture: {
        type: Boolean,
        default: false
      },
      user_picture: {
        type: String,
        default: ""
      },
      is_conf_now: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {

      }
    }
  }
</script>

<style>

</style>