<template>
  <div>
    <b-button id="manage-button" class="panel-btn pb-2">
      <b-icon icon="diagram3"></b-icon>
    </b-button>
    <b-popover id="btn-popover"
               target="manage-button"
               triggers="hover"
               :show.sync="popoverShow"
               placement="righttop"
               container="my-container"
               ref="popover"
               title="Групповое управление"
    >
      <div class="popover-btn-container">
        <div
            class="manage-btn-item"
            variant="primary"
            size="sm"
            @click="this.changeChat"
        >
          <b-icon icon="chat-fill" class="menu-btn-icon" :variant="this.$root.global_object.conf_states.isAllowChat ? 'success' : 'danger'"/>
          <div>
            Доступ в чат
          </div>
        </div>
        <div
            class="manage-btn-item"
            @click="this.changeShareScreen"
            variant="primary"
            size="sm"
        >
          <b-icon size="sm"  icon="arrow-up-right-square-fill" class="menu-btn-icon" :variant="this.$root.global_object.conf_states.isAllowShareScreen ? 'success' : 'danger'"/>
          <div>
            Трансляции
          </div>
        </div>
        <div
            class="manage-btn-item"
            @click="this.changeCamera"
            variant="primary"
            size="sm"
        >
          <b-icon icon="camera-video-fill" class="menu-btn-icon" :variant="this.$root.global_object.conf_states.isAllowCamera ? 'success' : 'danger'"/>
          <div>
            Камеры
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
const MANAGE_BUTTON = 'MANAGE_BUTTON';
export default {
  watch: {},
  name: 'ManageButton',
  components: {},
  data() {
    return {
      popoverShow: false,
      //isAllowChat: true,
      //isAllowCamera: true,
      //isAllowShareScreen: true
    }
  },
  computed: {},
  mounted() {
    this.$root.global_object.conf_states.manageButtonListener = this.globalEventListener;
    this.$root.global_object.conf_states.manageButtonNewUserListener = this.newUserListener;
  },
  methods: {
    changeCamera(){
      this.$root.global_object.conf_states.isAllowCamera = !this.$root.global_object.conf_states.isAllowCamera;
      this.sendCommand();
    },
    changeChat(){
      this.$root.global_object.conf_states.isAllowChat = !this.$root.global_object.conf_states.isAllowChat;
      this.sendCommand();
    },
    changeShareScreen(){
      this.$root.global_object.conf_states.isAllowShareScreen = !this.$root.global_object.conf_states.isAllowShareScreen;
      this.sendCommand();
    },
    newUserListener(user_id) {
      //alert("New join " + user_id);
      if(this.$root.global_object.conf_states.is_moderator)
        this.sendCommandToUser(user_id)
    },
    globalEventListener(command) {
     // alert(command.tool);
      // let currentTimeStamp =  Date.now();
      if (command.isCommand) {
        if(command.isSync)
            //alert("Get Sync");
          if (this.$root.global_object.conf_states.currentConnectTimeStamp < command.senderTimeStamp)
            return
        //alert("Before Switch" + command.state)
        switch (command.tool) {
          case MANAGE_BUTTON:
            this.setState(command.state)
            break
        }
      }
    },
    sendCommand() {

      if (!this.$root.global_object.conf_states.is_planned)
        return;

      const command = {
        isCommand: true,
        senderTimeStamp: this.$root.global_object.conf_states.currentConnectTimeStamp,
        tool: MANAGE_BUTTON,
        isSync: false,
        state:{
          isAllowChat:  this.$root.global_object.conf_states.isAllowChat,
          isAllowCamera:  this.$root.global_object.conf_states.isAllowCamera,
          isAllowShareScreen:  this.$root.global_object.conf_states.isAllowShareScreen
        },
        id: this.$root.global_object.conf_states.occupant_id
      };
      this.$root.global_object.conf_states.methods.sendCommandToAllParticipants(command)

    },
    sendCommandToUser(user_id) {
      if (!this.$root.global_object.conf_states.is_planned)
        return;

      const command = {
        isCommand: true,
        senderTimeStamp: this.$root.global_object.conf_states.currentConnectTimeStamp,
        tool: MANAGE_BUTTON,
        isSync: true,
        state:{
          isAllowChat:  this.$root.global_object.conf_states.isAllowChat,
          isAllowCamera:  this.$root.global_object.conf_states.isAllowCamera,
          isAllowShareScreen:  this.$root.global_object.conf_states.isAllowShareScreen
        },
        id: this.$root.global_object.conf_states.occupant_id
      };
      this.$root.global_object.conf_states.methods.sendCommandToParticipant(command, user_id)
    },
    setState(state){
      this.$root.global_object.conf_states.isAllowShareScreen = state.isAllowShareScreen;
      this.$root.global_object.conf_states.isAllowChat = state.isAllowChat;
      this.$root.global_object.conf_states.isAllowCamera = state.isAllowCamera;
    }
  }
}
</script>


<style scoped>
.panel-btn {
  background-color: #151515;
  border: none;
}
.popover-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  max-width: 20rem;

}
.manage-btn-item {
  padding: 0.1rem 0.1rem;
  display: flex;
  align-items: center;
  justify-content: start ;
}
.manage-btn-item:hover {
  background-color: #5a6268;
  cursor: pointer;
}
.menu-btn-icon {
  color: white;
  padding: 2px;
  margin: 5px;
}

</style>