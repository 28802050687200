<template>
  <div class="room-container">
    <div class="room-item">
      <div class="toggle-participants-btn">
        <b-button @click="toggle_participants" v-b-toggle="breakoutRoomName">
          <b-icon v-bind:icon="getIcon()" scale="0.9" />
        </b-button>
      </div>

      <div class="room-name">
        <span>{{ `Переговорная комната ${this.breakoutRoomNumber + 1}` }} (0)</span>
      </div>

    </div>

    <b-collapse :id="breakoutRoomName" class="mt-2">
      <div class="rooms-btn-container">
        <b-link :to="{ name: 'conf', params: { id: this.breakoutRoomName } }">
          <b-button variant="primary">Подключиться</b-button>
        </b-link>
        <b-button @click="api_delete_breakout_room" variant="danger">Удалить</b-button>
      </div>
      <div class="my-1">
        <BreakoutRoomUserItem
            v-for="(item, index) in participants"
            :key="index"
            :id="item.occupant_id"
            :avatar="item.profile_pic"
            :full_name="item.user_name"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import BreakoutRoomUserItem from "@/components/microcomponents/BreakoutRoomUserItem.vue";

export default {
  name: 'ConfMenuRoomItem',
  components: {BreakoutRoomUserItem},
  props: {
    breakoutRoomNumber: {
      type: Number,
      default: 0
    },
    breakoutRoomName: {
      type: String,
      default: ''
    },
    participants: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  computed: {

  },
  data() {
    return {
      show_participants: false
    }
  },
  methods: {
    getIcon() {
      return this.show_participants ? 'chevron-down' : 'chevron-up';
    },
    toggle_participants() {
      this.show_participants = !this.show_participants;
    },
    api_delete_breakout_room() {
      this.axios
          .post('/api/v2/breakout_rooms/close_breakout_room', {
            breakout_room_name: this.breakoutRoomName
          }, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(() => {
            this.$parent.api_get_breakout_rooms_info();
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>

<style>
  .room-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    min-height: 30px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
  }

  .toggle-participants-btn {
    margin-right: 12px;
  }

  .toggle-participants-btn button {
    padding: 1px 4px;
    border-radius: 30%;
  }

  .room-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rooms-btn-container {
    display: flex;
    justify-content: center;
  }

  .rooms-btn-container button {
    padding: 1px;
    margin: 2px 6px;
  }
</style>