<template>
  <b-container>
    <b-row align-h="end" class="pb-3" v-if="is_moderator">
      <b-col class="col-auto"><b-button variant="primary" @click="download_meeting_report">Протокол</b-button></b-col>
      <b-col class="col-auto pl-0"><b-button variant="primary" @click="editor_status=false;creatorEditorTitle='Добавление вопроса';openVotingQuestionCreator()">+ Добавить</b-button></b-col>
      <b-col class="col-auto pl-0"><b-button variant="secondary" @click="openGeneralSettings()"><b-icon size="1" icon="gear"></b-icon></b-button></b-col>
    </b-row>
    <b-card v-for="(question,index) in this.questions" class="mb-2 p-0" style="background-color: #f8f9fa" no-header :key="index">
      <b-card-body class="pl-2 pr-2 pt-0 pb-0">
        <b-row align-h="between" >
        <b-col>
          <b-row><b>Текст вопроса:</b></b-row>
          <b-row>{{`Вопрос № ${index+1}. ${question.question}`}}</b-row>
        </b-col>
          <b-col class="pr-0">
            <b-row class="col-auto pr-0" align-h="end">
              <b-col class="col-auto p-0" v-if="is_moderator"><b-button @click="editor_status=true;creatorEditorTitle='Редактирование вопроса';selected_question=question; openVotingQuestionEditor()" size="sm" variant="link">Редактировать</b-button></b-col>
              <b-col class="col-auto p-0" v-if="is_moderator"><b-button size="sm" style="color: red" variant="link" @click="selected_question=question;confirmDeletion()">Удалить</b-button></b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row><b>Описание:</b></b-row>
        <b-row class="pb-3" style="border-bottom: 2px solid lightgrey;">{{question.description}}</b-row>
        <b-row>
          <b-col v-if="can_vote" class="col-12 mt-2"
                 :class="{'col-lg-6': show_results_to_everyone || (can_vote && is_moderator)}"
                 :style="can_vote && show_results_to_everyone ? 'border-right: 2px solid light grey':''" >
            <b-row><b>Ваше решение:</b></b-row>
            <b-row>
              <b-form-radio-group
                  id="btn-radios-2"
                  v-model="decisions[index].decision_id"
                  :options="answer_options"
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  class="flex-sm-row flex-xl-row"
                  :class="{'flex-lg-column': show_results_to_everyone || (can_vote && is_moderator) }"
                  buttons
              ></b-form-radio-group>
            </b-row>
            <b-row><b>Особое мнение:</b></b-row>
            <b-row class="pr-3">
            <b-form-textarea
                id="textarea-default"
                placeholder="Поле для ввода текста"
                rows="4"
                v-model="decisions[index].body"
            >
            </b-form-textarea>
            </b-row>
            <b-row class="pt-2 pr-3" align-h="end"><b-button @click="selected_result=decisions[index];vote()" size="sm" variant="primary">Сохранить ответ</b-button>
            </b-row>
          </b-col>
          <b-col class="col-12 mt-2 pl-4" :class="{'col-lg-6': can_vote}"
                 v-if="show_results_to_everyone || is_moderator">
           <b-row><b>Результаты:</b></b-row>
            <b-row class="ProResult">За: {{stat_in_array(question.poll_id, 1)}}</b-row>
     <b-row class="AgainstResult">Против: {{stat_in_array(question.poll_id, 2)}}</b-row>
         <b-row class="AbstainResult">Воздержался: {{stat_in_array(question.poll_id, 3)}}</b-row>
         <b-row class="NoResult">Еще не голосовал: {{stat_in_array(question.poll_id, 0)}}</b-row>
            <b-row>Итого: {{stat_sum(question.poll_id)}}</b-row>
            <b-row class="pt-2"><b-button v-if="show_vote_body || is_moderator" size="sm" @click="selected_result=decisions[index];selected_question=question; openVotingDetailedResults()" class="btnDetailedResults">Подробные результаты</b-button></b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal title="Редактирование вопроса" hide-footer ok-title="Сохранить" cancel-title="Отмена" id="questionEditor-modal" size="lg">
      <VotingQuestionEditor
      :editor_status="editor_status"
      :parent_description="selected_question.description"
      :parent_poll_id="selected_question.poll_id"
      :parent_question="selected_question.question"
      :room_name="room_name"
      ></VotingQuestionEditor>
    </b-modal>
    <b-modal title="Добавление вопроса" hide-footer ok-title="Сохранить" cancel-title="Отмена" id="questionCreator-modal" size="lg">
      <VotingQuestionEditor
          :editor_status="editor_status"
          :room_name="room_name"
      ></VotingQuestionEditor>
    </b-modal>
    <b-modal title="Подробные результаты" ok-only ok-title="Закрыть" ok-variant="secondary" id="detailedResults-modal" size="lg">
      <VotingDetailedResults
        :opinions="this.opinions"
        :results="this.results"
        :stat_in_array="this.stat_in_array"
        :stat_sum="this.stat_sum"
        :poll_id="this.selected_question.poll_id"
      >
      </VotingDetailedResults>
    </b-modal>
    <b-modal title="Подтверждение удаления" cancel-title="Отмена" ok-title="Удалить" @ok="deleteQuestion()" ok-variant="danger" cancel-variant="secondary" id="confirmDeletion-modal" size="sm">
      Вы уверены, что хотите удалить вопрос? Это действие нельзя будет отменить.
    </b-modal>
    <b-modal title="Настройки голосования" ok-only ok-title="Применить" @ok="changeGeneralSettings()" ok-variant="primary" id="generalSettings-modal">
      <b-row class="ml-1 mr-1">
        <b-form-checkbox
            v-model="show_results_to_everyone"
            :checked="true"
            :unchecked-value="false"
        >Показывать результат голосования всем участникам голосования</b-form-checkbox>
      </b-row>
      <b-row class="ml-1 mr-1">
        <b-form-checkbox
            v-model="show_vote_body"
            :checked="true"
            :unchecked-value="false"
        >Разрешать просмотр особых мнений  всем участникам голосования</b-form-checkbox>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import VotingQuestionEditor from "@/components/VotingQuestionEditor";
import VotingDetailedResults from "@/components/VotingDetailedResults";
import SocketioService from "@/services/socketio.service";

export default {
  name: "VotingModal",
  components:{
    VotingQuestionEditor,
    VotingDetailedResults,
  },
  props:{
    room_name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    is_moderator: {
      type: Boolean,
      default: false,
    },
    can_vote:{
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      questions:[
        // {//   question:'Организуем завтра мероприятие?', //   description: 'Содержит обсуждение мероприятия', //   poll_id:13374073, //   },
      ],
      decisions: [
    // { //   decision_id: 0, //   poll_id:13374073, //   body:'abshdbsdc', // },
      ],
      results:[],
      opinions:[],
      stats:[],
      answer_options:[
        {text:'Еще не выбрал', value: 0},
        {text:'За', value: 1},
        {text:'Против', value: 2},
        {text:'Воздержался', value: 3},
      ],
      creatorEditorTitle:'',
      selected_question:{},
      selected_result:{},
      editor_status:false,
      show_results_to_everyone:true,
      show_vote_body: true,

    }
  },
  mounted(){
    SocketioService.socket.emit('get_all_polls', {'room_name': this.room_name, 'email': this.email});
    SocketioService.socket.on('get_all_polls_response', (response)=>{
      if(response.success) {
        this.questions=response.polls;
        this.decisions=response.votes;
        this.show_results_to_everyone = response.show_results_to_everyone;
        this.show_vote_body = response.show_vote_body ;
        console.log(response)
      }
    });
    SocketioService.socket.on('change_poll_response', (response)=>{
      if(!response.success) alert('Не удалось создать/отредактировать вопрос')
    });
    SocketioService.socket.on('poll_refresh', (response)=>{
      this.edit_question_list(response.poll_id, response.question, response.description)
    });
    SocketioService.socket.on(`poll_permissions_refresh`, (response)=>{
      //alert("Pool_refresh");
      this.show_results_to_everyone = response.show_results_to_everyone;
      this.show_vote_body = response.show_vote_body ;
    });
    SocketioService.socket.on(`poll_refresh_delete`, (response)=>{
      let index = this.questions.findIndex(item => item.poll_id === response.poll_id)
      this.questions.splice(index, 1)
      this.decisions.splice(index, 1)
    });
    SocketioService.socket.on(`all_vote_results`, (response)=>{
      this.results = response
      console.log(response, this.results)
    });
    SocketioService.socket.on(`all_vote_body`, (response)=>{
      this.opinions = response
      console.log(response, this.opinions)
    });
    SocketioService.socket.on(`vote_body_refresh`, (response)=>{
      let index = this.opinions.findIndex(item => item.user_email === response.user_email && item.poll_id===response.poll_id)
      if(index===-1){this.opinions.push({
        'body': response.body,
        'poll_id': response.poll_id,
        'user_email': response.user_email,
      })
        index = this.opinions.findIndex(item =>item.poll_id===response.poll_id && item.user_email === response.user_email)
      }
      this.opinions[index].body=response.body
      console.log('VOTE_BODY_REFRESH', this.opinions, this.results, this.stats )
    });
    SocketioService.socket.on(`vote_results_refresh`, (response)=>{
      console.log('STATS', this.stats)
      let index = this.results.findIndex(item =>item.poll_id===response.poll_id && item.user_email === response.user_email)
      // при добавлении вопроса
      if(index===-1){this.results.push({
        'decision_id': response.decision_id,
        'poll_id': response.poll_id,
        'user_email': response.user_email,
        'user_name': response.user_name,
      })
        index=this.results.findIndex(item =>item.poll_id===response.poll_id && item.user_email === response.user_email)
        const old = this.stats.findIndex(item=>item.poll_id===response.poll_id && item.decision_id===this.results[index].decision_id)
        if(old===-1){
          this.stats.push({
            'poll_id': response.poll_id,
            'decision_id': response.decision_id,
            'stat': 1,
          })
        }
        else this.stats[old].stat+=1
      }
      // голосование
      else{
        const old = this.stats.findIndex(item=>item.poll_id===response.poll_id && item.decision_id===this.results[index].decision_id)
        const neww = this.stats.findIndex(item=>item.poll_id===response.poll_id && item.decision_id===response.decision_id)
        this.stats[old].stat-=1
        if(neww!==-1) this.stats[neww].stat+=1
          else {
            this.stats.push({
              'poll_id': response.poll_id,
              'decision_id': response.decision_id,
              'stat':1,
            })
          }
        }
      this.results[index].decision_id=response.decision_id
      console.log('VOTE_BODY_REFRESH', this.results )
    });
     SocketioService.socket.on(`get_stats`, (response)=>{
       this.stats=response
     });
    SocketioService.socket.on(`vote_response`, (response)=>{
      if(response.success)this.toastForVoter()
    })
  },
  destroyed() {
    SocketioService.socket.off('change_poll_response');
    SocketioService.socket.off('poll_refresh');
    SocketioService.socket.off('get_all_polls_response');
    SocketioService.socket.off('poll_permissions_refresh');
    SocketioService.socket.off('poll_refresh_delete');
    SocketioService.socket.off('all_vote_results');
    SocketioService.socket.off('all_vote_body');
    SocketioService.socket.off('vote_body_refresh');
    SocketioService.socket.off('vote_results_refresh');
    SocketioService.socket.off('get_stats');
    SocketioService.socket.off('vote_response');

  },
  // computed:{
  //   poll_results(){
  //
  //   }
  // },
  methods: {
    download_meeting_report(){
      let link = document.createElement('a');
      link.href = '/api/v2/voting/export_results?content_id=' + this.$root.global_object.conf_states.content_id;
      link.target = "_blank"
      link.click();
      // this.axios
      //     .get('/api/v2/voting/export_results?content_id=' + this.content_id,
      //         {withCredentials: true})
      //     .then(response => {
      //       console.log(response)
      //       let link = document.createElement('a');
      //
      //       link.href = URL.createObjectURL(response);
      //       link.target = "_blank"
      //       //link.download = `file_${this.desk_room_name_str}`;
      //       link.click();
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     })
    },
    toastForVoter() {
      this.$bvToast.toast('Ваш ответ сохранен', {
        title: 'Уведомление',
        autoHideDelay: 4000,
      })
    },
    stat_in_array(poll_id, decision_id){
      const n = this.stats.find(stat => stat.poll_id===poll_id && stat.decision_id===decision_id)
      if (n!==undefined) return n.stat
      else return '0'
    },
    stat_sum(poll_id){
      let sum=0
      const statistic = this.stats.filter(item => item.poll_id === poll_id);
      if(statistic!==[]){
      statistic.forEach((count)=>{
        sum+=count.stat
      })}
      return sum
    },
    openVotingQuestionEditor() {
      this.$bvModal.show('questionEditor-modal')
    },
    openVotingQuestionCreator() {
      this.$bvModal.show('questionCreator-modal')
    },
    openGeneralSettings() {
      this.$bvModal.show('generalSettings-modal')
    },
    openVotingDetailedResults() {
      this.$bvModal.show('detailedResults-modal')
    },
    confirmDeletion() {
      this.$bvModal.show('confirmDeletion-modal')
    },
    deleteQuestion() {
      SocketioService.socket.emit('delete_poll', {
        'room_name': this.room_name,
        'email': this.email,
        'poll_id': this.selected_question.poll_id
      });
    },
     edit_question_list(poll_id,question, description) {
       let index = this.questions.findIndex((item) => item.poll_id === poll_id);
       if (index !== -1) {
         this.questions[index].description = description;
       } else {
         this.questions.push({
           question: question,
           description: description,
           poll_id: poll_id,
         })
         this.decisions.push({
             decision_id: 0,
             body: '',
             poll_id: poll_id,
           },
         )
       }
     },
    changeGeneralSettings(){
      SocketioService.socket.emit('change_permissions', {
        'room_name': this.room_name,
        'email': this.email,
        'show_results_to_everyone':this.show_results_to_everyone,
        'show_vote_body': this.show_vote_body });
    },
    vote(){
      console.log(this.selected_result)
      SocketioService.socket.emit('vote', {
        'room_name': this.room_name,
         'email': this.email,
        'poll_id':this.selected_result.poll_id,
        'decision_id': this.selected_result.decision_id,
        'body':this.selected_result.body,
      });
    },
  },
}
</script>

<style scoped>
.btnDetailedResults{
  background-color: white;
  border: 2px solid #0050cf;
  color: #0050cf;
}
.btnDetailedResults:hover{
  background-color: #0050cf;
  color: white;
}
.ProResult{
  color: #02741B;
}
.AgainstResult{
  color: #740202;
}
.AbstainResult{
  color: #021574;
}
.NoResult{
  color: #4D514E;
}
</style>