<template>
  <div>
    <b-button id="popover-reactive-1" class="panel-btn pb-2" v-b-popover.hover.right="{ delay:0, content: 'Таймер' }">
      <b-icon icon="clock-history" :variant="this.playStatus ? 'warning' : ''"></b-icon>
    </b-button>
    <b-popover id="btn-popover"
               target="popover-reactive-1"
               triggers="click"
               :show.sync="popoverShow"
               placement="righttop"
               container="my-container"
               ref="popover"
    >
      <div class="popover-btn-container">
        <div >
          <b-row class="m-0">
            <b-col cols="9" class="m-0 p-1" id="popover-reactive-2">
              <p v-if="!(currentMinutes==0 &&(currentSeconds>0 && currentSeconds<5))" style="font-weight: bold; color: white; font-size: 1.2rem;" class="p-1 m-0" >
                {{ zeroPad(currentMinutes, 2) + ':' + zeroPad(currentSeconds, 2) }}
              </p>
              <p v-if="(currentMinutes==0 &&(currentSeconds>0 && currentSeconds<5))" style="font-weight: bold; color: red; font-size: 1.2rem;" class="p-1 m-0" >
                {{ zeroPad(currentMinutes, 2) + ':' + zeroPad(currentSeconds, 2) }}
              </p>

            </b-col>
            <b-col cols="3" class="m-0 p-0">
              <b-row>
                <b-col class="m-0">
                  <!--                  Звук таймера-->
                  <b-button size="sm" class="panel-btn p-1 m-0" @click="disableBell"
                            v-b-popover.hover.right="this.bellStatus ? 'Со звуком' : 'Звук выкл.'">
                    <b-icon :icon="this.bellStatus ? 'bell' : 'bell-slash'">
                    </b-icon>
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="this.$root.global_object.conf_states.is_moderator || !this.$root.global_object.conf_states.is_planned ">
                <b-col class="m-0">
                  <b-button size="sm" class="panel-btn p-1 m-0" @click="startTimerClick"
                            v-b-popover.hover.right="this.playStatus ? 'Остановить' : 'Запустить'">
                    <b-icon :icon="this.playStatus ? 'stop-fill' : 'play'">
                    </b-icon>
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>

        <b-popover :disabled.sync="disabledPopoverEditor" id="input-popover" class="inpup-popver-cl" target="popover-reactive-2" triggers="click"
                   :show.sync="popoverEditTimeShow"

                   container="my-container"
                   ref="popover2">
          <div class="popover-btn-container">
            <b-row align-h="end" align-content="center" class="p-0 m-0">
              <b-col cols="5" class="p-0">
                <b-form-input placeholder="Мин." id="minutes" type="number" max="200" min="0"
                              style="text-align: center"
                              v-model="editMinutes"
                              :formatter="minutesFormater"></b-form-input>
              </b-col>
              <b-col cols="1" class="label-sm">
                <label for="minutes">м.</label>
              </b-col>
              <b-col cols="5" class="p-0">
                <b-form-input placeholder="Сек." id="seconds" type="number" max="59" min="0"
                              style="text-align:center"
                              v-model="editSeconds"
                              :formatter="secondsFormater"></b-form-input>
              </b-col>
              <b-col cols="1" class="label-sm">
                <label for="seconds">с.</label>
              </b-col>
            </b-row>
            <b-row align-h="end" class="m-0" style="padding-top: 1rem">
              <b-col cols="4">
                <b-button size="sm" variant="secondary" class="p-1" @click="this.cancelEdit">Отмена</b-button>
              </b-col>
              <b-col cols="5">
                <b-button size="sm" variant="primary" class="p-1" @click="this.applyTime">Применить</b-button>
              </b-col>
            </b-row>
          </div>
        </b-popover>
      </div>
    </b-popover>

  </div>
</template>
<script>
const TIMER_EDIT = 'TIMER_EDIT';
const TIMER_START = 'TIMER_START';
const TIMER_STOP = 'TIMER_STOP';

export default {
  watch: {},
  name: 'TimeButton',
  components: {},
  data() {
    return {
      bellStatus: false,
      playStatus: false,
      popoverShow: false,
      popoverEditTimeShow: false,
      disabledPopoverEditor: false,
      editSeconds: 0,
      editMinutes: 15,
      currentSeconds: 0,
      currentMinutes: 15,
      timer: null,
      lastCommand: null,
      timerStartTime: 0,
      duration: 0
    }
  },
  computed: {},
  mounted() {
    this.$root.global_object.conf_states.timerButtonListener = this.globalEventListener;
    this.$root.global_object.conf_states.timerButtonNewUserListener = this.newUserListener;
    this.disabledPopoverEditor = !(this.$root.global_object.conf_states.is_moderator || !this.$root.global_object.conf_states.is_planned);
  },
  methods: {
    onClose() {
      this.popoverShow = false
    },
    toastForModerator(text) {
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 5000,
      })
    },
    disableBell() {
      this.bellStatus = !this.bellStatus;
    },
    calcNewTime() {
      //let fullTime = this.currentMinutes * 60 + this.currentSeconds;
      let nowDifTime = Date.now() - this.timerStartTime; //сколько прошло
      let fullTime = this.duration - Math.floor(nowDifTime/1000);

      if (fullTime > 0) {
        fullTime -= 1;
        this.currentSeconds = fullTime % 60;
        this.currentMinutes = Math.floor(fullTime / 60);
        if (this.bellStatus) {
          if (fullTime <= 4 && fullTime > 0 && (fullTime % 2 == 0) ) {
            let audio = new Audio("/sound/audio-beep.mp3"); // path to file
            audio.play();
          }
        }
      } else if (fullTime <= 0) {
        this.toastForModerator("Время таймера закончилось");
        this.startTimer(true)
      }
    },
    startTimerClick(){
      // let timeStamp = Date.now();
      // //alert(timeStamp);
      this.startTimer(false);
    },
    startTimer(fromCommand = false) {
      if (!this.playStatus) {

        let call = this.calcNewTime
        this.toastForModerator("Таймер запущен");
        this.timer = setInterval(function () {
          call()
        }, 500)
        this.timerStartTime = Date.now();
        this.duration = this.currentMinutes * 60 + this.currentSeconds
        if (!fromCommand)
          this.sendCommand(TIMER_START);
      } else {
        this.duration = this.currentMinutes * 60 + this.currentSeconds
        clearInterval(this.timer);
        this.toastForModerator("Таймер остановлен");
        if (!fromCommand)
          this.sendCommand(TIMER_STOP);
      }
      this.playStatus = !this.playStatus;
    },
    startTimerFromCommand() {
      if(!this.playStatus)
        this.startTimer(true)
    },
    stopTimerFromCommand(){
      if(this.playStatus)
        this.startTimer(true)
    },
    editTimerFromCommand(timerStartTime, fullTime) {
      this.duration = fullTime;
      this.currentSeconds = fullTime % 60;
      this.currentMinutes = Math.floor(fullTime / 60);
      this.timerStartTime = timerStartTime;
    },
    newUserListener(user_id) {
      //alert("New join " + user_id);
      if(this.$root.global_object.conf_states.is_moderator)
        this.sendCommandToUser(user_id)
    },
    globalEventListener(command) {
      // alert(command.tool);
      // let currentTimeStamp =  Date.now();
      if (command.isCommand) {
        if(command.isSync)
          //alert("Get Sync");
          if (this.$root.global_object.conf_states.currentConnectTimeStamp < command.senderTimeStamp)
            return
        //let dif = Math.floor((currentTimeStamp - command.timeStamp)/1000) ;
        //alert("Dif =" + dif);
        //let newFullTime = Math.max(command.fullTime - dif,0);
        let newFullTime = command.fullTime;
        switch (command.tool) {
          case TIMER_EDIT:
            this.editTimerFromCommand(command.timerStartTime, newFullTime);
            break
          case TIMER_STOP:
            this.editTimerFromCommand(command.timerStartTime, newFullTime);
            this.stopTimerFromCommand();
            break
          case TIMER_START:
            this.editTimerFromCommand(command.timerStartTime, newFullTime);
            this.startTimerFromCommand();
        }
      }
    },
    sendCommand(commandName) {
      //alert("RUN CN=" + commandName)
      if (!this.$root.global_object.conf_states.is_planned)
        return;
      let timeStamp = Date.now();
      let fullTime = this.duration;
      const command = {
        isCommand: true,
        isAllow: true,
        senderTimeStamp: this.$root.global_object.conf_states.currentConnectTimeStamp,
        tool: commandName,
        fullTime:  fullTime,
        timeStamp: timeStamp,
        timerStartTime: this.timerStartTime,
        id: "0"
      };
      this.$root.global_object.conf_states.methods.sendCommandToAllParticipants(command)
      this.lastCommand = commandName
    },
    sendCommandToUser(user_id) {
      if (!this.$root.global_object.conf_states.is_planned)
        return;
      let fullTime = this.duration;
      let timeStamp = Date.now();
      let commandName = TIMER_STOP;
      if (this.playStatus)
        commandName = TIMER_START;
      //let timestamp = Date.now();
      const command = {
        isCommand: true,
        isAllow: true,
        isSync: true,
        senderTimeStamp: this.$root.global_object.conf_states.currentConnectTimeStamp,
        tool: commandName,
        fullTime:  fullTime,
        timeStamp: timeStamp,
        timerStartTime: this.timerStartTime,
        id: "0"
      };
      this.$root.global_object.conf_states.methods.sendCommandToParticipant(command, user_id)
    },
    cancelEdit() {
      this.popoverEditTimeShow = false;
    },
    applyTime() {
      this.currentMinutes = this.editMinutes;
      this.currentSeconds = this.editSeconds;
      this.popoverEditTimeShow = false;
      this.sendCommand(TIMER_EDIT);
    },
    zeroPad(num, places) {
      return String(num).padStart(places, '0')
    },
    secondsFormater(value) {
      if (value < 0)
        return 0
      if (value > 59)
        return 59
      else
        return value
    },
    minutesFormater(value) {
      if (value < 0)
        return 0
      else
        return value
    },

    onShow() {
      // This is called just before the popover is shown
      // Reset our popover form variables
      // this.input1 = ''
      // this.input2 = ''
      // this.input1state = null
      // this.input2state = null
      // this.input1Return = ''
      // this.input2Return = ''
    },
    onShown() {
      // Called just after the popover has been shown
      // Transfer focus to the first input
      // this.focusRef(this.$refs.input1)
    },
    onHidden() {
      // Called just after the popover has finished hiding
      // Bring focus back to the button
      // this.focusRef(this.$refs.button)
    },
  }
}

</script>
<style scoped>
#btn-popover .popover-body {
  padding: 1rem;
  background-color: #151515;
  color: white;
}

#input-popover .popover-body {
  padding: 1rem;
  background-color: #151515;
  color: white;
}

.label-sm {
  padding-top: 0.5rem;
  padding-left: 3px;
  padding-right: 0;
  padding-bottom: 0;
}

.inpup-popver-cl .popover-body {
  padding: 1rem;
  background-color: #151515;
  color: white;
}

.popover-btn-container {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  max-width: 15rem;
}

.panel-btn {
  background-color: #151515;
  border: none;
}
</style>