<template>
<b-container>
  <b-row>
    <b>{{this.editor_status?'Текст вопроса:':'Введите текст вопроса:'}}</b></b-row>
    <b-row class="pr-3 pt-2">
      <p v-if="editor_status">{{this.question}}</p>
    <b-form-textarea
        id="textarea-question-to-edit"
        placeholder="Поле для ввода текста"
        rows="4"
        v-else
        v-model="question"
    >
    </b-form-textarea>
  </b-row>
  <b-row class="pt-2">
  <b>Введите описание вопроса:</b></b-row>
  <b-row class="pr-3 pt-2">
    <b-form-textarea
        id="textarea-description-to-edit"
        placeholder="Поле для ввода текста"
        rows="4"
        v-model="description"
    >
    </b-form-textarea>
  </b-row>
<!--  <b-row class="mt-3">-->
<!--    <b-form-checkbox-->
<!--    v-model="show_results_to_everyone"-->
<!--    :checked="true"-->
<!--    :unchecked-value="false"-->
<!--    >Показать результат голосования всем участникам голосования</b-form-checkbox>-->
<!--  </b-row>-->
<!--  <b-row>-->
<!--    <b-form-checkbox-->
<!--        v-model="show_vote_body"-->
<!--        :checked="true"-->
<!--        :unchecked-value="false"-->
<!--    >Разрешить просмотр особых мнений  всем участникам голосования</b-form-checkbox>-->
<!--  </b-row>-->
  <b-button
      class="btn-createConf"
      @click="create_edit_question()"
      variant="primary" right
  >
    {{editor_status ? 'Применить изменения' : 'Создать'}}
  </b-button>
</b-container>
</template>

<script>


import SocketioService from '../services/socketio.service.js';
export default {
  name: "VotingQuestionEditor",
  props:{
    editor_status: {
      type: Boolean,
      default: false,
    },
    parent_poll_id: {
      type: Number,
      default: null,
    },
    parent_question:{
      type: String,
      default: '',
    },
    parent_description:{
      type: String,
      default: '',
    },
    room_name:{
      type: String,
      default: '',
    },
  },
  data(){
    return{
      poll_id:null,
      question:'',
      description:'',
    }
  },
  mounted(){
    this.poll_id=this.parent_poll_id;
    this.question=this.parent_question;
    this.description=this.parent_description;
  },
  methods:{
    create_edit_question(){
      if(this.editor_status){
        SocketioService.socket.emit('change_poll', {
          'room_name': this.room_name,
          'poll_id': this.poll_id,
          'question':this.question,
          'description':this.description,
        })
      }
      else{
        SocketioService.socket.emit('change_poll', {
          'room_name': this.room_name,
          'question':this.question,
          'description':this.description,
        })
      }
      if(this.editor_status){
        this.$bvModal.hide('questionEditor-modal')
      }
      else{
        this.$bvModal.hide('questionCreator-modal')
      }
    },
    }
}
</script>

<style scoped>

</style>