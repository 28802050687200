import {io} from 'socket.io-client';

class SocketioService {
    socket;

    constructor() {
    }

    setupSocketConnection(room_name) {

        this.socket = io({
            withCredentials: true,
            autoConnect: false,
            extraHeaders: {
                "room_name": room_name
            },
            query: {
                "room_name": room_name
            },
            reconnectionDelay: 500,
            reconnectionDelayMax: 2000,
            timeout: 4000

        })
    }
}

export default new SocketioService();