import { render, staticRenderFns } from "./WaitingRoom.vue?vue&type=template&id=d53e6e82&scoped=true"
import script from "./WaitingRoom.vue?vue&type=script&lang=js"
export * from "./WaitingRoom.vue?vue&type=script&lang=js"
import style0 from "./WaitingRoom.vue?vue&type=style&index=0&id=d53e6e82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d53e6e82",
  null
  
)

export default component.exports