<template>
  <div class="conf-manage-wrapper">

    <div class="conf-manage-main-panel">
      <!--      <b-button-group vertical>-->
      <ProfileButton
          :is_auth_flag="this.$root.global_object.is_auth_flag"
          :is_conf_now="this.$root.global_object.is_conf_now"
          :user_has_picture="this.$root.global_object.user_has_picture"
          :user_picture="this.$root.global_object.user_picture"
          :user_shortname="this.$root.global_object.user_shortname"
      />

      <TimerButton/>
      <ManageButton v-if="this.$root.global_object.conf_states.is_moderator"/>
      <b-button
          class="stop-record-btn"
          v-b-popover.hover.right="'Остановить локальную запись'"
          @click="this.$parent.$parent.record_stop"
          v-if="this.$root.global_object.conf_states.is_local_record_now"
          size="sm"
      >
        <b-icon icon="stop-btn" aria-hidden="true" style="color: red"/>
      </b-button>
      <b-button
          class="stop-record-btn"
          v-b-modal.modal-room-info
          v-b-popover.hover.right="'Участники ведут локальную запись'"
          v-if="this.$parent.$parent.nonempty_local_records"
          size="sm"
      >
        <b-icon icon="exclamation-circle" aria-hidden="true" variant='warning'/>
      </b-button>
      <b-button id="waiting-connect" class="panel-btn" v-b-popover.hover.right="'Идет подключение'" v-if="this.$root.global_object.conf_states.is_planned && !this.$root.global_object.socket_connection_status">
        <b-icon icon="arrow-clockwise" animation="spin" ></b-icon>
      </b-button>
      <b-button id="waiting-room-btn" class="panel-btn"
                v-if="this.$root.global_object.is_conf_now && this.$root.global_object.conf_states.is_moderator && this.$root.global_object.conf_states.waiting_room_status && this.$root.global_object.socket_connection_status"
                @click="this.$parent.open_waiting_room"
                v-b-popover.hover.right="'Комната ожидания'">
        <b-icon icon="door-open"
                :animation="this.$root.global_object.conf_states.waiting_users_count > 0 ? 'throb' : ''"/>
      </b-button>
      <!--      <b-button class="button-voting-modal" style="background-color: black ; border: none"-->
      <!--                v-if="this.$root.global_object.is_conf_now && (this.$root.global_object.conf_states.is_moderator && this.$root.global_object.conf_states.voting_status || this.$root.global_object.conf_states.can_vote)"-->
      <!--                @click="open_voting_modal">-->
      <!--        <b-icon class="button-avatar p-0 m-0" badge badge-top square :size="50" badge-variant="primary"-->
      <!--                style="background-color: black" icon="check2-circle" scale="1.2">-->
      <!--        </b-icon>-->
      <!--      </b-button>-->
      <b-button id="voting-btn" class="panel-btn"
                v-if="this.$root.global_object.is_conf_now && this.$root.global_object.socket_connection_status && (this.$root.global_object.conf_states.is_moderator && this.$root.global_object.conf_states.voting_status || this.$root.global_object.conf_states.can_vote)"
                v-b-popover.hover.right="'Система голосования'" @click="this.$parent.open_voting_modal">
        <b-icon icon="check2-circle"/>
      </b-button>
<!--      <b-button v-if="this.$root.global_object.conf_states.is_moderator" @click="open_moderation_menu"-->
<!--                class="panel-btn-menu">-->
<!--        <b-icon icon="gear"></b-icon>-->
<!--      </b-button>-->
      <b-button id="record-btn" class="panel-btn" v-if="!btn_show_flag">
        <b-icon icon="record-circle"/>
      </b-button>
      <!--      <b-button id="mic-btn" class="panel-btn" @click="toggle_mic" :disabled="!isMicAllowed">-->
      <!--        <b-icon :icon="getIcon('mic')"></b-icon>-->
      <!--      </b-button>-->
      <b-button id="camera-btn" class="panel-btn" @click="toggle_camera" :disabled="!( this.$root.global_object.conf_states.is_moderator ||  this.$root.global_object.conf_states.isAllowCamera)">
        <b-icon :icon="getIcon('camera')"></b-icon>
      </b-button>
      <b-button class="panel-btn" @click="toggle_screen_share" :disabled="!( this.$root.global_object.conf_states.is_moderator ||  this.$root.global_object.conf_states.isAllowShareScreen)">
        <b-icon :icon="getIcon('screen_share')"></b-icon>
      </b-button>
      <b-button id="chat-btn" class="panel-btn" @click="toggle_chat" :disabled="!( this.$root.global_object.conf_states.is_moderator ||  this.$root.global_object.conf_states.isAllowChat)">
        <b-icon :icon="getIcon('chat')"></b-icon>
      </b-button>
      <!--      <b-button class="panel-btn" @click="toggle_raise_hand">-->
      <!--        <font-awesome-icon :icon="['fa-regular', 'fa-hand']"/>-->
      <!--      </b-button>-->
      <!--      <b-button class="panel-btn pb-2" @click="toggle_tile_view">-->
      <!--        <b-icon icon="border-all"></b-icon>-->
      <!--      </b-button>-->
      <!--      <b-button class="panel-btn" @click="api_work_check_func">-->
      <!--        <b-icon icon="three-dots"></b-icon>-->
      <!--      </b-button>-->
      <!--      <b-button class="panel-btn-danger" @click="left_room">-->
      <!--        <b-icon icon="telephone-x"></b-icon>-->
      <!--      </b-button>-->
      <!--      </b-button-group>-->

      <b-popover id="btn-popover" target="record-btn" triggers="hover">
        <div class="popover-btn-container">
          <div
              class="record-btn-item"
              v-b-modal.bv-modal-cloud-record
              v-if="!this.$root.global_object.conf_states.is_cloud_record_now"
              variant="primary"
              size="sm"
          >
            <b-icon icon="record-fill" class="popover-btn-icon"/>
            <div>
              Облачная запись
            </div>
          </div>

          <div
              class="record-btn-item"
              v-if="this.$root.global_object.conf_states.is_cloud_record_now"
              @click="this.$parent.$parent.cloud_record_stop"
              variant="primary"
              size="sm"
          >
            <b-icon icon="stop-btn" class="popover-btn-icon"/>
            Остановить запись в облако
          </div>

          <div
              class="record-btn-item"
              v-b-modal.bv-modal-record
              v-if="!isMobile() && !this.$root.global_object.conf_states.is_local_record_now"
              variant="primary"
              size="sm"
          >
            <b-icon icon="record-fill" class="popover-btn-icon"/>
            <div>Локальная запись</div>
          </div>

          <div
              class="record-btn-item"
              v-if="this.$root.global_object.conf_states.is_local_record_now"
              @click="this.$parent.$parent.record_stop"
              variant="primary"
              size="sm"
          >
            <b-icon icon="stop-btn" class="popover-btn-icon"/>
            Остановить локальную запись
          </div>
        </div>
      </b-popover>

      <!--      Модальное окно для локальной записи-->
      <b-modal id="bv-modal-record" title="Локальная запись конференции" centered ok-title="Начать запись"
               cancel-title="Отмена"
               @ok="this.$parent.$parent.record_start">
        <p class="my-2">Локальная запись осуществляется средствами вашего браузера и хранится только локально.</p>
        <p>После начала записи необходимо выбрать текущую вкладку или окно содержимое которого будет записано.</p>
        <p>
          <strong>Для корректной записи аудио потока в некоторых браузерах необходимо дополнительно установить галочку
            на
            пункте «Предоставить доступ к аудио».</strong>
        </p>
        <p> После окончания записи видео файл будет автоматически сохранен на ваш локальный диск.</p>
      </b-modal>

      <!--      Модальное окно для облачной записи-->
      <b-modal id="bv-modal-cloud-record" title="Облачная запись конференции" centered ok-title="Начать запись"
               cancel-title="Отмена"
               @ok="this.$parent.$parent.cloud_record_start">
        <p class="my-2">
          Облачная запись осуществляется средствами облачных рекрдеров и хранится на сервере.
        </p>
        <p>
          После окончания записи видео файл будет обработан и ссылку на него cмогут найти все участники встречи и
          разделе
          <strong>"История"</strong>.
        </p>
      </b-modal>
    </div>


  </div>
</template>

<script>
import ProfileButton from "@/components/microcomponents/ProfileButton";
import TimerButton from "./TimerButton.vue";
import ManageButton from "./ManageButton.vue";
// import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
// import faHand  from "@fortawesome/free-regular-svg-icons";
// import {library}  from "@fortawesome/fontawesome-svg-core";
//
// library.add(faHand);
// Vue.component('font-awesome-icon', FontAwesomeIcon);

// import FixWebm from "fix-webm-duration";
const MANAGE_BUTTON = 'MANAGE_BUTTON';
export default {
  name: 'ConfModerationSidePanel',
  components: {
    ProfileButton,
    TimerButton,
    ManageButton
  },
  props: {
    isModerator: {
      type: Boolean,
      default: false,
    },
    isMicAllowed: {
      type: Boolean,
      default: true
    },
    isCameraAllowed_l: {
      type: Boolean,
      default: true
    },
    isChatAllowed_l: {
      type: Boolean,
      default: true
    },
    isScreenShareAllowed_l: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      btn_show_flag: false,
      mic_muted: true,
      recorder: null,
      recordingData: [],
      recorderStream: null,
      gumStream: null,
      gdmStream: null,
      video_record_start_time: null,
      occupant_id: '',
      nonempty_local_records: false,
      isMicActive: false,
      isCameraActive: false,
      isChatActive: false,
      isScreenShareActive: false,
      isScreenShareAllowed: true,
      isChatAllowed: true,
      isCameraAllowed: true
    }
  },
  watch: {
    isMicAllowed: function () {
      if (this.isMicActive) {
        this.$root.global_object.API.executeCommand('toggleAudio');
        this.isMicActive = false;
      }
    },
    // isCameraAllowed: function () {
    //   //if (this.isCameraActive) {
    //   this.$root.global_object.API.executeCommand('toggleVideo');
    //   //  this.isCameraActive = false;
    //   //}
    // },
    // isChatAllowed: function () {
    //   if (this.isChatActive) {
    //     this.$root.global_object.API.executeCommand('toggleChat');
    //   }
    // },
    // isScreenShareAllowed: function () {
    //   if (this.isScreenShareActive) {
    //     this.$root.global_object.API.executeCommand('toggleShareScreen');
    //     this.isScreenShareActive = false;
    //   }
    // }
  },
  mounted() {
    this.$root.global_object.API.addEventListeners({
      screenSharingStatusChanged: this.screenSharingStatusChangedListener
    });
    this.$root.global_object.API.addEventListeners({
      videoMuteStatusChanged: this.videoMuteStatusChangedListener
    });
    this.$root.global_object.API.addEventListeners({
      chatUpdated: this.chatUpdateListener
    });
    this.$root.global_object.conf_states.sidePanelListener = this.globalEventListener;

  },
  methods: {
    screenSharingStatusChangedListener(event) {
      this.isScreenShareActive = event.on;
    },
    videoMuteStatusChangedListener(event) {
      this.isCameraActive = !event.muted;
    },
    chatUpdateListener(event){
      this.isChatActive = event.isOpen;
    },
    left_room() {
      this.$root.global_object.left_room();
    },
    open_moderation_menu() {
      this.$root.global_object.is_moderation_mode = !this.$root.global_object.is_moderation_mode;
    },
    toggle_mic() {
      this.$root.global_object.API.executeCommand('toggleAudio');
      this.isMicActive = !this.isMicActive;
    },
    toggle_camera() {
      this.$root.global_object.API.executeCommand('toggleVideo');
      //this.isCameraActive = !this.isCameraActive;
    },
    toggle_chat() {
      this.$root.global_object.API.executeCommand('toggleChat');
      //this.isChatActive = !this.isChatActive;
    },
    toggle_screen_share() {
      this.$root.global_object.API.executeCommand('toggleShareScreen', {enable: !this.isScreenShareActive});
      //this.isScreenShareActive = !this.isScreenShareActive;
    },
    toggle_tile_view() {
      this.$root.global_object.API.executeCommand('toggleTileView');
    },
    toggle_raise_hand() {
      this.$root.global_object.API.executeCommand('toggleRaiseHand');
    },
    getIcon(tool) {
      switch (tool) {
        case 'mic':
          return this.isMicActive ? 'mic' : 'mic-mute';
        case 'camera':
          return this.isCameraActive ? 'camera-video' : 'camera-video-off';
        case 'chat':
          return this.isChatActive ? 'chat-text-fill' : 'chat-text';
        case 'screen_share':
          return this.isScreenShareActive ? 'arrow-up-right-square-fill' : 'arrow-up-right-square';
        default:
          return '';
      }
    },
    api_work_check_func() {
      //this.$root.global_object.API.executeCommand('sendEndpointTextMessage', '3aa3adc7-661a-48d2-86d9-3da0dc585cea@meet3.miem.vmnet.top', 'randomText');

      this.$root.global_object.API.getRoomsInfo()
          .then(item => {
            console.log(item.rooms[0].participants);
            console.log(item.rooms[0].participants[0].id);
            //TODO удалить тестовую строку
            this.$root.global_object.API.executeCommand('sendEndpointTextMessage', item.rooms[0].participants[0].id, 'randomText');
          });
    },

    globalEventListener(command) {
      // alert(command.tool);
      // let currentTimeStamp =  Date.now();
      if (command.isCommand) {
        if(command.isSync)
            //alert("Get Sync");
          if (this.$root.global_object.conf_states.currentConnectTimeStamp < command.senderTimeStamp)
            return
        //alert("Before Switch" + command.state)
        switch (command.tool) {
          case MANAGE_BUTTON:
            this.setState(command.state)
            break;
        }
      }
    },
    setState(state){
      console.log("!!!!DEBUG " + JSON.stringify(state));
      if (!state.isAllowShareScreen && this.isScreenShareActive)
        this.$root.global_object.API.executeCommand('toggleShareScreen', {enable: !this.isScreenShareActive});
      if (!state.isAllowCamera && this.isCameraActive)
        this.$root.global_object.API.executeCommand('toggleVideo');
      if (!state.isAllowChat && this.isChatActive) {
        this.$root.global_object.API.executeCommand('toggleChat', {enable: !this.isChatActive});
      }

      this.$root.global_object.conf_states.isAllowShareScreen = state.isAllowShareScreen;
      this.$root.global_object.conf_states.isAllowChat = state.isAllowChat;
      this.$root.global_object.conf_states.isAllowCamera = state.isAllowCamera;


    },
    isMobile() {
      let vall = navigator.userAgent.match(/Android/i)
          || navigator.userAgent.match(/BlackBerry/i)
          || navigator.userAgent.match(/iPhone|iPad|iPod/i)
          || navigator.userAgent.match(/Opera Mini/i);
      if (vall == null) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style>
.conf-manage-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 2.5%;
  min-width: 2.8rem;
  height: 100%;
  padding: 0.1rem 0 0;
  margin: 0;
  background-color: #151515;
}

.conf-manage-main-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.panel-btn {
  background-color: #151515;
  border: none;
}

.panel-btn-danger {
  background-color: #bc0020;
  border: none;
}

.panel-btn-menu {
  background-color: #0050cf;
  border: none;
}

.panel-btn:active, .panel-btn-menu:active {
  background: white;
}


.popover-btn-container {
  display: flex;
  flex-direction: column;
}

.popover-btn-container button {
  margin: 2px 0;
}

.popover-btn-icon {
  color: red;
  padding-right: 24px;
}

.record-btn-item {
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.record-btn-item:hover {
  background-color: #5a6268;
  cursor: pointer;
}

.record-btn-item div {
  color: white;
}

#btn-popover .popover-body {
  padding: 0;
  background-color: #151515;
  color: white;
}

.panel-btn:focus {
  outline: none;
  background: #a3a3a3;
}

.panel-btn:active, .panel-btn-menu:active {
  background: white;
}

#btn-popover .arrow:after {
  border-right-color: #151515;
}

.stop-record-btn {
  background-color: #151515;
  border: none;
}
</style>