<template>
<b-container>
  <b-row>
  <b-table
      id="table-transition-example"
      :items="array"
      :fields="fields"
      borderless
      hover
      small
      class="tableResults"
      primary-key="a"
      :tbody-transition-props="transProps"
  >
    <template #cell(user_name)="data">
      {{ data.value ? data.value : data.item.user_email }}
    </template>
    <template #cell(decision_id)="data" >
       <p :style="decisionColor(data.value)" class="p-0 m-0">{{ data.value ? data.value : 'Не доступно' }}</p>
    </template>
    <template #cell(body)="data">
      {{ data.value ? data.value : 'Не доступно'  }}
    </template>
  </b-table>
  </b-row>
  <b-row class="countResult ProResult">За: {{stat_in_array(poll_id, 1)}}</b-row>
  <b-row class="countResult AgainstResult">Против: {{stat_in_array(poll_id, 2)}}</b-row>
  <b-row class="countResult AbstainResult">Воздержался: {{stat_in_array(poll_id, 3)}}</b-row>
  <b-row class="countResult NoResult">Еще не голосовал: {{stat_in_array(poll_id, 0)}}</b-row>
  <b-row class="countResult">Итого: {{stat_sum(poll_id)}}</b-row>
</b-container>
</template>

<script>
// import SocketioService from "@/services/socketio.service";


export default {
  name: "VotingDetailedResults",
  props:{
    results: {
      type: Array,
      default(){
        return []
      }
    },
    opinions: {
      type: Array,
      default(){
        return []
      }
    },
    stat_in_array:{
      type: Function,
      default() {
        return 1
      }
    },
    stat_sum:{
      type: Function,
      default() {
        return 1
      }
    },
    poll_id:{
        type: Number,
        default: null,
    }
  },
  data(){
    return{
      fields: [
        { key: 'user_name', label:'Имя', sortable: true },
        { key: 'decision_id', label:'Голос',sortable: true },
        { key: 'body', label:'Особое мнение', sortable: false },
      ],
      transProps: {
        // Transition name
        name: 'flip-list'
      },
    }
  },
  computed:{
   array(){
      console.log(this.poll_id)
      let voters = this.results.filter(item => item.poll_id === this.poll_id);
      console.log('FILTERED', voters)
      let bodies = this.opinions.filter(item => item.poll_id === this.poll_id);
      let list=[]
      voters.forEach((item,index)=>
      {
        let dec_id = 0
        if(item.decision_id===0) dec_id='Еще не проголосовал'
        else if(item.decision_id===1) dec_id='За'
        else if(item.decision_id===2) dec_id='Против'
        else dec_id='Воздержался'
        list.push({
          'decision_id':dec_id,
          'user_name': item.user_name,
          'user_email': item.user_email,
          'body': bodies[index].body,
        })
      })
      console.log('LIST', list)
     return list
    }
  },
  mounted(){

  },
  methods:{
  decisionColor(value){
    if(value === 'За')return 'color:#02741B;'
    else if(value === 'Против') return 'color:#740202;'
    else if(value === 'Воздержался') return 'color: #021574;'
    else return 'color: #4D514E;'
  }
  }
}
</script>

<style scoped>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
.countResult {
  padding-left: 4.8px;
  font-size: medium;
}
.countResult:hover {
  font-weight: bold;
}
.ProResult{
  color: #02741B;
}
.AgainstResult{
  color: #740202;
}
.AbstainResult{
  color: #021574;
}
.NoResult{
  color: #4D514E;
}
</style>