<template>
  <div id="ConfPlanning" :class="getStyle()">
    <b-container>
      <b-form-group
          class="mb-0"
      >
        <b-form-group
            label="Тема"
            label-for="subtitle-topic"
            label-cols-lg="4"
            label-align-sm="left"
            label-class="font-weight-bold"
        >
          <b-form-input type="text" :state="conference.description!==''" id="subtitle-topic"
                        v-model="conference.description">
          </b-form-input>
        </b-form-group>
        <b-form-group
            label="Описание"
            label-for="subtitle-info"
            label-cols-lg="4"
            label-align-sm="left"
            label-class="font-weight-bold"
        >
          <b-form-input type="text"  id="subtitle-info" placeholder="Текст на окне подключения"
                        v-model="conference.room_info">
          </b-form-input>
        </b-form-group>
        <b-form-group
            label="Когда"
            label-for="subtitle-when"
            label-cols-lg="4"
            label-align-sm="left"
            label-class="font-weight-bold"
        >
          <b-row>
            <b-col>
              <b-form-input type="datetime-local"  :state="start_time!==''" v-model="start_time" :min="dateNow">
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="col-auto my-auto">
              <b-dropdown id="dropdown-recurring" :text="recurring_text">
                <b-dropdown-item href="#" @click="change_recurring_text('Не повторять')">Не повторять</b-dropdown-item>
                <b-dropdown-item href="#" @click="change_recurring_text('Ежедневно')">Ежедневно</b-dropdown-item>
                <b-dropdown-item href="#" @click="change_recurring_text('Еженедельно')">Еженедельно
                  {{ weekdayFromConfDate }}
                </b-dropdown-item>
                <b-dropdown-item v-b-modal.recurring-settings @click="change_recurring_text('Другое...')">Другое...
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col v-if="recurring_type" class="my-auto col-auto">
              <b-link v-b-modal.recurring-settings>
                <b-row>
                  <b-col class="col-12 col-lg-auto"><b>Дни повторения:</b> {{ selected_days_array }}</b-col>
                  <b-col class="col-12 col-lg-auto"><b>Интервал:</b> {{ this.recurring_interval }}
                    {{ this.recurring_interval === 1 ? 'неделя' : "недели" }}
                  </b-col>
                  <b-col class="col-12 col-lg-auto"><b>Окончание:</b>
                    {{ this.recurring_end_flag === true ? `${recurring_end}` : 'нет' }}
                  </b-col>
                </b-row>
              </b-link>
            </b-col>
          </b-row>
          <b-modal id="recurring-settings-alert" ok-only ok-variant="danger" title="Ошибка" size="sm">
            {{ alert_text }}
          </b-modal>
          <!-- Модалка настройки повторения конференции -->
          <b-modal id="recurring-settings" title="Повтор конференции" cancel-title="Отменить изменения"
                   ok-title="Применить" @close="recurring_settings()" @cancel="recurring_settings()"
                   @ok="(event) => apply_recurring_settings(event)">
            <b-form-group label-cols="12" label-class="font-weight-bold" label-cols-sm="6" label-cols-md="6"
                          label="Повторять с интервалом">
              <b-row class="align-h align-x" style="vertical-align: middle;">
                <b-col class="my-auto col-sm-6 col-auto">
                  <b-form-input style="width:100px" :state="recurring_interval>0" type="number" id="recurring-interval"
                                list="list-hours" v-model="recurring_interval">
                  </b-form-input>
                </b-col>
                <b-col class="my-auto col-sm-6 col-auto">
                  <b-button id="text-week" disabled> Неделя
                  </b-button>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-cols="12" label-class="font-weight-bold" label-cols-lg="12" label="Дни повторения">
              <b-row style="padding-bottom: 3px;">
                <b-col v-for="(weekday,index) in weekdays" :key="index" class="recurring-week" cols="auto">
                  <b-form-checkbox v-model="weekday.selected" class="btn-week" button-variant="outline-primary" button>
                    {{ weekday.day }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label-cols="12" label-class="font-weight-bold" label-cols-lg="12" label="Окончание">
              <b-row>
                <b-col>
                  <b-form-radio v-model="recurring_end_flag" @click="this.recurring_end=''" :value="false"
                                :unchecked-value="true">Никогда
                  </b-form-radio>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="my-auto">
                  <b-form-radio v-model="recurring_end_flag" :value="true" :unchecked-value="false">Дата</b-form-radio>
                </b-col>
                <b-col v-if="recurring_end_flag" class="my-auto">
                  <b-form-input v-model="recurring_end" type="date" :min="dateNowForRecurring"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </b-modal>

        </b-form-group>
        <b-form-group
            label="Продолжительность"
            label-for="subtitle-duration"
            label-cols-lg="4"
            label-align-sm="left"
            label-class="font-weight-bold"
        >
          <b-row>
            <b-col class="col-lg-3 col-xl-3 col-sm-3 col-4 pr-2">
              <b-form-input type="number" id="hours" list="list-hours" v-model="duration_hours">
              </b-form-input>
            </b-col>
            <b-col cols="1" class="labels-duration label-hours pl-1">
              <label for="hours">ч</label>
            </b-col>
            <b-form-datalist id="list-hours">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </b-form-datalist>
            <b-col class="col-lg-3 col-xl-3 col-md-3 col-sm-3 col-4 pr-2 pl-0">
              <b-form-input type="number"
                            :state="duration_minutes>0 || (duration_hours>0 && (duration_minutes===0 ||duration_minutes==='00' ||duration_minutes==='0' ))"
                            id="minutes" list="list-minutes" v-model="duration_minutes">
              </b-form-input>
            </b-col>
            <b-form-datalist id="list-minutes">
              <option>00</option>
              <option>15</option>
              <option>30</option>
              <option>45</option>
            </b-form-datalist>
            <b-col cols="2" class="labels-duration label-minutes pl-1"><label for="minutes">мин</label>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
            label="Безопасность"
            label-for="subtitle-safety"
            label-cols-lg="4"
            label-align-sm="left"
            label-class="font-weight-bold"
        >
          <b-form-radio-group
              id="radio-group-safety"
              v-model="valueForSafety"
          >
            <b-row align-h="between">
              <b-col class="my-auto col-10">
                <b-form-radio :value="0">Впускать всех авторизованных пользователей</b-form-radio>
              </b-col>
              <b-col class="my-auto col-2" style="text-align: right;">
                <b-icon id="img-info-3" scale="1" class="active-icon" icon="info-circle">
                </b-icon>
                <b-popover
                    target="img-info-3"
                    triggers="hover focus"
                    content="Смогут зайти все авторизованные пользователи, не добавленные в список">
                </b-popover>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col class="my-auto col-10">
                <b-form-radio :value="1">Впускать всех пользователей</b-form-radio>
              </b-col>
              <b-col class="my-auto col-2" style="text-align: right;">
                <b-icon id="img-info-4" scale="1" class="active-icon" icon="info-circle">
                </b-icon>
                <b-popover
                    target="img-info-4"
                    triggers="hover focus"
                    content="Будет создана ссылка для подключения всех неавторизованных пользователей">
                </b-popover>
              </b-col>
            </b-row>
            <b-row align-h="between">
              <b-col class="my-auto col-10">
                <b-form-radio :value="2">Впускать только приглашенных пользователей</b-form-radio>
              </b-col>
              <b-col class="my-auto col-2" style="text-align: right;">
                <b-icon id="img-info-5" scale="1" class="active-icon" icon="info-circle">
                </b-icon>
                <b-popover
                    target="img-info-5"
                    triggers="hover focus"
                    content="Смогут зайти только пользователи, добавленные в списки организаторов или участников">
                </b-popover>
              </b-col>
            </b-row>
          </b-form-radio-group>
          <b-row align-h="between">
            <b-col class="my-auto col-10">
              <b-form-checkbox
                  class="checkbox-4 left-position"
                  name="checkbox-4"
                  :value="true"
                  :unchecked-value="false"
                  v-model="conference.waiting_room"
              >
                Комната ожидания
              </b-form-checkbox>
            </b-col>
            <b-col class="my-auto col-2" style="text-align: right;">
              <b-icon id="img-info" scale="1" class="active-icon" icon="info-circle">
              </b-icon>
              <b-popover
                  target="img-info"
                  triggers="hover focus"
                  content="В конференцию cмогут войти только пользователи, утвержденные организатором">
              </b-popover>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
            label="Организаторы"
            label-cols-lg="4"
            label-align-sm="left"
            class="mb-0"
            label-class="font-weight-bold"
        >
          <b-row>
            <b-col class="my-auto left-position">
              <b-link v-b-modal.coOrganizers-list>
                {{ this.CoOrganizers_array_length }} организаторы
              </b-link>
              <b-modal id="coOrganizers-list" ok-only title="Организаторы">
                <ul class="added-coOrganizers mt-10">
                  <li v-for="(coOrganizer,index) in conference.coOrganizers" :key="index" class="added-coOrganizer">
                    <b-row v-if="coOrganizer" class="justify-content-md-center row-person-info">
                      <b-col v-if="coOrganizer.user_name" class="col-2 pr-0">
                        <b-avatar :src="getUserLogoURL(coOrganizer.user_email)">
                        </b-avatar>
                      </b-col>
                      <b-col v-else src="/img/cross.svg" class="col-2 pr-0">
                        <b-avatar></b-avatar>
                      </b-col>
                      <b-col class="col-sm-9 col-8 pl-0 pr-0" v-if="coOrganizer.user_name">
                        <p class="justify-content-center">{{ coOrganizer.user_name }}</p>
                      </b-col>
                      <b-col class="col-sm-9 col-8 pl-0 pr-0" v-else>
                        <p class="justify-content-center">{{ coOrganizer.user_email }}</p>
                      </b-col>
                      <b-col cols="1">
                        <b-button
                            id="btn-delete-coOrganizer"
                            @click="deleteCoOrganizer(index)"
                        >
                          <b-icon id="img-cross" class="active-icon" icon="x">
                          </b-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-modal>
            </b-col>
            <b-col>
              <b-button class="btn-add btn-add-org" variant="primary" v-b-modal.addCoOrganizers>
                + Добавить
              </b-button>
              <b-modal id="addCoOrganizers" ok-only title="Добавление организаторов">
                <b-form-group
                    label="Добавить:"
                    label-cols-sm="2"
                    label-align-sm="left"
                    class="mb-0"
                >
                  <b-row class="input-coOrganizer-group" cols="10" no-gutters>
                    <b-col class="col-9">
                      <b-form-input
                          v-model="c_search_request_org"
                          placeholder="введите имя/почту"
                          type="search"
                          debounce="500"
                          list="namesOrg"
                          @keyup.enter="checkInput(c_search_request_org);addNewCoOrganizer();"
                          @update="update_search_org">
                      </b-form-input>
                      <b-form-datalist id="namesOrg" class="OrgExistingList">
                        <option v-for="(res,index) in user" :key="index" :value="res.email"
                                class="datalist-coOrganizer">
                          <div id="orgExisting">
                            {{ res.name + ' '}}  <b> {{'('+res.email +')'}}</b></div>
                        </option>
                      </b-form-datalist>
                    </b-col>
                    <b-col class="col-2">
                      <b-button
                          :class="inputNotNunOrg"
                          @click="checkInput(c_search_request_org);addNewCoOrganizer();"
                          id="btn-addCoOrganizer"
                      >
                        <b-icon id="img-plus" icon="plus-square-fill" class="active-icon" aria-hidden="true">
                        </b-icon>
                      </b-button>
                    </b-col>
                    <b-col class="col-1 text-center">
                      <b-icon id="img-info-addingPerson" class="active-icon " icon="info-circle">
                      </b-icon>
                      <b-popover
                          target="img-info-addingPerson"
                          triggers="hover focus"
                          content="Вы можете ввести имя уже существующего пользователя или добавить нового по email"
                      >
                      </b-popover>
                    </b-col>
                  </b-row>
                  <b-row class="errorRow">
                    <p v-if="errorInInputText" class="errorText">Неправильный ввод имени или почты</p>
                  </b-row>
                </b-form-group>
                <ul class="added-coOrganizers">
                  <li v-for="(coOrganizer,index) in conference.coOrganizers" :key="index" class="added-coOrganizer">
                    <b-row v-if="coOrganizer" class="justify-content-md-center row-person-info">
                      <b-col v-if="coOrganizer.user_name" class="col-2 pr-0">
                        <b-avatar :src="getUserLogoURL(coOrganizer.user_email)">
                        </b-avatar>
                      </b-col>
                      <b-col v-else src="/img/cross.svg" class="col-2 pr-0">
                        <b-avatar></b-avatar>
                      </b-col>
                      <b-col class="col-sm-9 col-8 pl-0 pr-0" v-if="coOrganizer.user_name">
                        <p>{{ coOrganizer.user_name }}</p>
                      </b-col>
                      <b-col class="col-sm-9 col-8 pr-0 pl-0" v-else>
                        <p>{{ coOrganizer.user_email }}</p>
                      </b-col>
                      <b-col class="col-1">
                        <b-button
                            id="btn-delete-coOrganizer"
                            @click="deleteCoOrganizer(index)"
                        >
                          <b-icon id="img-cross" class="active-icon" icon="x">
                          </b-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-modal>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group
            label="Участники"
            label-cols-lg="4"
            label-align-sm="left"
            class="mb-0"
            label-class="font-weight-bold"
        >
          <b-row class="mt-2">
            <b-col class="my-auto left-position">
              <b-link v-b-modal.participants-list>
                {{ this.Participants_array_length }} участники
              </b-link>
              <b-modal id="participants-list" ok-only title="Участники">
                <ul class="added-participants">
                  <li v-for="(participant,index) in conference.participants" :key="index">
                    <b-row v-if="participant" class="justify-content-md-center row-person-info">
                      <b-col v-if="participant.user_name" class="col-2 pr-0">
                        <b-avatar v-bind:src="getUserLogoURL(participant.user_email)">
                        </b-avatar>
                      </b-col>
                      <b-col v-else src="/img/cross.svg" class="col-2 pr-0">
                        <b-avatar></b-avatar>
                      </b-col>
                      <b-col class="col-sm-8 col-8 pl-0 pr-0" v-if="participant.user_name">
                        <p>{{ participant.user_name }}</p>
                      </b-col>
                      <b-col class="col-sm-8 col-8 pl-0 pr-0" v-else>
                        <p>{{ participant.user_email }}</p>
                      </b-col>
                      <b-col cols="1">
                        <b-button
                            id="btn-delete-participant"
                            @click="deleteParticipant(index)"
                        >
                          <b-icon id="img-cross" class="active-icon" icon="x">
                          </b-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-modal>
            </b-col>
            <b-col>
              <b-button
                  class="btn-add btn-add-part"
                  variant="primary"
                  v-b-modal.addParticipants
              >
                + Добавить
              </b-button>
              <b-modal id="addParticipants" ok-only title="Добавление участников">
                <b-form-group
                    label="Добавить:"
                    label-cols-sm="2"
                    label-align-sm="left"
                    class="mb-0"
                >
                  <b-row class="input-participant-group" cols="10" no-gutters>
                    <b-col class="col-9">
                      <b-form-input
                          v-model="c_search_request_part"
                          placeholder="введите имя/почту"
                          type="text"
                          @update="update_search_part"
                          debounce="500"
                          list="namesPart"
                          @keyup.enter="checkInput(c_search_request_part);addNewParticipant();"
                      >
                      </b-form-input>
                      <b-form-datalist id="namesPart">
                        <option v-for="(res,index) in user" :key="index" :value="res.email" class="datalist-participant" >
                          <div id="orgExisting">
                            {{ res.name + ' '}}  <b> {{'('+res.email +')'}}</b></div>
                        </option>
                      </b-form-datalist>
                    </b-col>
                    <b-col class="col-2">
                      <b-button
                          :class="inputNotNunPart"
                          @click="checkInput(c_search_request_part);addNewParticipant();"
                          id="btn-addParticipant"
                      >
                        <b-icon id="img-plus" icon="plus-square-fill" class="active-icon" aria-hidden="true">
                        </b-icon>
                      </b-button>
                    </b-col>
                    <b-col class="text-center col-1">
                      <b-icon id="img-info-addingPerson" class="active-icon" icon="info-circle">
                      </b-icon>
                      <b-popover
                          target="img-info-addingPerson"
                          triggers="hover focus"
                          content="Вы можете ввести имя уже существующего пользователя или добавить нового по email">
                      </b-popover>
                    </b-col>
                  </b-row>
                  <b-row class="errorRow">
                    <p v-if="errorInInputText" class="errorText">Неправильный ввод имени или почты</p>
                  </b-row>
                </b-form-group>
                <ul class="added-participants">
                  <li v-for="(participant,index) in conference.participants" :key="index">
                    <b-row v-if="participant" class="justify-content-md-center row-person-info">
                      <b-col v-if="participant.user_name" class="col-2 pr-0 pl-1">
                        <b-avatar :src="getUserLogoURL(participant.user_email)"></b-avatar>
                      </b-col>
                      <b-col v-else src="/img/cross.svg" class="col-2 pr-0 pl-1">
                        <b-avatar></b-avatar>
                      </b-col>
                      <b-col class="col-sm-8 col-8 pl-0 pr-0" v-if="participant.user_name">
                        <p>{{ participant.user_name }}</p>
                      </b-col>
                      <b-col class="col-sm-8 col-8 pl-0 pr-0" v-else>
                        <p>{{ participant.user_email }}</p>
                      </b-col>
                      <b-col cols="1" class="pr-2">
                        <b-button
                            id="btn-delete-participant"
                            @click="deleteParticipant(index)"
                        >
                          <b-icon id="img-cross" class="active-icon" icon="x">
                          </b-icon>
                        </b-button>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-modal>
            </b-col>
          </b-row>
<!--          <b-row>-->
<!--            <b-col class="my-auto left-position pt-2">-->
<!--              <label class="microphone">Микрофон</label>-->
<!--            </b-col>-->
<!--            <b-col class="my-auto pt-2">-->
<!--              <toggle-button-->
<!--                  color="#0050cf"-->
<!--                  :value="true"-->
<!--                  v-model="conference.user_mic"-->
<!--                  class="toggle-btn btn-tog-microphone">-->
<!--              </toggle-button>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--          <b-row>-->
<!--            <b-col class="my-auto left-position">-->
<!--              <label class="video">Камера</label>-->
<!--            </b-col>-->
<!--            <b-col class="my-auto pt2">-->
<!--              <toggle-button-->
<!--                  color="#0050cf"-->
<!--                  :value="true"-->
<!--                  v-model="conference.user_camera"-->
<!--                  class="toggle-btn btn-tog-video">-->
<!--              </toggle-button>-->
<!--            </b-col>-->
<!--          </b-row>-->
        </b-form-group>
        <b-form-group
            label="Настройки голосования"
            label-cols-lg="4"
            label-align-sm="left"
            class="mb-0"
            label-class="font-weight-bold pr-5"
        >
          <b-col class="left-position pl-0">
            <b-link v-b-modal.voting-list>
              Список с доступом
            </b-link>
            <b-modal id="voting-list" ok-only title="Доступ к голосованию">
              <b-row v-if="this.Participants_array_length===0 && this.CoOrganizers_array_length===0" class="p-3"><p>
                Добавьте пользователей в конференцию для настройки доступа к голосованию.</p></b-row>
              <b-container class="voting-all" v-if="this.Participants_array_length || this.CoOrganizers_array_length">
                <b-form-checkbox
                    v-model="voting_all"
                    :value="true"
                    :unchecked-value="false"
                    @change="toggleAll(voting_all,conference)"
                    class="mb-3"
                >
                  {{ voting_all ? 'Запретить всем' : 'Разрешить всем' }}
                </b-form-checkbox>
                <b-form-group v-for="(coOrganizer,index) in conference.coOrganizers" :key="'coorganizer'+index">
                  <b-row v-if="coOrganizer" class="align-h align-x justify-content-md-center">
                    <b-col v-if="coOrganizer.user_name" class="col-2 my-auto">
                      <b-avatar :src="getUserLogoURL(coOrganizer.user_email)">
                      </b-avatar>
                    </b-col>
                    <b-col v-else src="/img/cross.svg" class="col-2 my-auto ">
                      <b-avatar></b-avatar>
                    </b-col>
                    <b-col class="col-9 my-auto pl-0" v-if="coOrganizer.user_name">
                      <p>{{ coOrganizer.user_name }}</p>
                    </b-col>
                    <b-col class="col-9 my-auto pl-0" v-else>
                      <p>{{ coOrganizer.user_email }}</p>
                    </b-col>
                    <b-col class="col-1 my-auto">
                      <b-form-checkbox v-model="coOrganizer.voting_status"
                                       :value="true"
                                       :unchecked-value="false"
                                       @change="voting_all_settings()"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group v-for="(participant,index) in conference.participants" :key="'participant'+index">
                  <b-row v-if="participant" class="justify-content-md-left">
                    <b-col v-if="participant.user_name" class="col-2 my-auto">
                      <b-avatar v-bind:src="getUserLogoURL(participant.user_email)">
                      </b-avatar>
                    </b-col>
                    <b-col v-else src="/img/cross.svg" class="col-2 my-auto">
                      <b-avatar></b-avatar>
                    </b-col>
                    <b-col class="col-9 my-auto pl-0" v-if="participant.user_name">
                      <p>{{ participant.user_name }}</p>
                    </b-col>
                    <b-col class="col-9 my-auto pl-0" v-else>
                      <p>{{ participant.user_email }}</p>
                    </b-col>
                    <b-col class="my-auto col-1">
                      <b-form-checkbox v-model="participant.voting_status"
                                       :value="true"
                                       :unchecked-value="false"
                                       @change="voting_all_settings()"
                      ></b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-form-group>

              </b-container>
            </b-modal>
          </b-col>
        </b-form-group>
        <b-form-group
            label="Дополнительные параметры"
            label-cols-lg="4"
            label-align-sm="left"
            class="mb-0"
            label-class="font-weight-bold pr-5"
        >
          <b-col class="left-position pl-0">
            <button
                class="button-toggle my-auto btn-additionalInf"
                type="button"
                @click="handler()"
            >
              {{ buttonValue }}
            </button>
          </b-col>
        </b-form-group>
        <b-form-group v-if="buttonValueIsHide">
          <hr class="my-3">
          <b-form-group
              label="Настройки для участников"
              label-for="subtitle-safety"
              label-cols-lg="4"
              label-align-sm="left"
              label-class="font-weight-bold"
              class="add-settings-part"
          >
<!--            <b-row>-->
<!--              <b-col class="my-auto col-10">-->
<!--                <b-form-checkbox-->
<!--                    class="checkbox-5 left-position"-->
<!--                    name="checkbox-5"-->
<!--                    :value="false"-->

<!--                    :unchecked-value="false"-->
<!--                    v-model="conference.restrict_join_before_start"-->
<!--                >-->
<!--                  Разрешить подключаться в любое время-->
<!--                </b-form-checkbox>-->
<!--                -->
<!--              </b-col>-->
<!--              <b-col>-->
<!--                <b-icon id="img-info-2" scale="1" class="active-icon" icon="info-circle">-->
<!--                </b-icon>-->
<!--                <b-popover-->
<!--                    target="img-info-2"-->
<!--                    triggers="hover focus"-->
<!--                    content="Участники смогут подключиться раньше организатора">-->
<!--                </b-popover>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <b-row>
              <b-col class="my-auto">
                <b-form-checkbox
                    class="checkbox-6 left-position"
                    name="checkbox-6"
                    :value="true"
                    :unchecked-value="false"
                    v-model="conference.user_ss"
                >
                  Разрешить демонстрировать экран
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="my-auto">
                <b-form-checkbox
                    class="checkbox-7 left-position"
                    name="checkbox-7"
                    :value="true"
                    :unchecked-value="false"
                    v-model="conference.user_chat"
                >
                  Разрешить писать сообщения в чат
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-group>

<!--          <b-form-group-->
<!--              label="Отправить напоминание"-->
<!--              label-cols-lg="4"-->
<!--              label-align-sm="left"-->
<!--              class="mb-0"-->
<!--              label-class="font-weight-bold">-->
<!--            <b-row>-->
<!--              <b-col class="my-auto">-->
<!--                <b-form-checkbox-->
<!--                    class="checkbox-8 left-position"-->
<!--                    name="checkbox-8"-->
<!--                    :value="true"-->
<!--                    :unchecked-value="false"-->
<!--                    v-model="notification_1h"-->
<!--                >-->
<!--                  За 1 час до начала-->
<!--                </b-form-checkbox>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--              <b-col class="my-auto">-->
<!--                <b-form-checkbox-->
<!--                    class="checkbox-9 left-position"-->
<!--                    name="checkbox-9"-->
<!--                    :value="true"-->
<!--                    :unchecked-value="false"-->
<!--                    v-model="notification_10min"-->
<!--                >-->
<!--                  За 10 минут до начала-->
<!--                </b-form-checkbox>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--            <b-row>-->
<!--              <b-col class="my-auto">-->
<!--                <b-form-checkbox-->
<!--                    class="checkbox-10 left-position"-->
<!--                    name="checkbox-10"-->
<!--                    :value="true"-->
<!--                    :unchecked-value="false"-->
<!--                    v-model="notification_start_conf"-->
<!--                >-->
<!--                  В момент начала конференции-->
<!--                </b-form-checkbox>-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-form-group>-->
          <b-form-group
              label="Изображение конференции"
              label-cols-lg="4"
              label-align-sm="left"
              class="mb-0"
              label-class="font-weight-bold">
            <b-col cols="12" lg="9" xl="9" class="pl-0">
              <b-carousel
                  id="carousel-fade"
                  style="text-shadow: 0 0 2px #000;"
                  controls
                  indicators
                  :interval="0"
                  class="carousel"
                  v-model="screensaver_index"
              >
                <b-carousel-slide v-for="(slide, index) in slides" :key="index"
                                  :img-src="slide"
                >
                </b-carousel-slide>
              </b-carousel>
            </b-col>
          </b-form-group>
          <!--   <b-form-group-->
          <!--      label="Запись"-->
          <!--      label-cols-md="5"-->
          <!--      label-cols-lg="4"-->
          <!--      label-align-sm="left"-->
          <!--      class="mb-0"-->
          <!--      label-class="font-weight-bold">-->
          <!--       <b-form-checkbox-->
          <!--         class="checkbox-11 left-position pt-1"-->
          <!--         name="checkbox-11"-->
          <!--         value="true"-->
          <!--         unchecked-value="false"-->
          <!--        >-->
          <!--          Сделать автоматическую запись конференции-->
          <!--       </b-form-checkbox>-->
          <!--   </b-form-group>-->
        </b-form-group>
      </b-form-group>
      <b-button
          class="btn-createConf"
          @click="create_conference"
          variant="primary" right
      >
        {{ btn_create_edit }}
      </b-button>
      <b-modal id="create-conf-error" ok-only ok-variant="danger" title="Ошибка" size="sm">
        <p>Заполните все необходимые поля</p>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  name: "confPlanning",
  props: {
    page: {
      type: String,
      default: "1"
    },
    search_request: {
      type: String,
      default: ""
    },
    sort: {
      type: String,
      default: ''
    },
    conference_parent:
        {
          type: Object,
          default() {
            return {
              coOrganizers: [],
              description: '',
              room_info: '',
              duration: 0,
              generic_passcode: false,
              notifications: '',
              participants: [],
              recurring_type: '',
              recurring_interval: null,
              recurring_end_flag: false,
              screensaver_path: '',
              recurring_end: 0,
              restrict_join_before_start: false,
              restrict_join_by_list: false,
              room_name: '',
              start_ts: null,
              user_camera: true,
              user_chat: true,
              voting: false,
              user_mic: true,
              user_ss: true,
              waiting_room: true,
            }
          }
        },
    parentApi: {
      type: Function,
      default() {
        return 'Default function'
      }
    },
    btn_create_edit: {
      type: String,
      default: 'Создать',
    },
  },
  data() {
    return {
      message: 'okay',
      buttonValue: 'Показать',
      flag_loading: true,
      info_is_loaded: false,
      c_search_request_org: '',
      c_search_request_part: '',
      voting_all: false,
      alert_text: '',
      screensaver_index: 0,
      user: [],
      recurring_text: 'Не повторять',
      recurring_end_flag: false,
      recurring_type: '',
      recurring_interval: null,
      recurring_end: '',
      buttonValueIsHide: false,
      update_ts: '',
      start_time: '',
      valueForSafety: 0,
      duration_hours: 1,
      duration_minutes: 0,
      notification_1h: false,
      notification_10min: false,
      recurring_status: false,
      notification_start_conf: false,
      newParticipant: {},
      newCoOrganizer: {},
      errorInInput: false,
      errorInInputText: false,
      errorInputStyle: {
        border: '3px dashed lightslategray',
      },
      btnInputNotNun: {
        border: '5px solid red',
        background: 'red',
      },
      conference: this.conference_parent,
      slides: [
        '/img/slide1.jpg',
        '/img/slide2.jpg',
        '/img/slide3.jpg',
        '/img/slide4.jpg',
        '/img/slide5.jpg',
        '/img/slide6.jpg',
        '/img/slide7.jpg',
        '/img/slide8.jpg',
        '/img/slide9.jpg',
        '/img/slide10.jpg',
        '/img/slide11.jpg',

      ],
      weekdays: [
        {day: 'Пн', selected: 'false',},
        {day: 'Вт', selected: 'false',},
        {day: 'Ср', selected: 'false',},
        {day: 'Чт', selected: 'false',},
        {day: 'Пт', selected: 'false',},
        {day: 'Сб', selected: 'false',},
        {day: 'Вс', selected: 'false',},
      ]
    }
  },
  mounted() {
    if (this.conference.room_name !== '') {
      this.duration_settings()
      this.recurring_settings()
      this.notifications_settings()
      this.start_time_settings()
      this.screensaver_settings()
      this.passcode_and_joinByList_settings()
      this.voting_all_settings()
    } else {
      this.axios
          .get('/api/v1/get_user_info?' + this.getRandomInt(3000000).toString(), {
            withCredentials: true
          })
          .then(response => {
            console.log(response)
            this.conference.coOrganizers.push(
                {
                  'user_name': response.data.name,
                  'user_email': response.data.email,
                  'user_sub': response.data.user_sub,
                  'voting_status': false,
                }
            )
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response)
            }
          })
    }
  },
  computed: {
    inputNotNunPart() {
      return this.c_search_request_part ? this.btnInputNotNun : ""
    },
    inputNotNunOrg() {
      return this.c_search_request_org ? this.btnInputNotNun : ""
    },
    voting_all_status() {
      const voting_all_part = this.conference.participants.every(participant => participant.voting_status === true)
      const voting_all_org = this.conference.coOrganizers.every(coOrganizer => coOrganizer.voting_status === true)
      return voting_all_part && voting_all_org
    },
    dateNow() {
      const date = new Date()
      const year_now = date.getFullYear().toString()
      let month_now = date.getMonth() + 1
      if (month_now < 10) {
        month_now = '0' + month_now.toString()
      } else month_now = month_now.toString()
      let day_now = date.getDate()
      if (day_now < 10) {
        day_now = '0' + day_now.toString()
      } else day_now = day_now.toString()
      const time_now = date.toLocaleTimeString().slice(0, -3)
      return year_now + '-' + month_now + '-' + day_now + 'T' + time_now
    },
    dateNowForRecurring() {
      return this.start_time.slice(0, -6)
    },
    CoOrganizers_array_length() {
      if (this.conference.coOrganizers)
        return this.conference.coOrganizers.length
      else return 0
    },
    Participants_array_length() {
      if (this.conference.participants)
        return this.conference.participants.length
      else return 0
    },

    selected_days_array() {
      const arr = []
      this.weekdays.forEach((weekday) => {
        if (weekday.selected === true) arr.push(weekday.day)
      })
      return arr.join(", ")
    },
    voting_verification() {
      const voting_all_part = this.conference.participants.some(participant => participant.voting_status === true)
      const voting_all_org = this.conference.coOrganizers.some(coOrganizer => coOrganizer.voting_status === true)
      return voting_all_part || voting_all_org
    },
    weekdayFromConfDate() {
      let weekday
      if (this.start_time) {
        weekday = new Date(this.start_time).getDay()
      } else {
        weekday = new Date().getDay()
      }
      if (weekday === 0) {
        let currentDay = this.weekdays.find((item, index) => index === 6);
        return currentDay.day
      } else {
        let currentDay = this.weekdays.find((item, index) => index === weekday - 1);
        return currentDay.day
      }
    },
  },
  methods: {
    voting_all_settings() {
      const voting_all_part = this.conference.participants.every(participant => participant.voting_status === true)
      const voting_all_org = this.conference.coOrganizers.every(coOrganizer => coOrganizer.voting_status === true)
      this.voting_all = voting_all_part && voting_all_org
    },
    duration_settings() {
      this.duration_hours = Math.floor(this.conference.duration / 3600)
      this.duration_minutes = Math.floor((this.conference.duration - this.duration_hours * 3600) / 60)
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    selected_weekdays_counter() {
      let count = 0
      this.weekdays.forEach((weekday) => {
        if (weekday.selected === true) count += 1
      })
      return count
    },
    screensaver_settings() {
      if (this.conference.screensaver_path !== '') this.screensaver_index = this.slides.indexOf(this.conference.screensaver_path)
    },
    apply_recurring_settings(event) {
      const selected_weekdays_count = this.selected_weekdays_counter()
      if (this.recurring_interval < 1 || selected_weekdays_count === 0) {
        this.alert_text = 'Выберите интервал повтора и дни повторения'
        event.preventDefault()
        this.$bvModal.show('recurring-settings-alert')
      } else if (this.recurring_end_flag && !this.recurring_end) {
        this.alert_text = 'Укажите дату окончания или выберите "Никогда"'
        event.preventDefault()
        this.$bvModal.show('recurring-settings-alert')
      } else {
        this.selected_to_recurring_type()
        if (this.recurring_end_flag === false) this.recurring_end = ''
      }
      if (this.recurring_interval === 1 && this.recurring_end_flag === false) {
        if (this.recurring_type === '1111111') this.recurring_text = 'Ежедневно'
        if (selected_weekdays_count === 1) this.recurring_text = 'Еженедельно'
      } else if (this.recurring_type === '') {
        this.recurring_text = 'Не повторять'
      } else this.recurring_text = 'Другое...'
    },

    selected_to_recurring_type() {
      this.recurring_type = ''
      this.weekdays.forEach((weekday) => {
        if (weekday.selected === true) {
          this.recurring_type += '1'
        } else {
          this.recurring_type += '0'
        }
      })
    },
    recurring_type_to_selected() {
      this.weekdays.forEach((weekday, index) => {
        weekday.selected = this.conference.recurring_type[index] === '1';
      })
    },
    recurring_settings() {
      this.recurring_type = this.conference.recurring_type
      this.recurring_type_to_selected()
      this.recurring_interval = this.conference.recurring_interval
      this.recurring_end_flag = this.conference.recurring_end_flag
      const count = this.selected_weekdays_counter()
      if (this.recurring_end_flag === true) {
        const date = new Date(this.conference.recurring_end * 1000)
        const year_end = date.getFullYear().toString()
        let month_end = date.getMonth() + 1
        if (month_end < 10) {
          month_end = '0' + month_end.toString()
        } else month_end = month_end.toString()
        let day_end = date.getDate()
        if (day_end < 10) {
          day_end = '0' + day_end.toString()
        } else day_end = day_end.toString()
        this.recurring_end = year_end + '-' + month_end + '-' + day_end
      }
      if (this.recurring_type === '') {
        this.change_recurring_text('Не повторять')
      } else if (count === 1 && this.recurring_end_flag === false && this.recurring_interval === 1) {
        this.change_recurring_text('Еженедельно')
      } else if (count === 7 && this.recurring_end_flag === false && this.recurring_interval === 1) {
        this.change_recurring_text('Ежедневно')
      } else {
        this.change_recurring_text('Другое...')
      }
    },
    notifications_settings() {
      if (this.conference.notifications.charAt(0) === '1') {
        this.notification_1h = true
      }
      if (this.conference.notifications.charAt(1) === '1') {
        this.notification_10min = true
      }
      if (this.conference.notifications.charAt(2) === '1') {
        this.notification_start_conf = true
      }
    },
    change_recurring_text(text) {
      this.recurring_text = text
      if (this.recurring_text === 'Не повторять') {
        this.recurring_type = ''
        this.recurring_end_flag = false
        this.recurring_end = ''
        this.recurring_interval = null
        this.weekdays.forEach((weekday) => {
          weekday.selected = false
        })
      }
      if (this.recurring_text === 'Ежедневно') {
        this.recurring_type = '1111111'
        this.recurring_end_flag = false
        this.recurring_end = ''
        this.recurring_interval = 1
        this.weekdays.forEach((weekday) => {
          weekday.selected = true
        })
      }
      if (this.recurring_text === 'Еженедельно') {
        const day = this.weekdayFromConfDate
        this.recurring_type = ''
        this.weekdays.forEach((weekday) => {
          if (weekday.day === day) {
            weekday.selected = true
            this.recurring_type += '1'
          } else {
            weekday.selected = false
            this.recurring_type += '0'
          }
        })
        this.recurring_end_flag = false
        this.recurring_end = ''
        this.recurring_interval = 1
      }
    },
    passcode_and_joinByList_settings() {
      if (this.conference.generic_passcode === true && this.conference.restrict_join_by_list === false) {
        this.valueForSafety = 1
      } else if (this.conference.generic_passcode === false && this.conference.restrict_join_by_list === true) {
        this.valueForSafety = 2
      } else if (this.conference.generic_passcode === false && this.conference.restrict_join_by_list === false) {
        this.valueForSafety = 0
      }
    },
    start_time_settings() {
      const startTime = new Date(this.conference.start_ts * 1000)
      const year_conf = startTime.getFullYear().toString()
      let month_conf = startTime.getMonth() + 1
      if (month_conf < 10) {
        month_conf = '0' + month_conf.toString()
      } else month_conf = month_conf.toString()
      let day_conf = startTime.getDate()
      if (day_conf < 10) {
        day_conf = '0' + day_conf.toString()
      } else day_conf = day_conf.toString()
      const time_conf = startTime.toLocaleTimeString().slice(0, -3)
      this.start_time = year_conf + '-' + month_conf + '-' + day_conf + 'T' + time_conf
    },
    addNewParticipant() {
      if (!this.errorInInput) {
        if (this.user.some(item => item.name === this.c_search_request_part || item.email === this.c_search_request_part)) {
          this.newParticipant = this.user.find(item => item.name === this.c_search_request_part || item.email === this.c_search_request_part)
        } else {
          this.newParticipant.email = this.c_search_request_part
          this.newParticipant.sub = null
          this.newParticipant.name = null
        }
        if (!(this.conference.participants.some(item => item.user_email === this.newParticipant.email))) {
          this.conference.participants.push({
                user_email: this.newParticipant.email,
                user_name: this.newParticipant.name,
                user_sub: this.newParticipant.sub,
                voting_status: false,
              }
          )
        }
        this.newParticipant = {}
      }
      this.c_search_request_part = ''
    },
    addNewCoOrganizer() {
      if (!this.errorInInput) {
        if (this.user.some(item => item.name === this.c_search_request_org || item.email === this.c_search_request_org)) {
          this.newCoOrganizer = this.user.find(item => item.name === this.c_search_request_org || item.email === this.c_search_request_org)
        } else {
          this.newCoOrganizer.email = this.c_search_request_org
          this.newCoOrganizer.sub = null
          this.newCoOrganizer.name = null
        }
        if (!(this.conference.coOrganizers.some(item => item.user_email === this.newCoOrganizer.email))) {
          this.conference.coOrganizers.push({
            user_email: this.newCoOrganizer.email,
            user_name: this.newCoOrganizer.name,
            user_sub: this.newCoOrganizer.sub,
            voting_status: false,
          })
        }
        this.newCoOrganizer = {}
      }
      this.c_search_request_org = ''
    },
    validation_guest_email(input) {
      let format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return format.test(input);
    },
    checkInput(modal_input) {
      if (!(this.validation_guest_email(modal_input) || this.user.some(item => item.name === modal_input))) {
        this.errorInInput = true
        this.errorInInputText = true
        setTimeout(() => {
          this.errorInInputText = false;
        }, 4000);
      } else this.errorInInput = false
    },
    deleteParticipant(index) {
      this.conference.participants.splice(index, 1)
    },
    deleteCoOrganizer(index) {
      this.conference.coOrganizers.splice(index, 1)
    },
    handler() {
      if (this.buttonValue === 'Показать') {
        this.buttonValue = 'Скрыть'
        this.buttonValueIsHide = true
      } else {
        this.buttonValue = 'Показать'
        this.buttonValueIsHide = false
      }
    },
    toggleAll(selectedAll) {
      if (selectedAll) {
        for (let coOrganizer of this.conference.coOrganizers) {
          coOrganizer.voting_status = true
        }
        for (let participant of this.conference.participants) {
          participant.voting_status = true
        }
      } else {
        for (let coOrganizer of this.conference.coOrganizers) {
          coOrganizer.voting_status = false
        }
        for (let participant of this.conference.participants) {
          participant.voting_status = false
        }
      }
    },
    getUserLogoURL(email, profile_pic = null) {
      if (profile_pic !== null && profile_pic.length > 0)
        return profile_pic;
      let resp_email = "";
      if (email === undefined)
        resp_email = "";
      else
        resp_email = email;
      return 'https://www.gravatar.com/avatar/' + md5(resp_email.trim().toLowerCase()) + '?d=identicon';
    },
    getStyle() {
      if (this.modal)
        return "modal_style";
      else
        return "not_modal_style";
    },
    api_get_users_info(page, limit, search_request, sort, update_ts) {
      this.axios
          .get('/api/v1/get_users?page=' + page + '&limit=' + limit + '&search_request=' + search_request + '&sort=' + sort + '&update_ts=' + update_ts, {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.info_is_loaded = true;
              this.flag_loading = false;
              this.user = response.data.users;

            } else {
              this.info_is_loaded = false;
              this.data_message = 'Данные о пользователях отсутствуют';
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = 'Данные о пользователях отсутствуют';
            this.flag_loading = false;

            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
            }
          })
    },
    update_search_org() {
      this.api_get_users_info(0, 10, this.c_search_request_org, this.sort_request);
      this.$router.push({
        name: this.$route.name,
        query: {
          search_request: this.c_search_request_org,
          sort: this.sort_request
        }
      });
    },
    update_search_part() {
      this.api_get_users_info(0, 10, this.c_search_request_part, this.sort_request);
      this.$router.push({
        name: this.$route.name,
        query: {
          search_request: this.c_search_request_part,
          sort: this.sort_request
        }
      });
    },
    create_conference() {
      if ((this.duration_minutes > 0 || (this.duration_hours > 0 && this.duration_minutes != null)) && this.conference.description !== '' && this.start_time !== '') {
        console.log(this.duration_minutes)
        this.conference.duration = this.duration_hours * 3600 + this.duration_minutes * 60
        const dateStart = new Date(this.start_time)
        this.conference.start_ts = dateStart.getTime() / 1000
        this.conference.notifications = ''
        if (this.notification_1h === true) {
          this.conference.notifications += '1'
        } else {
          this.conference.notifications += '0'
        }
        if (this.notification_10min === true) {
          this.conference.notifications += '1'
        } else {
          this.conference.notifications += '0'
        }
        if (this.notification_start_conf === true) {
          this.conference.notifications += '1'
        } else {
          this.conference.notifications += '0'
        }
        this.conference.recurring_type = this.recurring_type
        this.conference.recurring_interval = Number(this.recurring_interval)
        this.conference.recurring_end_flag = this.recurring_end_flag
        if (this.recurring_end_flag === true) {
          let date = Date.parse(this.recurring_end)
          this.conference.recurring_end = date / 1000
        } else {
          this.conference.recurring_end = 0
        }
        if (this.screensaver_index === 0) {
          this.conference.screensaver_path = ''
        } else this.conference.screensaver_path = this.slides[this.screensaver_index]
        this.conference.voting = this.voting_verification
        if (this.valueForSafety === 0) {
          this.conference.generic_passcode = false
          this.conference.restrict_join_by_list = false
        } else if (this.valueForSafety === 1) {
          this.conference.generic_passcode = true
          this.conference.restrict_join_by_list = false
        } else if (this.valueForSafety === 2) {
          this.conference.generic_passcode = false
          this.conference.restrict_join_by_list = true
        }
        this.api_create_conf(this.conference)
        if (this.conference.room_name) {
          this.$bvModal.hide('modal-edit-conf')
        } else {
          this.$bvModal.hide('modal-create-conf')
        }
        this.conference = {}
      } else {
        this.$bvModal.show('create-conf-error')
      }
    },
    api_create_conf(conference) {
      this.axios
          .post('/api/v2/planned/event', conference,
              {
                withCredentials: true,
                headers: {
                  'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
                }
              }
          )
          .then(response => {
            {
              console.log(response)
              this.parentApi()
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
  }
}
</script>

<style>
.button-toggle {
  border: 0;
  color: #007bff;
  padding-left: 0;
  background-color: transparent;
}

.btn-createConf {
  margin-top: 20px;
  float: right;
}

.toggle-btn {
  float: right;
}

.btn-add {
  float: right;
}

.add-settings-part {
  //border-top: 3px dashed lightslategray;
  padding-top: 10px;
}

#btn-addParticipant {
  border: 0;
  float: right;
  background: white;
}

#btn-addCoOrganizer {
  border: 0;
  float: right;
  background: white;
}

#img-plus {
  color: #0050cf;
}

p {
  padding-top: 10px;
}

#btn-delete-participant {
  border: 0;
  float: right;
  background: white;
  padding: 0;
}

#btn-delete-coOrganizer {
  border: 0;
  float: right;
  background: white;
  padding: 0;
}

#img-cross {
  /*width: 35px;*/
  /*height: 35px;*/
  /*padding-top: 5px;*/
  color: #0050cf;
}

.row-person-info {
  display: flex;
  align-items: center;
}

.carousel {
  margin-top: 15px;
}

.added-participants {
  list-style-type: none;
  padding-left: 0;
  vertical-align: middle;
  margin-top: 10px;
}

.added-coOrganizers {
  list-style-type: none;
  padding-left: 0;
  vertical-align: middle;
  margin-top: 10px;
}

/* .voting-all{
 padding-left: 15px;
}  */
.input-participant-group {
  display: flex;
  align-items: center;
}

.input-coOrganizer-group {
  display: flex;
  align-items: center;
}

label {
  padding-top: 0;
}

.recurring-week {
  padding-right: 0;
  border-radius: 50%;
  padding-top: 7px;
}

.errorText {
  color: red;
  font-size: 13px;
}

.errorRow {
  padding-top: 0;
  padding-left: 15px;
}

.labels-duration {
  padding-top: 1%;
  padding-bottom: 0;
  text-align: left;
}

.left-position {
  text-align: left;
  padding-top: 3px;
}

.btn-additionalInf {
  height: 32px;
}
</style>
