<template>
  <div id="users-monitoring">
    <b-modal size="xl" id="modal-user-info" title="Информация о пользователе" centered ok-only ok-title="Закрыть">
      <span v-if="selected_user">
        <b-row align-h="center" class="justify-content-md-center"
               style="width: 100%; margin: 0px; overflow-wrap: break-word; ">
          <b-col cols="6" class="left-col"><p>Идентификатор:</p></b-col>
          <b-col cols="6" class="right-col"><p><b>{{ selected_user.id }}</b></p></b-col>
        </b-row>

        <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
          <b-col cols="6" class="left-col"><p>Роли:</p></b-col>
          <b-col cols="6" class="right-col"><p><b>{{ selected_user.roles }}</b></p></b-col>
        </b-row>

        <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
          <b-col cols="6" class="left-col"><p>Источник авторизации:</p></b-col>
          <b-col cols="6" class="right-col"><p><b>{{ selected_user.auth_src }}</b></p></b-col>
        </b-row>
        <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
          <b-col cols="6" class="left-col"><p>ID источника авторизации:</p></b-col>
          <b-col cols="6" class="right-col"><p><b>{{ selected_user.sub }}</b></p></b-col>
        </b-row>
        <b-row align-h="center" class="justify-content-md-center " style="width: 100%; margin: 0px">
          <b-col cols="6" class="left-col"><p>Gravatar Logo:</p></b-col>
          <b-col cols="6" class="right-col">
            <p><b-avatar size="md" variant="info" v-bind:src="getUserLogoURL(selected_user.email)"></b-avatar></p>
          </b-col>
        </b-row>
        <b-row v-if="selected_user.profile_pic.length>0" align-h="center" class="justify-content-md-center "
               style="width: 100%; margin: 0px">
          <b-col cols="6" class="left-col"><p>Profile Logo:</p></b-col>
          <b-col cols="6" class="right-col">
            <p><b-avatar size="md" variant="info" v-bind:src="selected_user.profile_pic"></b-avatar></p>
          </b-col>
        </b-row>

      </span>
    </b-modal>
    <b-row align-h="between" class="justify-content-md-center" id="main-row">
      <b-col class="col-md-8 col-xl-8 col-sm-12" id="main-col">

        <b-row align-h="between" class="m-0 p-0">
          <b-col align-self="start" md="12" xl="12" sm="12" xs="12" cols="12" class="p-1 m-0 " id="filters">
            <b-row class="p-0 m-0">
              <b-col xs="12" md="6" xl="6" sm="12" class="p-0 m-0 pb-1">

                <b-form-input size="sm" v-model="c_search_request" placeholder="Поиск пользователей"
                              type="search" debounce="500" @update="update_search"></b-form-input>
              </b-col>
              <b-col xs="12" md="6" xl="6" sm="12" class="p-0 m-0 pb-1">
                <b-row class="p-0 m-0">
                  <b-col cols="2" class="p-1" style="text-align: end">
                    <!--                    <label size="sm" class="pr-1 m-0" label-for="sort"-->
                    <!--                           style="font-size: 0.8rem; vertical-align: center"><strong>Сорт. по</strong></label>-->
                    <b-icon icon="arrow-down-up" aria-hidden="true"></b-icon>
                  </b-col>
                  <b-col cols="10" class="pl-0 p-0">
                    <b-form-select size="sm" v-model="sort_request" placeholder="Сортировка" id="sort"
                                   :options="sort_options" @change="update_sort"></b-form-select>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-overlay :show="flag_loading" rounded="sm" >
          <b-table v-bind:stacked="this.$root.global_object.methods.isMobile()" striped hover v-if="info_is_loaded"
                   style="width: 100%; overflow-wrap: break-word;"
                   :fields="fields_user" :items="user">
            <template #cell(logo)="data">
              <span id="avatar">
                <b-avatar size="sm" variant="info"
                          v-bind:src="getUserLogoURL(data.item.email, data.item.profile_pic)"></b-avatar>
              </span>
            </template>
            <template #cell(name)="data">
              <b-link @click="get_special_user_info(data.item)" v-b-modal.modal-user-info class="user-name pr-1">
                {{ data.item.name }}
              </b-link>
              <b-badge v-if="data.item.guest" variant="warning" pill size="md">Гость</b-badge>

            </template>
            <template #cell(email)="data">
              <a v-bind:href="'mailto:' + data.value " target="_blank"
                 style="overflow-wrap: break-word;">{{ data.value }} </a>
            </template>
            <template #cell(update_ts)="data">
              {{ dateFormat(new Date(Math.trunc(data.value * 1000)), "%d.%m.%Y %H:%M:%S", false) }}
            </template>
            <template #cell(create_ts)="data">
              {{ dateFormat(new Date(Math.trunc(data.value * 1000)), "%d.%m.%Y %H:%M:%S", false) }}
            </template>
          </b-table>
          <p v-else>Информация о пользователях отсутствует.</p>
        </b-overlay>

        <b-row align-h="between">
          <b-col cols="auto">
            <b-pagination-nav
                size="sm"
                v-model="current_page"
                :number-of-pages="number_of_pages"
                use-router
                :base-url='get_url_for_pagination()'
            ></b-pagination-nav>
          </b-col>
          <b-col cols="auto">
            Всего найдено: {{ this.all_users_count }}
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  props: {
    page: {
      type: String,
      default: "1"
    },
    search_request: {
      type: String,
      default: ""
    },
    sort: {
      type: String,
      default: 'name'
    }
  },
  watch: {
    page: function (newVal) {
      this.api_get_users_info(newVal - 1, 10, this.c_search_request, this.sort_request)
      this.current_page = newVal;
    },
    search_request: function (newVal) {
      this.c_search_request = newVal;
    }
  },
  name: "MonitoringUsers",
  data() {
    return {
      flag_loading: true,
      info_is_loaded: false,
      current_page: 1,
      c_search_request: "name",
      number_of_pages: 1,
      all_users_count: 0,
      user: [],
      selected_user: null,
      // user_id: '',
      // user_role: '',
      // user_server: '',
      fields_user: [
        {
          key: 'logo',
          label: ""
        },
        {
          key: 'name',
          label: "Имя пользователя"
        },
        {
          key: 'email',
          label: "Почта"
        },
        {
          key: 'update_ts',
          label: "Последняя активность"
        },
        {
          key: 'create_ts',
          label: "Дата регистрации"
        }
      ],
      sort_request: 'name',
      sort_options: [
        {value: 'name', text: 'Имя пользователя'},
        {value: 'update_ts', text: 'Последняя активность'},
        {value: 'create_ts', text: 'Дата регистрации'},
        {value: 'email', text: 'Почта'}
      ]
    }
  },
  mounted() {
    // this.api_get_users_info();
    let page = this.$route.query.page;
    if (!page) {
      page = 1;
    }
    this.current_page = parseInt(page);
    this.c_search_request = this.$route.query.search_request;
    if (!this.c_search_request) {
      this.c_search_request = '';
    }
    if (this.$route.query.sort)
      this.sort_request = this.$route.query.sort


    this.api_get_users_info(page - 1, 10, this.c_search_request, this.sort_request);
  },
  methods: {
    api_get_users_info(page, limit, search_request, sort, update_ts) {
      this.axios
          .get('/api/v1/get_users?page=' + page + '&limit=' + limit + '&search_request=' + search_request + '&sort=' + sort + '&update_ts=' + update_ts, {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.info_is_loaded = true;
              this.flag_loading = false;

              this.user = response.data.users;
              this.all_users_count = response.data.all_users_count;
              this.number_of_pages = Math.ceil((response.data.all_users_count + 1) / limit)

            } else {
              this.info_is_loaded = false;
              this.data_message = 'Данные о пользователях отсутствуют';
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = 'Данные о пользователях отсутствуют';
            this.flag_loading = false;

            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
            }
          })

    },
    update_search() {
      this.api_get_users_info(0, 10, this.c_search_request, this.sort_request);
      this.$router.push({
        name: this.$route.name,
        query: {
          search_request: this.c_search_request,
          sort: this.sort_request
        }
      });
    },
    update_sort() {
      if (this.sort_request == 'update_ts') {
        this.api_get_users_info(0, 10, this.c_search_request, 'update_ts')
      } else if (this.sort_request == 'create_ts') {
        this.api_get_users_info(0, 10, this.c_search_request, 'create_ts')
      } else if (this.sort_request == 'email') {
        this.api_get_users_info(0, 10, this.c_search_request, 'email')
      } else {
        this.api_get_users_info(0, 10, this.c_search_request, 'name')
      }
      this.current_page = 1;
      this.$router.push({
        name: this.$route.name,
        query: {
          page: this.current_page.toString(),
          search_request: this.c_search_request,
          sort: this.sort_request
        }
      });
    },
    getUserLogoURL(email, profile_pic = null) {
      if (profile_pic !== null && profile_pic.length > 0)
        return profile_pic;
      let resp_email = "";
      if (email === undefined)
        resp_email = "";
      else
        resp_email = email;
      return 'https://www.gravatar.com/avatar/' + md5(resp_email.trim().toLowerCase()) + '?d=identicon';
    },
    get_url_for_pagination() {
      let url = '?';
      if (this.c_search_request && this.c_search_request.length > 0) {
        url += "search_request=" + this.c_search_request + "&";
      }
      url += 'page=';
      return url;
    },
    get_special_user_info(user) {
      this.selected_user = user;
      // this.user_id = user.id;
      // this.user_role = user.roles;
      // this.user_server = user.auth_src;
      // this.user_sub
    },
    dateFormat: function (date, fstr, utc) {
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
  }
}
</script>

<style>
#users-monitoring {
  padding: 1rem;
  padding-top: 0rem;
}

#main-row {
  width: 100%;
  margin: 0px;
}

#main-col {
  width: 100%;
  padding: 0px;
}

b-table {
  width: 100%;
  overflow-wrap: break-word;
}

#filters {
  margin-left: auto;
  margin-right: auto;
}

#avatar {
  text-align: center;
}

b-a {
  color: #0050cf;
}

.left-col {
  text-align: right;
}

.right-col {
  text-align: left;
}

.user-name {
  /*color: darkslateblue;*/
}
</style>