<template>
  <div v-bind:class="menu_container_width">
    <div class="conf-menu-body px-1 py-1">

      <h5>Список участников ({{ this.conf_users.length }})</h5>

      <div class="participantsSearch">
        <b-form-input placeholder="Поиск участников" size="sm"></b-form-input>
      </div>

      <div class="conferenceParticipants my-4">
        <ConfMenuUserItem
            v-for="(item, index) in conf_users"
            :key="index"
            :id="item.id"
            :avatar="item.avatarUrl"
            :full_name="item.displayName"
            :role="item.role"
            :room_name="room_name"
            :is-moderator="isModerator"
            :is-mic-allowed="isMicAllow"
            :is-camera-allowed="isCameraAllow"
            :is-chat-allowed="isChatAllow"
            :is-screen-share-allowed="isScreenShareAllow"
        />
      </div>

      <div class="roomCreateButton mb-4" v-if="!isBreakoutRoom">
        <b-button variant="primary" @click="api_create_breakout_room">Добавить комнату</b-button>
      </div>

      <div class="conferenceTableWrapper my-4">
        <ConfMenuRoomItem
            v-for="(item, index) in breakout_rooms"
            :key="index"
            :breakout-room-number="index"
            :breakout-room-name="item.breakout_room_name"
            :participants="item.participants"
        />
      </div>
    </div>

    <div class="my-4" v-if="isBreakoutRoom">
      <BreakoutRoomUserItem
          v-for="(item, index) in main_room_participants"
          :key="index"
          :id="item.occupant_id"
          :avatar="item.profile_pic"
          :full_name="item.user_name"
      />
    </div>

    <div class="conf-menu-footer">
      <h5>Общее управление</h5>

      <div class="conferenceButtonGroup my-2">
        <b-button
            size="xs"
            @click="allChatsSwitch"
            v-bind:class="footer_btn_margin"
            v-bind:variant="getVariant(this.isChatAllow)"
        >
          <b-icon
              v-bind:icon="getIcon('chat')"
              aria-hidden="true"
          />
        </b-button>

        <b-button
            size="xs"
            @click="allMicsSwitch"
            v-bind:class="footer_btn_margin"
            v-bind:variant="getVariant(this.isMicAllow)"
        >
          <b-icon
              v-bind:icon="getIcon('mic')"
              aria-hidden="true"
          />
        </b-button>

        <b-button
            size="xs"
            @click="allCamerasSwitch"
            v-bind:class="footer_btn_margin"
            v-bind:variant="getVariant(this.isCameraAllow)"
        >
          <b-icon
              v-bind:icon="getIcon('camera')"
              aria-hidden="true"
          />
        </b-button>

        <b-button
            size="xs"
            @click="allScreenSharesSwitch"
            v-bind:class="footer_btn_margin"
            v-bind:variant="getVariant(this.isScreenShareAllow)"
        >
          <b-icon
              v-bind:icon="getIcon('screen_share')"
              aria-hidden="true"
          />
        </b-button>
      </div>
    </div>

  </div>
</template>

<script>
import ConfMenuUserItem from "@/components/microcomponents/ConfMenuUserItem";
import ConfMenuRoomItem from "@/components/microcomponents/ConfMenuRoomItem.vue";
import BreakoutRoomUserItem from "@/components/microcomponents/BreakoutRoomUserItem.vue";

const MIC_TOOL = 'mic';
const CAMERA_TOOL = 'camera';
const CHAT_TOOL = 'chat';
const SCREEN_SHARE_TOOL = 'screen_share';

export default {
  name: 'ConfModerationMenu',
  components: {
    ConfMenuUserItem,
    ConfMenuRoomItem,
    BreakoutRoomUserItem
  },
  props: {
    conf_users: {
      type: Array,
      default: function () {
        return [];
      }
    },
    room_name: {
      type: String,
      default: ''
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
    isMicAllowed: {
      type: Boolean,
      default: true
    },
    isCameraAllowed: {
      type: Boolean,
      default: true
    },
    isChatAllowed: {
      type: Boolean,
      default: true
    },
    isScreenShareAllowed: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      breakout_rooms: [],
      main_room_participants: [],
      isBreakoutRoom: false,
      isMicAllow: this.isMicAllowed,
      isCameraAllow: this.isCameraAllowed,
      isChatAllow: this.isChatAllowed,
      isScreenShareAllow: this.isScreenShareAllowed,
      breakoutRoomsInfoTimerId: null
    }
  },
  mounted() {
    this.api_get_is_breakout_room();

    if (!this.isBreakoutRoom) {
      this.api_get_breakout_rooms_info();

      this.breakoutRoomsInfoTimerId = setInterval(() => {
        this.api_get_breakout_rooms_info();
      }, 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.breakoutRoomsInfoTimerId);
    this.breakoutRoomsInfoTimerId = null;
  },
  computed: {
    menu_container_width() {
      return this.$parent.$parent.isMobile() ? 'col-11 conf-menu-wrapper' : 'col-2 conf-menu-wrapper';
    },
    footer_btn_margin() {
      return this.$parent.$parent.isMobile() ? 'mobile-footer-btn-wrapper' : 'm-2';
    }
  },
  methods: {
    getIcon(tool) {
      switch (tool) {
        case MIC_TOOL:
          return this.isMicAllow ? 'mic-fill' : 'mic-mute-fill';
        case CAMERA_TOOL:
          return this.isCameraAllow ? 'camera-video-fill' : 'camera-video-off-fill';
        case CHAT_TOOL:
          return this.isChatAllow ? 'chat-dots-fill' : 'chat-square-quote-fill';
        case SCREEN_SHARE_TOOL:
          return this.isScreenShareAllow ? 'arrow-up-right-square-fill' : 'box-arrow-down-right';
        default:
          return '';
      }
    },
    getVariant(isToolAllow) {
      return isToolAllow ? 'primary' : 'danger';
    },

    sendCommand(toolName, toolFlag, userId) {
      const command = {
        isCommand: true,
        isAllow: toolFlag,
        tool: toolName,
        id: userId
      };
      const commandJson = JSON.stringify(command);

      this.$root.global_object.API.executeCommand('sendEndpointTextMessage', userId, commandJson);
    },
    switchTool(tool, isToolAllow) {
      this.conf_users.forEach((user) => {
        this.sendCommand(tool, isToolAllow, user.id);
      });

      this.api_set_all_users_tool_rights(tool, isToolAllow);
    },

    allMicsSwitch() {
      this.isMicAllow = !this.isMicAllow;
      this.switchTool(MIC_TOOL, this.isMicAllow);
    },
    allCamerasSwitch() {
      this.isCameraAllow = !this.isCameraAllow;
      this.switchTool(CAMERA_TOOL, this.isCameraAllow);
    },
    allChatsSwitch() {
      this.isChatAllow = !this.isChatAllow;
      this.switchTool(CHAT_TOOL, this.isChatAllow);
    },
    allScreenSharesSwitch() {
      this.isScreenShareAllow = !this.isScreenShareAllow;
      this.switchTool(SCREEN_SHARE_TOOL, this.isScreenShareAllow);
    },

    api_set_all_users_tool_rights(toolName, isAllow) {
      this.axios
          .post('/api/v2/moderating/edit_all_permissions', {
            room_name: this.room_name,
            tool: toolName,
            allow: isAllow
          }, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .catch((error) => {
            console.log(error);
          })
    },
    api_get_breakout_rooms_info() {
      this.axios
          .get('/api/v2/breakout_rooms/get_all_participants?main_room_name=' + this.room_name, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then((response) => {
            console.log(response.data);
            this.breakout_rooms = [...response.data.breakout_rooms];
            this.main_room_participants = [...response.data.main_room_participants];
          })
          .catch((error) => {
            console.log(error);
          })
    },
    api_create_breakout_room() {
      this.axios
          .post('/api/v2/breakout_rooms/create_breakout_room', {
            main_room_name: this.room_name,
            breakout_room_name: this.getBreakoutRoomName()
          }, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then(() => {
            this.$parent.breakoutRoomsCount += 1;
            this.api_get_breakout_rooms_info();
          })
          .catch((error) => {
            console.log(error)
          });
    },
    api_get_is_breakout_room() {
      this.axios
          .get('/api/v2/breakout_rooms/is_breakout_room?room_name=' + this.room_name, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then((response) => {
            this.isBreakoutRoom = response.data.result;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    getBreakoutRoomName() {
      return `${this.room_name}_sub${this.$parent.breakoutRoomsCount + 1}`;
    }
  }
}
</script>

<style>
.conf-menu-wrapper {
    min-width: 18rem;
    background: #223140;
    color: white;
    display: flex;
    flex-direction: column;
}

.conf-menu-body {
    flex: 1 1 0%;
    margin-top: 12px;
    overflow-y: auto;
    position: relative;
}

.conf-menu-footer {
    z-index: 3;
    padding: 10px 0;
}

.conferenceButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.participantsSearch {
    margin-left: auto;
    margin-right: auto;
}

.participantsSearch input {
    text-align: center;
    background: rgb(61, 61, 61);
    color: white;
    border: none;
}

.participantsSearch input:focus {
    background: rgb(61, 61, 61);
    color: white;
    border: 0;
}

.mobile-footer-btn-wrapper {
    margin: 4px 14px;
    padding: 6px 16px;
}
</style>