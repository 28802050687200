<template>
  <div style="width: 100%; height: 100%; padding-top: 0; display: flex;">

    <ConfModerationSidebar
        v-if="this.$root.global_object.is_conf_now"
        :is-moderator="this.isModerator"
        :is-mic-allowed="this.isMicAllowed"
        :is-camera-allowed="this.isCameraAllowed"
        :is-chat-allowed="this.isChatAllowed"
        :is-screen-share-allowed="this.isScreenShareAllowed"
    />

    <div class="iframe-container">

      <ConfModerationPanel
          v-if="(this.$root.global_object.is_conf_now && this.$root.global_object.is_moderation_mode)"
          :conf_users="this.conf_users"
          :room_name="this.$parent.room_text"
          :is-moderator="this.isModerator"
          :is-mic-allowed="this.isMicAllowed"
          :is-camera-allowed="this.isCameraAllowed"
          :is-chat-allowed="this.isChatAllowed"
          :is-screen-share-allowed="this.isScreenShareAllowed"
      />

      <div v-bind:class="iframe_container_width" style="padding: 0">
        <!--        <b-button class="button-waiting-room" style="background-color: black ; border: none"-->
        <!--                  v-if="this.$root.global_object.is_conf_now && this.$root.global_object.conf_states.is_moderator && this.$root.global_object.conf_states.waiting_room_status"-->
        <!--                  @click="open_waiting_room">-->
        <!--          <b-avatar v-if="this.$root.global_object.conf_states.waiting_users_count>0" class="button-avatar p-0 m-0"-->
        <!--                    badge badge-top square :size="50" badge-variant="primary"-->
        <!--                    style="background-color: black" icon="door-open" scale="1.2">-->
        <!--            <template #badge>{{ this.$root.global_object.conf_states.waiting_users_count > 0 ? '!' : '' }}</template>-->

        <!--          </b-avatar>-->
        <!--          <b-avatar v-if="this.$root.global_object.conf_states.waiting_users_count<=0" class="button-avatar p-0 m-0"-->
        <!--                    square :size="50"-->
        <!--                    style="background-color: black" icon="door-open" scale="1.2">-->

        <!--          </b-avatar>-->

        <!--        </b-button>-->
        <!--        <b-button class="button-voting-modal" style="background-color: black ; border: none"-->
        <!--                  v-if="this.$root.global_object.is_conf_now && (this.$root.global_object.conf_states.is_moderator && this.$root.global_object.conf_states.voting_status || this.$root.global_object.conf_states.can_vote)"-->
        <!--                  @click="open_voting_modal">-->
        <!--          <b-icon class="button-avatar p-0 m-0" badge badge-top square :size="50" badge-variant="primary"-->
        <!--                  style="background-color: black" icon="check2-circle" scale="1.2">-->
        <!--          </b-icon>-->
        <!--        </b-button>-->
        <div id="jitsi-container" class="my-container"
             style="width: 100%; height: 100%;  background: #e6e6e6; display: none; ">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>

        <div id="jitsi-text" class="my-container"
             style="text-align: center; vertical-align: middle; width: 100%; display: none; height: 100%;">
          <div id="list-item-1"
               style="display: table-cell; vertical-align: middle; font-size: 18px;">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            Подключение...
          </div>
        </div>
        <!--    Модальное окно приветствия -->
        <b-modal id="modal-screen-saver"   title="Окно приветствия"
                  size="xl" button-size="sm" centered
                  ok-only hide-header class="p-0" ok-title="Закрыть">
          <template #modal-footer="{ cancel }">
            <!--        <b>Custom Footer</b>-->
            <!-- Emulate built in modal footer ok and cancel button actions -->

            <p>Ожидаем модератора</p>
            <b-spinner variant="secondary" label="Spinning"></b-spinner>

            <b-button size="sm" variant="primary" v-if="(get_moderator_state())"
                      @click="forse_get_config">
              Начать встречу
            </b-button>
            <b-button size="sm" variant="secondary" @click="cancel()">
              Отмена
            </b-button>
            <!-- Button with custom close trigger value -->
            <!--        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">-->
            <!--          Forget it-->
            <!--        </b-button>-->
          </template>
<!--          <template #modal-title>-->
<!--            <div  style="background-color: #134372; margin: -1rem; height: 100%">-->
<!--              Название-->
<!--            </div>-->

<!--          </template>-->
          <div   style="align-items: end; display: grid;
          background-color: #134372;
          margin: -1rem;
          min-height: 6rem; padding: 1rem; color: white ">
            <b-row>
              <b-col>
                {{ this.get_current_time_str() }}
              </b-col>
              <b-col style="text-align: end">
                начало <b>{{ this.get_start_time_str()}}</b>
              </b-col>
            </b-row>

            <span style="text-align: end">до начала <b style="font-size: 2.5rem">{{ this.get_waiting_time_str()}}</b> </span>
          </div>
          <div :style=" this.get_style_str() ">
            <p style="font-size: 3rem; color: #171b1e; padding: 1rem; padding-top: 2rem; font-weight: bold;

                text-shadow: white 1px 0 10px;">
              {{ this.$root.global_object.conf_states.description }}
            </p>
            <p style="font-size: 2rem; color: #434a50; padding: 1rem; font-weight:bolder;

                text-shadow: white 1px 0 10px;">
              {{ this.$root.global_object.conf_states.room_info }}
            </p>
          </div>
        </b-modal>
        <!--    Модальное окно комнаты ожидания -->
        <b-modal id="modal-waiting-room" @close="disconnectSocket()" no-close-on-backdrop title="Комната ожидания"
                 size="xl"
                 hide-footer>
          <WaitingRoom
              :is_moderator='this.$root.global_object.conf_states.is_moderator'
              :room_name="$parent.room_text"
              :email="this.guest_status ? this.guest_form.guest_email : this.$parent.user_email"
              :join_room="this.get_usual_token"
              :delete_user="this.delete_waiting_user_count"
              ref="waitingRoomModal"
          >
          </WaitingRoom>
        </b-modal>
        <!--    Модальное окно голосования -->
        <b-modal id="modal-voting" no-close-on-backdrop title="Голосование" size="xl" hide-footer>
          <VotingModal
              :room_name="this.$parent.room_text"
              :email="this.guest_status ? this.guest_form.guest_email : this.$parent.user_email"
              :is_moderator="this.$root.global_object.conf_states.is_moderator"
              :can_vote="this.$root.global_object.conf_states.can_vote"
          >
          </VotingModal>
        </b-modal>
        <!--    Модальное окно для гостевого входа-->
        <b-modal id="modal-guest-login" title="Гостевой вход" no-close-on-backdrop centered scrollable ok-only
                 ok-title="Закрыть" size="md" footer-class="p-2">
          <template #modal-footer="{ cancel }">
            <!--        <b>Custom Footer</b>-->
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="md" variant="primary" type="submit"
                      :disabled="!(validation_guest_room_name && validation_code && validation_guest_email && validation_guest_name)"
                      @click="open_room_as_guest">
              Подключиться
            </b-button>
            <b-button size="md" variant="secondary" @click="cancel()">
              Отмена
            </b-button>
            <!-- Button with custom close trigger value -->
            <!--        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">-->
            <!--          Forget it-->
            <!--        </b-button>-->
          </template>
          <b-form @submit.stop.prevent>
            <b-form-group>
              <label label-for="guest_form_room_name">Имя комнаты:</label>
              <b-form-input placeholder="Введите имя комнаты" debounce="300" v-model="guest_form.room_name"
                            :state="this.validation_guest_room_name"
                            id="guest_form_room_name"
                            required></b-form-input>
              <b-form-invalid-feedback :state="this.validation_guest_room_name">
                Длина имени от 2 до 60 символов. Допустимы только символы латинского алфавита [a-z,A-Z], цифры [0-9] и
                символы
                ["_","-"].
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <label label-for="guest_form_code">Код:</label>
              <b-form-input placeholder="Введите код пропуска" debounce="300" v-model="guest_form.code"
                            :state="this.validation_code"
                            id="guest_form_code"
                            required></b-form-input>
              <b-form-invalid-feedback :state="this.validation_code">
                Длина кода от 2 до 17 символов.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <label label-for="guest_form_guest_name">Ваши ФИО:</label>
              <b-form-input placeholder="Введите ваши ФИО" debounce="300" v-model="guest_form.guest_name"
                            :state="this.validation_guest_name"
                            id="guest_form_guest_name"
                            required></b-form-input>
              <b-form-invalid-feedback :state="this.validation_guest_name">
                Длина ФИО от 2 до 17 символов.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group>
              <label label-for="guest_form_guest_email">Email:</label>
              <b-form-input placeholder="Введите ваш Email" debounce="300" v-model="guest_form.guest_email"
                            type="email"
                            :state="this.validation_guest_email"
                            id="guest_form_guest_email"
                            required></b-form-input>
              <b-form-invalid-feedback :state="this.validation_guest_email">
                Введите существующий Email.
              </b-form-invalid-feedback>
            </b-form-group>
            <p>Или
              <b-link @click="$parent.login()">
                войти с постоянным аккаунтом
              </b-link>
            </p>
            <p>
              <b v-if="guest_form.error_text.length>0">{{ this.guest_form.error_text }}</b>
            </p>
          </b-form>

        </b-modal>
        <div id="main-form" class="my-container"
             style="padding:1rem; text-align: center; vertical-align: middle; width: 100%; display: table; height: 100%; padding-top: 5rem">
          <div style="display: table-cell; vertical-align: middle; font-size: 18px;">
        <span style="">
        <p style="font-size: 1.8rem; color: #8a8a8a; text-shadow: 0 0 0 rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22);">Система видео-конференций МИЭМ НИУ ВШЭ</p>
      </span>
            <div class="row justify-content-md-center">
              <div class="col-12 col-sm-12 col-md-8 col-xl-5 col-lg-5" style="padding: 10px;">
                <div class="row p-0 m-0">
                  <b-col class="p-0">
                    <b-form-input v-model="$parent.room_text" size="lg"
                                  style="width: 100%; text-align: center; box-shadow: 0 0 0 rgba(0,0,0,0.25), 0 0 10px rgba(0,0,0,0.22);"
                                  placeholder="Введите имя комнаты" @keydown.native="enter_keydown_handler">
                    </b-form-input>
                  </b-col>

                </div>
                <div class="row p-0 m-0">
                  <b-form-invalid-feedback :state="this.validation_main_room_name()">
                    Длина имени от 2 до 60 символов. Допустимы только символы латинского алфавита [a-z,A-Z], цифры [0-9]
                    и
                    символы
                    ["_","-"].
                  </b-form-invalid-feedback>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-8 col-xl-3 col-lg-3" style="padding: 10px;"
                   v-if="!$parent.isMobile()">
                <b-button-group size="lg" style="width: 100%" v-if="$parent.is_auth()">
                  <b-button variant="primary" style="width: 100%" @click="open_room" v-if="$parent.is_auth()"
                            :disabled="!is_fill_room_name() || !validation_main_room_name()">
                    Подключиться
                  </b-button>
                  <b-button v-b-modal.modal-invite variant="primary" @click="$parent.showModalInvite()">
                    <b-icon icon="person-plus" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
                <b-button variant="primary" size="lg" style="width: 100%" @click="$parent.login()"
                          v-if="!$parent.is_auth() && is_fill_room_name()"
                          :disabled="!is_fill_room_name() || !validation_main_room_name()">
                  Авторизоваться и подключиться
                </b-button>
                <b-button variant="primary" size="lg" style="width: 100%" @click="$parent.login()"
                          v-if="!$parent.is_auth() && !is_fill_room_name()">
                  Войти
                </b-button>

              </div>
            </div>
            <div class="row justify-content-md-center" v-if="!$parent.is_auth() && !$parent.isMobile()">
              <div class="col-12 col-sm-12 col-md-8 col-xl-8" style="padding: 10px;">
                <b-button variant="primary" size="lg" style="width: 100%" @click="this.showModalGuest"
                          :disabled="!is_fill_room_name() || !validation_main_room_name()">
                  Войти как Гость
                </b-button>
              </div>
            </div>
            <div class="row justify-content-md-center" v-if="$parent.isMobile()">
              <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="$parent.is_auth()">
                <b-button block variant="primary" style="width: 100%" @click="open_room_in_app"
                          :disabled="!is_fill_room_name() || !validation_main_room_name()">
                  Подключиться в приложении
                </b-button>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="$parent.is_auth()">
                <b-button block variant="primary" style="width: 100%; height: 100%" @click="open_room"
                          :disabled="!is_fill_room_name() || !validation_main_room_name()">
                  Подключиться в браузере
                </b-button>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="!$parent.is_auth()">
                <b-button block variant="primary" style="width: 100%; height: 100%" @click="this.showModalGuest"
                          :disabled="!is_fill_room_name() || !validation_main_room_name()"
                >
                  Войти как Гость
                </b-button>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-xl-4" style="padding: 10px;" v-if="!$parent.is_auth()">
                <b-button block variant="primary" style="width: 100%; height: 100%" @click="$parent.login()"
                >
                  Авторизоваться
                </b-button>
              </div>
            </div>
            <!-- Контейнер для быстрой истории        -->
            <div v-if="$parent.is_auth()" class="row justify-content-md-center" style=" ">

              <div class="col-12 col-sm-12 col-md-8 col-xl-8 mb-0 "
                   style="padding: 10px; height:19rem; max-height: 100%; ">

                <div id="history-container" v-if="is_history_loaded"
                     style="overflow-y:auto; padding: 1rem; background-color: white; border-radius: 0.25rem; max-height: 100%">
                  <b-list-group
                      style=" max-height: 100%;  width:100%; background: #ffffff">
                    <div class="v-border" v-for="(item, index) in this.history" :key="index+'2'">
                      <b-list-group-item size="sm" class="my-list-group-item" v-if="is_history_loaded">

                        <b-row align-v="center">
                          <b-col>
                            <b-row align-v="center">
                              <b-col @click="set_room(item['room_name'])">
                            <span class="mr-1" style="text-align: left; ">{{
                                dateFormat(new Date(Math.trunc(item["create_ts"] * 1000)), "%d.%m.%Y %H:%M:%S", false)
                              }}</span>
                              </b-col>
                              <b-col @click="set_room(item['room_name'])">
                            <span class="mr-2"
                                  style="text-align: left; word-wrap: break-word; max-width: 100px"><b>{{
                                item["description"] ? item["description"] : item["room_name"]
                              }}</b> </span>
                              </b-col>
                              <b-col @click="set_room(item['room_name'])">
                            <span class="mr-2" style="text-align: left;">[{{
                                get_duration_string(item["create_ts"], item["end_ts"])
                              }}]</span>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-list-group-item>
                      <p v-else>{{ this.data_message }}</p>

                    </div>
                  </b-list-group>
                </div>
                <p v-else>{{ this.data_message }}</p>
              </div>
            </div>
            <div v-if="$parent.is_auth()" class="row justify-content-center" style=" ">

              <div class="col-12 col-sm-12 col-md-8 col-xl-8" style="text-align: end">
                <b-link :to="{name:'history'}" style="font-size: 1rem">
                  полная история
                </b-link>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import ConfModerationPanel from "@/components/microcomponents/ConfModerationMenu";
import ConfModerationSidebar from "@/components/microcomponents/ConfModerationSidePanel";
import WaitingRoom from "@/components/WaitingRoom";
import VotingModal from "@/components/VotingModal";
import SocketioService from '../services/socketio.service.js';

var container = document.querySelector('#jitsi-container');
var jitsi_text = document.querySelector('#jitsi-text');
var main_text = document.querySelector('#main-form');
var api = null;

//var is_auth_flag_glob = false
export default {
  watch: {},
  name: 'ConfPage',
  components: {
    WaitingRoom,
    VotingModal,
    ConfModerationPanel,
    ConfModerationSidebar
  },
  data() {
    return {
      guest_form: {
        code: "",
        room_name: "",
        guest_name: "",
        guest_email: "",
        guest_sub: "",
        error_text: ""
      },
      data_message: "",
      ts: null,
      history: [],
      messages: [],
      flag_loading: true,
      info_is_loaded: false,
      is_history_loaded: false,
      user_history: [],

      conf_users: [],
      isModerator: false,
      isMicAllowed: true,
      isCameraAllowed: true,
      isChatAllowed: true,
      isScreenShareAllowed: true,
      breakoutRoomsCount: 0,

      //waiting_room_status: false,
      //voting_status: false,
      //can_vote: false,
      //is_moderator: false,
      guest_status: false,
      socket_connection_status: false,
      //waiting_users_count: 0,
      waiting_room_user: {},
      content_id: null,
      room_title: '',
    }
  },
  mounted() {
    //console.log(location.pathname.substring(1));
    this.$parent.room_text = location.pathname.substring(1);
    this.$parent.api_check_auth(this.open_room_event, this.open_guest_modal_event);
    //this.myUndefinedFunction();
    //Sentry.showReportDialog({ eventId: event.event_id });

    if (!this.ts) {
      this.ts = Date.now() / 1000;
    }

    let c_window = this;
    this.api_get_rooms_history("", c_window.ts, 0);

    //Sentry.captureMessage("Something went wrong");

  },
  computed: {
    validation_guest_room_name() {
      let format = /[^a-zA-Z0-9\-_]/;
      if (this.guest_form.room_name.length < 2 || this.guest_form.room_name.length > 60) return false;

      return !format.test(this.guest_form.room_name);
    },
    validation_code() {
      let format = /[^a-zA-Z0-9\-_]/;
      if (this.guest_form.code.length < 2 || this.guest_form.code.length > 17) return false;

      return !format.test(this.guest_form.code);
    },
    validation_guest_name() {
      return !(this.guest_form.guest_name.length < 2 || this.guest_form.guest_name.length > 100);

    },
    validation_guest_email() {
      let format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return format.test(this.guest_form.guest_email);
    },
    iframe_container_width() {
      if (this.$parent.isMobile()) {
        return (this.$root.global_object.is_conf_now && this.$root.global_object.is_moderation_mode) ? 'col-1' : 'col-12';
      }
      return (this.$root.global_object.is_conf_now && this.$root.global_object.is_moderation_mode) ? 'col-10' : 'col-12';
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$root.global_object.is_conf_now === true) {
      this.$bvModal.msgBoxConfirm('Конференция еще продолжается. Вы желаете выйти?', {
        title: 'Подтвердите действие',
        size: 'sm',
        buttonSize: 'md',
        okVariant: 'danger',
        okTitle: 'Да',
        cancelTitle: 'Нет',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value == true) {
              next(true);
            } else {
              next(false);
            }
          })
          .catch(err => {
            console.log(err)
            // An error occurred
          })
    } else next(true);
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
  },
  methods: {
    getRoomUsers() {
      this.$root.global_object.API.getRoomsInfo()
          .then(item => {
            this.conf_users = item.rooms.find(room => room.isMainRoom).participants;
          })
    },
    sendCommandToAllParticipants(command) {
      const commandJson = JSON.stringify(command);
      this.$root.global_object.API.getRoomsInfo()
          .then(item => {
            this.conf_users = item.rooms.find(room => room.isMainRoom).participants;
            for (const user of this.conf_users) {
              try {
                this.$root.global_object.API.executeCommand('sendEndpointTextMessage', user.id, commandJson);
              } catch (exeption) {
                alert("Jitsi API expt " + exeption.messages)
                console.log(exeption.messages);
              }

            }
          })


    },
    sendCommandToParticipant(command, user_id) {
      const commandJson = JSON.stringify(command);
      this.$root.global_object.API.executeCommand('sendEndpointTextMessage', user_id, commandJson);
    },
    set_room(name) {
      this.$parent.room_text = name;
      this.open_room_event();
    },
    delete_waiting_user_count(all = 'false') {
      if (all === 'true')
        this.$root.global_object.conf_states.waiting_users_count = 0
      else
        this.$root.global_object.conf_states.waiting_users_count = 0
    },
    removeElement: function (index) {
      this.history.splice(index, 1);
    },
    get_current_time_str() {
      let day = new Date();
      const formatter = new Intl.DateTimeFormat('ru-RU', { hour: '2-digit', minute: '2-digit' });
      const formattedTime = formatter.format(day);
      let str = day.toLocaleDateString() + " " + formattedTime
      return str;
    },
    get_start_time_str() {
      if (!this.$root.global_object.conf_states.is_planned)
        return "";
      let day = new Date(this.$root.global_object.conf_states.start_ts*1000);
      const formatter = new Intl.DateTimeFormat('ru-RU', { hour: '2-digit', minute: '2-digit' });
      const formattedTime = formatter.format(day);
      let str = day.toLocaleDateString() + " " + formattedTime
      return str;
    },
    get_moderator_state(){
      return this.$root.global_object.conf_states.is_moderator;
    },
    get_style_str() {
      let str = "margin: -1rem; min-height: 20rem; padding: 1rem; \
            text-align: center; \
            overflow:hidden; \
            background-size:cover; \
            background: url(" + this.$root.global_object.conf_states.screensaver_path +") no-repeat center center fixed";
      return str;
    },
    get_waiting_time_str() {
      let full_str = "";

      let day_current = new Date();
      let day_start_ts = new Date(this.$root.global_object.conf_states.start_ts*1000);
      if (day_current.getTime() > day_start_ts.getTime())
        return "0мин."
      let diff = new Date(day_start_ts.getTime() - day_current.getTime());
      let month = diff.getUTCMonth();
      let day = diff.getUTCDate() - 1;
      let hour = diff.getUTCHours();
      let min = diff.getUTCMinutes();

      if (month>0)
        full_str += month+"м. ";
      if (day>0)
        full_str += day+"д. ";
      if (hour>0)
        full_str += hour+"ч. ";
      full_str += min+"мин. ";

      return full_str;
    },
    get_duration_string(start_ts, end_ts) {
      if (end_ts === "" || end_ts == null) {
        // alert("NULL");
        return "online";
      }
      let start_date = new Date(Math.trunc(start_ts * 1000));
      let end_date = new Date(Math.trunc(end_ts * 1000));
      let dur_sec = Math.trunc((end_date - start_date) / 1000);
      let seconds = dur_sec % 60; // Получаем секунды
      let minutes = dur_sec / 60 % 60; // Получаем минуты
      let hour = dur_sec / 60 / 60 % 60; // Получаем часы
      // Создаём строку с выводом времени
      let str_duration = `${Math.trunc(hour)}:${Math.trunc(minutes).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}:${Math.trunc(seconds).toLocaleString('ru-RU', {minimumIntegerDigits: 2})}`;
      return str_duration;

    },
    validation_main_room_name() {
      return true;
      // let format = /[^a-zA-Z0-9\-_]/;
      // this.$parent.room_text = this.$parent.room_text.replace(' ','_');
      // //alert(this.$parent.room_text);
      // if (this.$parent.room_text.length > 60) return false;
      //
      // if (format.test(this.$parent.room_text)) {
      //   return false;
      // } else
      //   return true;
    },
    enter_keydown_handler(event) {
      // this.$parent.room_text = this.$parent.room_text.replace(' ','_');
      if (event.which === 13) {
        if (this.validation_main_room_name())
          this.open_room();
      }
    },
    get_end_ts_string(ts) {
      // alert(ts.value)
      if (ts.value === "" || ts.value == null) {
        // alert("NULL");
        return ""
      }

      return this.dateFormat(new Date(Math.trunc(ts.value * 1000)), "%d.%m.%Y %H:%M:%S", false)
    },
    dateFormat: function (date, fstr, utc) {
      if (date === null)
        return ""
      utc = utc ? 'getUTC' : 'get';
      return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
          case '%Y':
            return date[utc + 'FullYear'](); // no leading zeros required
          case '%m':
            m = 1 + date[utc + 'Month']();
            break;
          case '%d':
            m = date[utc + 'Date']();
            break;
          case '%H':
            m = date[utc + 'Hours']();
            break;
          case '%M':
            m = date[utc + 'Minutes']();
            break;
          case '%S':
            m = date[utc + 'Seconds']();
            break;
          default:
            return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
      });
    },
    api_get_rooms_history(room_name, ts, page, limit = 10) {
      this.flag_loading = true;
      this.info_is_loaded = false;
      this.axios

          .get('/api/v1/get_rooms_history?room_name=' + room_name + '&ts=' + ts + '&page=' + page + '&limit=' + limit, {
            withCredentials: true
          })
          .then(response => {
            if (response.data) {
              this.info_is_loaded = true;
              this.flag_loading = false;
              this.history = response.data.history;
              //alert(response.data.all_events_count);
            } else {
              this.info_is_loaded = false;
              this.data_message = "Данные отсутствуют";
              this.flag_loading = false;
            }
          })
          .catch((error) => {
            this.info_is_loaded = false;
            this.data_message = "Данные отсутствуют";
            this.flag_loading = false;
            if (error.response) {
              // console.log(error.response.data);
              // console.log(error.response.status);
              // console.log(error.response.headers);
            }
          });
      //this.flag_loading = false;
      this.is_history_loaded = true;
    },
    showModalGuest() {
      this.guest_form.room_name = this.$parent.room_text;
      this.guest_form.error_text = "";
      if (this.$route.query.code)
        this.guest_form.code = this.$route.query.code;
      let guest_name = localStorage.getItem("guest_name");
      let guest_email = localStorage.getItem("guest_email");
      let guest_sub = localStorage.getItem("guest_sub");
      if (guest_name)
        this.guest_form.guest_name = guest_name;
      if (guest_email)
        this.guest_form.guest_email = guest_email;
      if (guest_sub)
        this.guest_form.guest_sub = guest_sub

      this.$bvModal.show("modal-guest-login");
    },
    is_fill_room_name() {
      return this.$parent.room_text.length > 0;
    },
    start_timer(call) {
      this.$parent.timer = setInterval(function () {
        call();

      }, 1000)
    },
    start_local_video_record(call, room_name) {
      this.$parent.status_timer = setInterval(function () {
        call(room_name);
      }, 60000)
    },

    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    },

    iframeNow() {
      jitsi_text.style.display = "none";
      main_text.style.display = "none";
      container.style.display = "block";
      this.$root.global_object.conf_states.currentConnectTimeStamp = Date.now();
      // this.api.getLivestreamUrl().then(livestreamData => {
      //   alert(JSON.stringify(livestreamData));
      // });
      this.start_timer(this.$parent.calc_timer);
      this.start_local_video_record(this.$parent.api_get_room_status, this.$parent.room_text);
      this.$parent.is_conf_now = true;
      document.title = "Комната: " + this.$parent.room_text;
      this.$root.global_object.is_conf_now = true;
      this.$root.global_object.left_room = this.left_room;
      api.isAudioMuted().then(muted => {
        this.$parent.record_mic_muted(muted);
      });

      this.$root.global_object.API.addEventListeners({
        participantJoined: this.participant_joined
      });
      this.$root.global_object.API.addEventListeners({
        participantLeft: this.participant_left
      });
      this.getRoomUsers();
      this.$root.global_object.conf_states.methods.sendCommandToAllParticipants = this.sendCommandToAllParticipants;
      this.$root.global_object.conf_states.methods.sendCommandToParticipant = this.sendCommandToParticipant;

      this.api_get_description(this.$parent.room_text);
      if (this.$root.global_object.conf_states.is_moderator
          //&& (this.$root.global_object.conf_states.waiting_room_status || this.$root.global_object.conf_states.voting_status)
      )
        this.get_socket_token();
    },
    api_get_description(room_name) {
      // if (this.is_auth()) {
      this.axios
          .get('/api/v1/get_room_status_no_cache?room_name=' + room_name,
              {withCredentials: true})
          .then(response => {
            this.$parent.room_description = response.data.description;
            this.room_title = this.$parent.room_description != null ? this.$parent.room_description : this.$parent.room_text;
            api.executeCommand('localSubject', this.room_title);
          })
          .catch((error) => {
            console.log(error);
          })
      // }
    },
    conference_error_listener(event) {
      console.log(JSON.stringify(event))
      this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить подключение позже.");
    },
    mute_event_listener(event) {
      //alert(event.muted);
      this.$parent.record_mic_muted(event.muted);
    },
    role_changed_listener(event) {
      this.$parent.conf_states.current_role = event.role
    },
    recording_status_change_listener(event) {
      this.$parent.conf_states.is_cloud_record_now = event.on

    },
    // new_participant_joined_listener(event) {
    //   this.$root.global_object.conf_states.timerButtonNewUserListener(event.id)
    // },
    video_Conference_Joined_listener(event) {
      // alert("I joined " + event.id)
      this.$root.global_object.conf_states.occupant_id = event.id;
      //this.$parent.occupant_id = event.id;
      this.api_get_user_permissions(event.id, event.roomName);
    },
    api_get_user_permissions(id, room_name) {
      this.axios
          .post('/api/v2/moderating/get_permissions', {
            occupant_id: id,
            room_name: room_name
          }, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.$root.global_object.methods.getCookie('csrf_access_token'),
            }
          })
          .then((response) => {
            const {moderator, mic, camera, chat, screen_share} = response.data;

            this.isModerator = moderator;
            this.isMicAllowed = mic;
            this.isCameraAllowed = camera;
            this.isChatAllowed = chat;
            this.isScreenShareAllowed = screen_share;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    endpoint_text_message_received(event) {
      const command = JSON.parse(event.data.eventData.text);
      //Блок для таймера
      if (command.isCommand) {
        if (command.tool == "TIMER_EDIT" || command.tool == "TIMER_START" || command.tool == "TIMER_STOP")
          this.$root.global_object.conf_states.timerButtonListener(command);
        if (command.tool == "MANAGE_BUTTON") {

          if (this.$root.global_object.conf_states.is_moderator)
            this.$root.global_object.conf_states.manageButtonListener(command);
          else
            this.$root.global_object.conf_states.sidePanelListener(command);
        }
      }

      if (command.isCommand && this.isModerator) {
        switch (command.tool) {
          case 'mic':
            this.$root.global_object.API.isAudioMuted()
                .then(muted => {
                  if (command.isAllow) {
                    this.isMicAllowed = true;
                    if (!muted) {
                      this.$root.global_object.API.executeCommand('toggleAudio');
                    }
                  } else {
                    this.isMicAllowed = false;
                  }
                });
            break;
          case 'camera':
            this.$root.global_object.API.isVideoMuted()
                .then(muted => {
                  if (command.isAllow) {
                    this.isCameraAllowed = true;
                    if (!muted) {
                      this.$root.global_object.API.executeCommand('toggleVideo');
                    }
                  } else {
                    this.isCameraAllowed = false;
                  }
                });
            break;
          case 'chat':
            if (command.isAllow) {
              this.isChatAllowed = true;
            } else {
              this.isChatAllowed = false;
            }
            break;
          case 'screen_share':
            if (command.isAllow) {
              this.isScreenShareAllowed = true;
            } else {
              this.isScreenShareAllowed = false;
            }
            break;
          default:
            alert('Command Error');
        }
      }
    },
    participant_joined(event) {
      this.getRoomUsers();
      this.$root.global_object.conf_states.timerButtonNewUserListener(event.id);
      this.$root.global_object.conf_states.manageButtonNewUserListener(event.id);
    },
    participant_left() {
      this.getRoomUsers();
    },
    left_room() {
      // console.log(event.roomName);
      // alert("Left room "+ event.roomName);
      //
      //     return;
      container.style.display = "none";
      jitsi_text.style.display = "none";
      main_text.style.display = "table";
      //this.add_to_history(this.$parent.room_text, this.$parent.timer_str);
      this.api_get_rooms_history("", Date.now() / 1000, 0, 10);
      this.$parent.timer_sec = 0;
      if (api)
        api.dispose();
      history.pushState({param: 'Value'}, '', '/');
      clearInterval(this.$parent.timer);
      clearInterval(this.$parent.status_timer);
      this.$parent.nonempty_local_records = false;
      this.$parent.is_conf_now = false;
      this.$root.global_object.is_conf_now = false;
      this.$root.global_object.conf_states.methods.resetState();
      this.$root.global_object.is_moderation_mode = false;
      this.$parent.record_stop();
      this.$root.global_object.left_room = null;
      this.$parent.set_default_title();
      this.breakoutRoomsCount = 0;
      SocketioService.socket.removeAllListeners();
      SocketioService.socket.emit('delete_user_from_waiting_room', {'room_name': this.$parent.room_text});
      SocketioService.socket.disconnect();
      this.$root.global_object.socket_connection_status = false;
      document.cookie = `socket_io_token_${this.content_id}=;expires=` + new Date(0).toUTCString();
    },
    forse_get_config(){
      this.$bvModal.hide('modal-screen-saver');
      this.api_get_room_config(true);
    },
    api_get_room_config(forse = false) {
      const room_name = this.$parent.room_text;
      const email = this.guest_status ? this.guest_form.guest_email : this.$parent.user_email;
      this.axios
          .get('/api/v2/get_room_config?room_name=' + room_name + '&email=' + email, {
            withCredentials: true,
            headers: {
              headers: {
                'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
              }
            }
          })
          .then(response => {
            {
              this.$root.global_object.conf_states.waiting_room_status = response.data.waiting_room;
              this.$root.global_object.conf_states.voting_status = response.data.voting;
              this.$root.global_object.conf_states.can_vote = response.data.can_vote;
              this.$root.global_object.conf_states.is_moderator = response.data.moderator;
              this.$root.global_object.conf_states.is_planned = response.data.planned;
              this.$root.global_object.conf_states.description = response.data.description;
              this.$root.global_object.conf_states.room_info = response.data.room_info;
              this.$root.global_object.conf_states.start_ts = response.data.start_ts;
              this.$root.global_object.conf_states.screensaver_path = response.data.screensaver_path;

              this.$parent.room_description = response.data.description;
              if (!response.data.planned)
              {
                alert("Not plan");
                this.get_usual_token();
                return;
              }
              if (!forse && ((response.data.planned )  && (!response.data.room_online) ))
                this.$bvModal.show('modal-screen-saver');
              else {
                if (!this.$root.global_object.conf_states.is_moderator && (this.$root.global_object.conf_states.can_vote || this.$root.global_object.conf_states.waiting_room_status)) {
                  this.get_socket_token();
                } else this.get_usual_token();
              }
            }
          })
          .catch((error) => {
              if (error.response) {
                //alert(error.response.status);
                if (error.response.status == 400) {
                  if (error.response.data.error_code == 105)
                    this.$parent.showAlertMsgBox("Код пропуска недействительный. Проверьте правильность введенных данных.");
                  if (error.response.data.error_code == 103)
                    this.$parent.showAlertMsgBox("Организатор еще не начал встречу. Попробуйте подключиться позднее.");
                  if (error.response.data.error_code == 102)
                    this.$parent.showAlertMsgBox("Вы не приглашены на эту встречу.");
                } else
                  this.$parent.showAlertMsgBox("Нет подключения. Проверьте правильность введенных данных.");
              }
              console.log(error)
          });
    },
    get_usual_token() {
      if (!this.guest_status)
        this.api_get_token(this.$parent.room_text);
      else this.api_get_token_for_guest(this.guest_form.room_name, this.guest_form.code, this.guest_form.guest_name, this.guest_form.guest_email, this.guest_form.guest_sub);
    },
    api_get_moderator_status(room_name, user_email) {
      this.axios
          .get('/api/v2/waiting_rooms/is_moderator?room_name=' + room_name + '&email=' + user_email, {
            withCredentials: true,
          })
          .then(response => {
            //this.is_moderator = response.data.is_moderator;
            this.$root.global_object.conf_states.is_moderator = response.data.is_moderator;
            console.log('СТАТУС МОДЕРАТОРА ', this.$root.global_object.conf_states.is_moderator)
            if (this.$root.global_object.conf_states.is_moderator) this.get_usual_token();
            else this.get_socket_token();
          })
          .catch((error) => {
            console.log(error)
          })
    },
    get_socket_token() {
      if (!this.guest_status) this.get_socketio_token_for_auth(this.$parent.room_text);
      else this.get_socketio_token_for_guest(this.guest_form.room_name, this.guest_form.code, this.guest_form.guest_name, this.guest_form.guest_email, this.guest_form.guest_sub)
    },
    open_waiting_room() {
      if (this.$root.global_object.socket_connection_status) {
        this.$root.global_object.conf_states.waiting_users_count = 0;
        this.$bvModal.show('modal-waiting-room');
      } else alert('Невозможно открыть комнату ожидания, сокет-соединение не установлено.')
    },
    open_voting_modal() {
      this.$bvModal.show('modal-voting');
    },
    open_room() {
      if (this.$parent.room_text === "" || !this.validation_main_room_name())
        return;
      this.guest_status = false;
      this.api_get_room_config();
    },
    open_room_as_guest() {
      localStorage.setItem("guest_name", this.guest_form.guest_name);
      localStorage.setItem("guest_email", this.guest_form.guest_email);
      this.guest_status = true;
      this.api_get_room_config();
    },
    open_room_event() {
      //alert("openroomevent");
      if (this.$parent.room_text.length > 0) {

        if (this.$parent.is_auth() && !this.$parent.isMobile()) {
          this.open_room(this.$parent.room_text)
        }
        if (!this.$parent.is_auth()) {
          let flag_guest = true
          if (this.$route.query.guest === undefined)
            flag_guest = false
          if (flag_guest) this.showModalGuest()
        }
      }
    },
    open_guest_modal_event() {
      if (!this.$parent.is_auth()) {
        let flag_guest = true
        if (this.$route.query.guest === undefined)
          flag_guest = false
        if (flag_guest) this.showModalGuest()
      }
    },
    disconnectSocket() {
      //alert("Disconnet SC");
      if (!this.$root.global_object.conf_states.is_moderator) {
        SocketioService.socket.emit('delete_user_from_waiting_room', {'room_name': this.$parent.room_text});
        SocketioService.socket.disconnect();
      }
    },
    setUpSocketIoConnection(room_name) {
      SocketioService.setupSocketConnection(room_name);
      if (this.$root.global_object.conf_states.waiting_room_status) {
        SocketioService.socket.on('connection', (data) => {
          if (!SocketioService.socket.recovered)
            if (data) {
              this.$root.global_object.socket_connection_status = true;
              const email = this.guest_status ? this.guest_form.guest_email : this.$parent.user_email;
              const info = {
                'room_name': room_name, 'email': email
              }
              if (!this.$root.global_object.conf_states.is_moderator) {
                SocketioService.socket.emit('join_waiting_room', info);
              }
              SocketioService.socket.on('waiting_room_status_response', (response) => {
                if (response.success) this.$root.global_object.conf_states.waiting_users_count = response.participants_count;
                console.log('waiting_room_status_response', response)
                console.log('SSSS')
              });
              SocketioService.socket.on('join_waiting_room_response', (response) => {
                if (response.success) this.open_waiting_room();
                else alert(`Не удалось добавить пользователя в комнату ожидания, success: ${response.success}, ${response.message}`)
              });
              SocketioService.socket.emit('waiting_room_status', {'room_name': this.$parent.room_text});
              if (this.$root.global_object.conf_states.is_moderator) {
                SocketioService.socket.on('join_waiting_room_info', (response) => {
                  if (response.success && this.$root.global_object.conf_states.is_moderator) {
                    this.$root.global_object.conf_states.waiting_users_count += 1;
                    const text = `${response.joined_user} в зале ожидания`;
                    this.waiting_room_user = {'user_name': response.joined_user, 'email': response.email};
                    console.log('добавленный пользователь', this.waiting_room_user);
                    this.toastForModerator(text);

                    let index = this.$refs.waitingRoomModal.waitingList.findIndex(item => item['email'] === response.email);
                    if (index === -1) this.$refs.waitingRoomModal.waitingList.push({
                      'user_name': response.joined_user,
                      'email': response.email
                    });
                    console.log('waitingList:', this.$refs.waitingRoomModal.waitingList);
                    console.log('AAAA')
                  }
                });
              }
              SocketioService.socket.on('delete_user_info', (response) => {
                if (response.success) {
                  this.delete_waiting_user_count()
                  let index = this.$refs.waitingRoomModal.waitingList.findIndex(item => item['email'] === response.email);
                  console.log(this.$refs.waitingRoomModal.waitingList, response.email)
                  console.log(index)
                  if (index !== -1) this.$refs.waitingRoomModal.waitingList.splice(index, 1);
                }
              });
            } else {
              alert('Socket.io соединение не установлено')
            }
        });
      } else {
        SocketioService.socket.on('connection', (data) => {
          if (data) {
            //this.socket_connection_status = true;
            this.$root.global_object.socket_connection_status = true;
            if (!this.$root.global_object.conf_states.is_moderator) this.get_usual_token()
          } else alert('Беда')
        })
      }
      SocketioService.socket.connect();
    },
    toastForModerator(text) {
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 5000,
      })
    },
    get_socketio_token_for_auth(room_name) {
      let data = {load_avatar: true, room_name: room_name};
      if (this.$parent.user_avatar_checked == true)
        data.load_avatar = this.$parent.user_avatar_checked;
      this.axios
          .post('/api/v2/get_socketio_token?' + this.$parent.getRandomInt(3000000).toString(), data, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
            let date = new Date(Date.now() + 86400e3)
            console.log('ДАТА В2', date)
            document.cookie = `socket_io_token_${response.data.content_id}=${response.data.JWT};expires=${date}`
            this.content_id = response.data.content_id;
            this.$root.global_object.conf_states.content_id = response.data.content_id;
            this.setUpSocketIoConnection(room_name)

          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 401)
                this.$parent.showAlertMsgBox();
              else
                this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
    },
    get_socketio_token_for_guest(room_name, pass_code, guest_name, guest_email, guest_sub) {
      this.axios
          .post('/api/v2/get_socketio_token_for_guest?' + this.$parent.getRandomInt(3000000).toString(), {
                room_name: room_name,
                code: pass_code,
                guest_name: guest_name,
                guest_email: guest_email,
                guest_sub: guest_sub,
                load_avatar: 0
              },
              {
                withCredentials: true,
                headers: {
                  'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
                }
              })
          .then(response => {
            let date = new Date(Date.now() + 86400e3)
            console.log('ДАТА В2', date)
            document.cookie = `socket_io_token_${response.data.content_id}=${response.data.JWT};expires=${date}`
            this.content_id = response.data.content_id;
            this.$root.global_object.conf_states.content_id = response.data.content_id;
            this.setUpSocketIoConnection(room_name)

          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 401)
                this.$parent.showAlertMsgBox();
              else
                this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
    },
    /**
     * Запрашивает токен для авторизованных пользователей для входа на конференцию.
     * В случае успеха переходим сразу в комнату.
     * @param room_name
     * @param in_mobile если True, то переходим в мобильное приложение.
     */
    api_get_token(room_name, in_mobile = false) {
      let data = {room_name: room_name};
      if (this.$parent.user_avatar_checked == true)
        data.load_avatar = this.$parent.user_avatar_checked;
      this.axios
          .post('/api/v1/get_token?' + this.$parent.getRandomInt(3000000).toString(), data, {
            withCredentials: true,
            headers: {
              'X-CSRF-TOKEN': this.getCookie('csrf_access_token'),
            }
          })
          .then(response => {
            this.$parent.room_text = response.data.room_name;
            this.$parent.room_full_link = window.location.origin + "/" + this.$parent.room_text
            if (in_mobile) {
              let link = "org.jitsi.meet://" + response.data.domain + "/" + response.data.room_name + "?jwt=" + response.data.JWT;
              this.$parent.go_link(link);
              return;
            }
            //this.user_name = response.data.name;
            container = document.querySelector('#jitsi-container');
            jitsi_text = document.querySelector('#jitsi-text');
            main_text = document.querySelector('#main-form');
            container.innerHTML = "";
            main_text.style.display = "none";
            jitsi_text.style.display = "table";
            container.style.display = "none";
            history.pushState({param: 'Value'}, this.$parent.room_text, this.$parent.room_text);
            let options = {
              "roomName": response.data.room_name,
              "parentNode": container,
              "jwt": response.data.JWT,
              // "width": 600,
              // "height": 400,
              "onload": this.iframeNow,
              configOverwrite: {
                disableDeepLinking: true,
                startWithAudioMuted: true,
                startWithVideoMuted: true,

              },
              interfaceConfigOverwrite: {}
            };
            if (this.$root.global_object.conf_states.is_planned)
              options.configOverwrite.toolbarButtons = [
                //'camera',
                'closedcaptions',
                //'desktop',
                'download',
                'filmstrip',
                'fullscreen',
                'hangup',
                'livestreaming',
                'microphone',
                'mute-everyone',
                'mute-video-everyone',
                'participants-pane',
                'profile',
                'raisehand',
                'recording',
                'select-background',
                'settings',
                'shortcuts',
                'stats',
                'tileview',
                //'toggle-camera',
                'videoquality'//,
                //    '__end'
              ]
            // eslint-disable-next-line no-undef
            api = new JitsiMeetExternalAPI(response.data.domain, options);
            this.$parent.conf_states.api = api;
            api.addEventListeners({
              videoConferenceLeft: this.left_room
            });
            api.addEventListeners({
              audioMuteStatusChanged: this.mute_event_listener
            });
            api.addEventListeners({
              errorOccurred: this.conference_error_listener
            });
            api.addEventListeners({
              participantRoleChanged: this.role_changed_listener
            });
            api.addEventListeners({
              recordingStatusChanged: this.recording_status_change_listener
            });
            api.addEventListeners({
              videoConferenceJoined: this.video_Conference_Joined_listener
            });
            api.addEventListeners({
              endpointTextMessageReceived: this.endpoint_text_message_received
            });
            // api.addEventListeners({
            //   participantJoined: this.new_participant_joined_listener
            // });
            this.$root.global_object.API = api;
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 401)
                this.$parent.showAlertMsgBox();
              else
                this.$parent.showAlertMsgBox("Нет соединения с сервером. Попробуйте повторить запрос позже.");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
    },
    /**
     * Запрашивает токен для гостевых пользователей для входа на конференцию.
     * В случае успеха переходим сразу в комнату.
     * @param room_name
     * @param in_mobile если True, то переходим в мобильное приложение.
     */
    api_get_token_for_guest(room_name, code, guest_name, guest_email, guest_sub, in_mobile = false) {
      this.axios
          .post('/api/v1/get_token_for_guest?' + this.$parent.getRandomInt(3000000).toString(),
              {
                room_name: room_name,
                code: code,
                guest_name: guest_name,
                guest_email: guest_email,
                guest_sub: guest_sub
              }, {
                withCredentials: false,
                headers: {}
              })
          .then(response => {
            this.$parent.room_text = response.data.room_name;
            localStorage.setItem("guest_sub", response.data.guest_sub);
            this.guest_form.guest_sub = response.data.guest_sub;
            if (response.data.result == false && response.data.error_code == 103) {
              //alert("Wait moderators");
              this.guest_form.error_text = "Организатор еще не начал встречу. Попробуйте подключиться позднее.";
              return;
            }
            this.$parent.room_full_link = window.location.origin + "/" + this.$parent.room_text
            if (in_mobile) {
              let link = "org.jitsi.meet://" + response.data.domain + "/" + response.data.room_name + "?jwt=" + response.data.JWT;
              this.$parent.go_link_blank(link);
              return;
            }

            //this.user_name = response.data.name;
            container = document.querySelector('#jitsi-container');
            jitsi_text = document.querySelector('#jitsi-text');
            main_text = document.querySelector('#main-form');
            container.innerHTML = "";
            main_text.style.display = "none";
            jitsi_text.style.display = "table";
            container.style.display = "none";
            this.$bvModal.hide("modal-guest-login");
            history.pushState({param: 'Value'}, this.$parent.room_text, this.$parent.room_text);
            let options = {
              "roomName": response.data.room_name,
              "parentNode": container,
              "jwt": response.data.JWT,
              // "width": 600,
              // "height": 400,
              "onload": this.iframeNow,
              configOverwrite: {
                disableDeepLinking: true,
                startWithAudioMuted: true,
                startWithVideoMuted: true
              },
            };
            if (this.$root.global_object.conf_states.is_planned)
              options.configOverwrite.toolbarButtons = [
                //'camera',
                'closedcaptions',
                //'desktop',
                'download',
                'filmstrip',
                'fullscreen',
                'hangup',
                'livestreaming',
                'microphone',
                'mute-everyone',
                'mute-video-everyone',
                'participants-pane',
                'profile',
                'raisehand',
                'recording',
                'select-background',
                'settings',
                'shortcuts',
                'stats',
                'tileview',
                //'toggle-camera',
                'videoquality'//,
                //    '__end'
              ]

            // eslint-disable-next-line no-undef
            api = new JitsiMeetExternalAPI(response.data.domain, options);
            this.$parent.conf_states.api = api;

            api.addEventListeners({
              videoConferenceLeft: this.left_room
            });
            api.addEventListeners({
              audioMuteStatusChanged: this.mute_event_listener
            });
            api.addEventListeners({
              errorOccurred: this.conference_error_listener
            });
            api.addEventListeners({
              participantRoleChanged: this.role_changed_listener
            });
            api.addEventListeners({
              recordingStatusChanged: this.recording_status_change_listener
            });
            api.addEventListeners({
              endpointTextMessageReceived: this.endpoint_text_message_received
            });
            this.$root.global_object.API = api;

          })
          .catch((error) => {
            //alert("Error");
            if (error.response) {
              //alert(error.response.status);
              if (error.response.status == 400) {
                if (error.response.data.error_code == 105)
                  this.$parent.showAlertMsgBox("Код пропуска недействительный. Проверьте правильность введенных данных.");
                if (error.response.data.error_code == 103)
                  this.$parent.showAlertMsgBox("Организатор еще не начал встречу. Попробуйте подключиться позднее.");
              }
              else
                this.$parent.showAlertMsgBox("Нет подключения. Проверьте правильность введенных данных.");
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            }
          });
    },
    open_room_in_app() {
      if (this.$parent.room_text === "" || !this.validation_main_room_name())
        return;
      this.guest_status = false;
      this.api_get_room_config();
    }
    ,
  },
  props: {
    // global_object: Object,
  }
}
import "@/assets/css/main_style.css";
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>

.menu-items {
  text-align: left;
}

.wrapper-container {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
}

.iframe-container {
  display: flex;
  width: 97.5%;
  height: 100%;
  padding: 0;
}

.my-list-group-item {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  font-size: 0.9rem;
}

.my-list-group-item:hover {
  background-color: #eeeff4;
}

.btn-primary {
  background-color: #0050cf;
  border-color: #0050cf;
}

.styled-btn-group {
  background-color: black;
}

.btn-primary {
  color: #fff;
  background-color: #0050cf;
  border-color: #0050cf;
}

.nav-pills .nav-link.active {
  background-color: #0050cf;
}

.my-container {
  background-color: #eeeff4;
}

.active-icon:hover {
  transform: scale(1.75);
}

.button-waiting-room {
  position: absolute;
  bottom: 20rem;
  left: 27rem;
}

.button-voting-modal {
  position: absolute;
  bottom: 10rem;
  left: 27rem;
}

.button-waiting-room:focus {
  outline: none;
}

.button-avatar:focus {
  outline: none;
}

</style>
