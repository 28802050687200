<template>
 <div id="ConfPlanned" :class="getStyle()">
   <b-container :class="getRowStyle()" >
     <b-row class="pb-2 pl-0 pr-0" align-h="between" >
       <b-col cols-9 class="label-plannedConf my-auto">
         Запланированные конференции
       </b-col>
       <b-col cols-3>
         <b-button class="btn-createNewConf" v-b-modal.modal-create-conf size="sm">
           + Запланировать
         </b-button>
       </b-col>
     </b-row>
     <b-tabs >
       <b-tab title="Все">
         <b-card v-for="conference in conferences" :key="conference.room_name"
                 :title="conference.description"
                 class="mb-2 card-planned-conference pb-0" >
           <b-list-group horizontal="lg" center  class="confObject pt-0 pb-0 mb-0 border-top" >
             <b-list-group-item class="col-lg-2 border-0 pb-0 pt-2">
               <b-icon
                   class="mr-2 my-auto active-icon"
                   v-show="conference.moderator"
                   id="organizer-status"
                   scale="1.25"
                   v-b-popover.hover="'Вам доступны права модератора'"
                   icon="person"
               >
               </b-icon>
               <!-- <b-icon
                   class=" my-auto active-icon"
                   v-show="conference.public"
                   scale="1.25"
                   id="public" icon="megaphone"
                   v-b-popover.hover="'Публичная конференция'"
               >
               </b-icon> -->
             </b-list-group-item>
             <b-list-group-item class="col-lg-4 border-0 pb-0 pt-2 my-auto">
               {{start_date(conference.start_ts)}}
               {{start_time(conference.start_ts)}}
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto">
               <b-link
                   @click="selected_conference=conference;api_get_passcodes(conference.room_name)"
                   v-b-modal.modal-passcode
                   v-show="conference.passcodes && conference.moderator"
               >
                 Коды доступа
               </b-link>
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto">
               <b-link :to="{ name: 'conf', params: { id: conference.room_name }}" class="mb-0 mt-0 pt-0 pb-0">
                 <b-icon
                     id="launch-button"
                     icon="box-arrow-in-right"
                     aria-hidden="true"
                     scale="1.25"
                     class="active-icon"
                     v-b-popover.hover="'Запустить'"
                 >
                 </b-icon>
               </b-link>
               <b-icon
                   @click="selected_conference=conference"
                   v-show="conference.is_creator"
                   class="ml-3 mb-0 mt-0 pt-0 pb-0 active-icon"
                   id="delete-button"
                   v-b-popover.hover="'Удалить'"
                   scale="1.25"
                   v-b-modal.modal-delete-conf
                   icon="trash"
               >
               </b-icon>
               <b-icon
                   class="mb-0 mt-0 ml-3 pt-0 pb-0 active-icon"
                   id="editConf"
                   @click="api_get_conference_full_info(conference.room_name)"
                   v-show="conference.is_creator"
                   v-b-popover.hover="'Редактировать'"
                   scale="1.15"
                   icon="pencil"
               >
               </b-icon>
             </b-list-group-item>
           </b-list-group>
         </b-card>
         <b-modal
             class="mr-0 ml-0"
             id="modal-edit-conf"
             size="xl"
             title="Редактирование конференции"
             title-class="title-modal-conference"
             hide-footer
             no-close-on-backdrop
         >
           <div>
             <ConfPlanning
                 :conference_parent="conference_to_edit"
                 :btn_create_edit="button_edit"
                 :parentApi="api_get_conferences_brief_info"
             >
             </ConfPlanning>
           </div>
         </b-modal>
         <b-modal
             class="mr-0 ml-0"
             id="modal-create-conf"
             size="xl"
             title="Создание конференции"
             title-class="title-modal-conference"
             hide-footer
             no-close-on-backdrop
         >
           <div>
             <ConfPlanning
                 :parentApi="api_get_conferences_brief_info"
             >
             </ConfPlanning>
           </div>
         </b-modal>
         <b-modal
             id="modal-delete-conf"
             ok-title="Удалить"
             ok-variant=danger
             @ok="api_delete_conference(selected_conference.room_name)"
             cancel-title="Отмена"
             title="Удаление конференции"
         >
           <div>
             Вы уверены, что хотите удалить конференцию?
           </div>
         </b-modal>
         <b-modal
             id="modal-passcode"
             title="Коды доступа"
             ok-only
         >
           <div v-if="selected_conf_passcodes.generic_passcode">
             <p>
               <b>Поделитесь ссылкой на встречу, чтобы пригласить неавторизованных пользователей:</b>
             </p>
             <b-row>
               <b-col cols="10">
                 <b-form-textarea rows="2" id="full-link-plaintext" ref="full-link-plaintext" plaintext
                                  style="background-color: #eeeff4; padding: 3px; border-radius: 3px;"
                                  :value="getFullPassCodeLink(selected_conf_passcodes.generic_passcode)">
                 </b-form-textarea>
               </b-col>
               <b-col cols="2" md="2" class="p-1 my-auto">
                     <!--        <p class="card-text">{{ this.room_full_link }}</p>-->
                     <b-button variant="primary" size="sm" @click="shareAsCopyLinkGeneric()"
                               v-b-popover.hover.top="'Копировать ссылку'">
                       <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                     </b-button>
               </b-col>
             </b-row>
           </div>
           <div v-if="selected_conf_passcodes.personal_passcodes">
             <p class="m-0">
               <b>Личные коды доступа</b>
             </p>
             <b-row v-for="(person,index) in selected_conf_passcodes.personal_passcodes" :key="index">
               <b-col cols="12" class="my-auto pb-1">
                 <strong style="color: #0645AD">{{person.name ? person.name :person.email}}:</strong>
               </b-col>
               <b-col cols="10">
                 <b-form-textarea rows="2" id="personal-link-textarea" ref="personal" plaintext
                                  style="background-color: #eeeff4; padding: 3px; border-radius: 3px;"
                                  :value="getFullPassCodeLink(person.pass_code)">
                 </b-form-textarea>
               </b-col>
               <b-col cols="2" md="2" class="p-1 my-auto">
                 <!--        <p class="card-text">{{ this.room_full_link }}</p>-->
                 <b-button variant="primary" size="sm" @click="shareAsCopyLinkPersonal(index)"
                           v-b-popover.hover.top="'Копировать ссылку'">
                   <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
                 </b-button>
               </b-col>
             </b-row>
           </div>
         </b-modal>
       </b-tab>
       <!--<b-tab title="Я - приглашенный">
         <b-card v-for="conference in public_array" :key="conference.room_name"
                 :title="conference.description"
                 title-class="card-title"
                 class="mb-2 card-planned-conference mb-0 pb-0"
         >
           <b-list-group horizontal="lg" center  class="confObject pt-0 pb-0 mb-0 border-top" >
             <b-list-group-item class="col-lg-2 border-0 pb-0 pt-2">
               <b-icon
                   class="mr-2 my-auto active-icon"
                   v-show="conference.moderator"
                   id="organizer-status"
                   scale="1.25"
                   v-b-popover.hover="'Вам доступны права модератора'"
                   icon="person"
               >
               </b-icon>
               <b-icon
                   class=" my-auto active-icon"
                   v-show="conference.public"
                   scale="1.25"
                   id="public" icon="megaphone"
                   v-b-popover.hover="'Публичная конференция'"
               >
               </b-icon> 
             </b-list-group-item>
             <b-list-group-item class="col-lg-4 border-0 pb-0 pt-2 my-auto" >
               {{start_date(conference.start_ts)}}
               {{start_time(conference.start_ts)}}
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto" >
               <b-link
                   @click="selected_conference=conference"
                   v-show="conference.passcode && conference.moderator"
                   v-b-modal.modal-passcode
               >
                 Коды доступа
               </b-link>
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto">
               <b-link :to="{ name: 'conf', params: { id: conference.room_name }}" class="mb-0 mt-0 pt-0 pb-0">
                 <b-icon
                     id="launch-button"
                     icon="play-btn"
                     aria-hidden="true"
                     scale="1.25"
                     class="active-icon"
                     v-b-popover.hover="'Запустить'"
                 >
                 </b-icon>
               </b-link>
               <b-icon
                   @click="selected_conference=conference"
                   v-show="conference.is_creator"
                   class="ml-3 mb-0 mt-0 pt-0 pb-0 active-icon"
                   id="delete-button"
                   v-b-popover.hover="'Удалить'"
                   scale="1.25"
                   v-b-modal.modal-delete-conf
                   icon="trash"
               >
               </b-icon>
               <b-icon
                   class="mb-0 mt-0 ml-3 pt-0 pb-0 active-icon"
                   id="editConf"
                   @click="api_get_conference_full_info(conference.room_name)"
                   v-show="conference.is_creator"
                   v-b-popover.hover="'Редактировать'"
                   scale="1.25"
                   icon="pencil"
               >
               </b-icon>
             </b-list-group-item>
           </b-list-group>
         </b-card>
       </b-tab>-->
       <b-tab title="Я - организатор">
         <b-card v-for="conference in creator_array" :key="conference.room_name"
                 :title="conference.description"
                 title-class="card-title"
                 class="mb-2 card-planned-conference mb-0 pb-0">
           <b-list-group horizontal="lg" center  class="confObject pt-0 pb-0 mb-0 border-top">
             <b-list-group-item class="col-lg-2 border-0 pb-0 pt-2">
               <b-icon
                   class="mr-2 my-auto active-icon"
                   v-show="conference.moderator"
                   id="organizer-status"
                   scale="1.25"
                   v-b-popover.hover="'Вам доступны права модератора'"
                   icon="person"
               >
               </b-icon>
               <!-- <b-icon
                   class=" my-auto active-icon"
                   v-show="conference.public"
                   scale="1.25"
                   id="public" icon="megaphone"
                   v-b-popover.hover="'Публичная конференция'"
               >
               </b-icon> -->
             </b-list-group-item>
             <b-list-group-item class="col-lg-4 border-0 pb-0 pt-2 my-auto">
               {{start_date(conference.start_ts)}}
               {{start_time(conference.start_ts)}}
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto">
               <b-link
                   @click="selected_conference=conference;api_get_passcodes(conference.room_name)"
                   v-b-modal.modal-passcode
                   v-show="conference.passcodes && conference.moderator"
               >
                 Коды доступа
               </b-link>
             </b-list-group-item>
             <b-list-group-item class="col-lg-3 border-0 pb-0 pt-2 my-auto">
                 <b-link :to="{ name: 'conf', params: { id: conference.room_name }}" class="mb-0 mt-0 pt-0 pb-0">
                   <b-icon
                       id="launch-button"
                       icon="box-arrow-in-right"
                       aria-hidden="true"
                       scale="1.25"
                       class="active-icon"
                       v-b-popover.hover="'Запустить'"
                   >
                   </b-icon>
                 </b-link>
               <b-icon
                   @click="selected_conference=conference"
                   v-show="conference.is_creator"
                   class="ml-3 mb-0 mt-0 pt-0 pb-0 active-icon"
                   id="delete-button"
                   v-b-popover.hover="'Удалить'"
                   scale="1.25"
                   v-b-modal.modal-delete-conf
                   icon="trash"
               >
               </b-icon>
               <b-icon
                   class="mb-0 mt-0 ml-3 pt-0 pb-0 active-icon"
                   id="editConf"
                   @click="api_get_conference_full_info(conference.room_name)"
                   v-show="conference.is_creator"
                   v-b-popover.hover="'Редактировать'"
                   scale="1.25"
                   icon="pencil"
               >
               </b-icon>
             </b-list-group-item>
           </b-list-group>
         </b-card>
       </b-tab>
     </b-tabs>
   </b-container>
 </div>
</template>

<script>
import ConfPlanning from "./ConfPlanning";
export default {
  name: "ConfPlanned",
  components: {ConfPlanning},
  data(){
    return{
      conferences:[],
      upHere: false,
      button_edit:'Применить изменения',
      selected_conference: {},
      selected_conf_passcodes:{},
      months:[
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
      ],
      conference_to_edit:{},
    }
  },
  computed:{
    creator_array(){
      return this.conferences.filter(item => item.is_creator===true)
    },
  },
  mounted() {
   this.api_get_conferences_brief_info()
  },
  methods:{
    start_date(time){
      const date = new Date(time*1000)
      return String(date.getDate())+' '+ this.months[date.getMonth()]
    },
    getFullPassCodeLink(passcode) {
      return window.location.origin + "/" + this.selected_conference.room_name + "?guest&code=" + passcode;
    },
    start_time(time){
      const date = new Date(time*1000)
      return date.toLocaleTimeString().slice(0,-3)
    },
    api_get_conferences_brief_info(){
      this.axios
          .get('/api/v2/planned/all', {
            withCredentials: true
          })
          .then(response => {
            {
              this.conferences = response.data;
              console.log(this.conferences)
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    api_get_passcodes(room_name){
      this.axios
          .get('/api/v2/planned/passcodes?room_name='+ room_name, {
            withCredentials: true
          })
          .then(response => {
            {
              this.selected_conf_passcodes=response.data
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    api_get_conference_full_info(room_name){
      this.axios
          .get('/api/v2/planned/event?room_name='+ room_name, {
            withCredentials: true
          })
          .then(response => {
            {
              this.conference_to_edit = response.data;
              this.$bvModal.show('modal-edit-conf')
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    shareAsCopyLinkGeneric() {
      let copyTextarea = this.$refs["full-link-plaintext"];
      copyTextarea.focus();
      copyTextarea.select();
      try {
        let successful = document.execCommand('copy');
        if (successful)
          this.makeToast(true, "Ссылка скопирована в буфер обмена", "success");
        else
          this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
      } catch (err) {
        this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
        console.log('Ссылка не скопирована. Что-то пошло не так.');
      }
    },
    shareAsCopyLinkPersonal(index) {
      let copyTextarea = this.$refs.personal[index];
      copyTextarea.focus();
      copyTextarea.select();
      try {
        let successful = document.execCommand('copy');
        if (successful)
          this.makeToast(true, "Ссылка скопирована в буфер обмена", "success");
        else
          this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
      } catch (err) {
        this.makeToast(true, 'Ссылка не скопирована. Что-то пошло не так.', "danger")
        console.log('Ссылка не скопирована. Что-то пошло не так.');
      }
    },
    makeToast(append = false, text, variant = null) {
      // this.toastCount++
      this.$bvToast.toast(text, {
        title: 'Уведомление',
        autoHideDelay: 3000,
        appendToast: append,
        variant: variant
      })
    },
    getStyle() {
      if (this.modal)
        return "modal_style";
      else
        return "not_modal_style";
    },
    getRowStyle() {
      if (this.modal)
        return "";
      else
        return "col-md-8 col-xl-8 col-sm-8";
    },
    api_delete_conference(room_name){
      this.axios.delete('/api/v2/planned/event?room_name='+ room_name,{
        withCredentials: true,
        headers: {
          'X-CSRF-TOKEN':this.$root.global_object.methods.getCookie('csrf_access_token'),
        }
      })
        .then(response =>{
          console.log(response)
          this.api_get_conferences_brief_info()
        })
    },

}
}
</script>

<style>
.btn-createNewConf {
  background-color: #0050cf;
  float: right;
  border:0;
}
.btn-createNewConf:hover{
  background-color: rgba(0, 80, 207, 0.91);
}
.label-plannedConf{
  font-weight: bold;
  text-align: left;
}
#editConf{
  color: #0050cf;
  vertical-align: middle;
}
.confObject{
  margin-bottom: 10px;
}
#delete-button:hover{
  color: rgba(229, 24, 24, 0.83);
}
#delete-button:active{
 color: rgba(203, 62, 62, 0.83);
  border: 0;
}
#delete-button{
  color: #dc3545;
  vertical-align: middle;
}
#launch-button{
  color: #0050cf;
  vertical-align: middle;
}
#organizer-status{
  color: #0050cf;
}
#public{
  color: #0050cf;
}
.card-title{
  font-size: 17px;
  font-weight: 500;
}
.access-code{
  text-align: center;
}

</style>